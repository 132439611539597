// @flow

import TextButton from '@components/popups/TextButton';
import * as LifetimeTradeInGuaranteeContent from './LifetimeTradeInGuarantee';
import * as QualityGuaranteeContent from './QualityGuarantee';
import * as SafeShoppingGuaranteeContent from './SafeShoppingGuarantee';
import * as GiaCertificateContent from './GiaCertificate';
import * as SecurePackagingShippingContent from './SecurePackagingShipping';
import * as FedExDeliveryContent from './FedExDelivery';
import * as AppraisalContent from './Appraisal';
import * as ReturnPolicyContent from './ReturnPolicy';
import * as ReferAFriendContent from './ReferAFriend';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Lumera Appraisal';

export function Content({ setPopupContent }: TProps) {
  const handleLinkClick = setPopupContent;

  return (
    <div>
      <p>
        Every Lumera purchase is accompanied by a free appraisal, documenting
        the key characteristics of the item purchased, such as the carat weight,
        shape, cut, color, clarity, and measurements of a diamond, and the
        weight, purity, and styling of any metal (such as a band). The appraisal
        can be used to document the value of your purchase for insurance.
      </p>
      <p>
        <a
          href='javascript:void(0);'
          onClick={() => setPopupContent(AppraisalContent)}
        >
          Learn more about appraisals.
        </a>{' '}
      </p>

      <p>
        By offering our Free Appraisal, as well as our{' '}
        <TextButton
          onClick={e => setPopupContent(LifetimeTradeInGuaranteeContent)}
          text='Lifetime Trade-In Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(ReturnPolicyContent)}
          text='30-Day Return Policy'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(QualityGuaranteeContent)}
          text='Quality Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(FedExDeliveryContent)}
          text='Insured Express Delivery'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(SecurePackagingShippingContent)}
          text='Secure Packaging & Shipping'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(SafeShoppingGuaranteeContent)}
          text='Safe Shopping Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(ReferAFriendContent)}
          text='Refer a Friend program'
        />
        , and{' '}
        <TextButton
          onClick={e => setPopupContent(GiaCertificateContent)}
          text='GIA Certificate'
        />
        , Lumera hopes to instill complete confidence in your purchase.
      </p>
    </div>
  );
}
