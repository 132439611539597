// @flow

import * as DiamondPlotContent from './DiamondPlot';

import TextButton from '@components/popups/TextButton';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const chip = '/images/glossary/chip.gif';

export const Header = 'Chip';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        A shallow nick in the surface of the diamond, often created during
        cutting.
      </p>
      <img src={chip} />
      <p>
        <TextButton
          onClick={(e) => setPopupContent(DiamondPlotContent)}
          text="Learn more about inclusion and blemish types, and how they are represented"
        />
        .
      </p>
    </div>
  );
}
