// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Service Hours';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        A Lumera representative is available to assist you via phone,{' '}
        <a rel='nofollow' href='mailto:customerservice@LumeraDiamonds.com'>
          email
        </a>
        , or live chat Monday - Friday from 8:00 am to 6:00 pm CST.
      </p>
      <p>
        If you leave a message or send an email, you will receive a response
        within four business hours.
      </p>
    </div>
  );
}
