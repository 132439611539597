// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const image = '/images/popups/shape/round.jpg';

export const Header = 'Round Diamonds';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <img
        src={image}
        style={{ float: 'left', width: 55, marginTop: 18, marginRight: 13 }}
      />
      <span style={{ fontWeight: 'bold' }}>Round</span>
      <p>
        The most popular shape, representing approximately 75% of all diamonds
        sold, the round diamond is generally superior to fancy shapes at the
        proper reflection of light, maximizing potential brightness.
      </p>

      <p>
        Round diamonds cost more on a per carat basis than fancy shapes for two
        reasons; the demand for round diamonds is very high, and the yield is
        relatively low.
      </p>

      <p>
        <a href="/diamond-education/round-diamonds" target="_blank">
          Learn more about round diamonds
        </a>
        , including their history, as well as how to evaluate cut parameters,{' '}
        color, and clarity.
      </p>
    </div>
  );
}
