// @flow

import TextButton from '@components/popups/TextButton';
import * as CaratWeightContent from './CaratWeight';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Price-per-Carat';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        The total price of a diamond divided by the{' '}
        <TextButton
          onClick={e => setPopupContent(CaratWeightContent)}
          text='carat weight'
        />{' '}
        (e.g. A 2.00 carat diamond with a total purchase price of $6,000 would
        have a price-per-carat of $3,000). Price-per-carat is a helpful measure
        when comparing diamonds of similar but not identical carat weights.
      </p>
      <p>
        As carat weight increases, you will typically pay more not only in
        total, but on a price-per-carat basis as well. This is due to the rarity
        of larger diamonds (if 2.00 carat diamonds were mined as often as 1.00
        carat diamonds, their total price would only be double that of a 1.00
        carat). The table below illustrates the typical relationship between
        diamonds of equal quality and increasing carat weights:
      </p>
      
      <table>
        <thead>
          <tr>
            <th>Carat Weight</th>
            <th>1.00</th>
            <th>2.00</th>
            <th>3.00</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Price / carat</td>
            <td>$6,000</td>
            <td>$12,000</td>
            <td>$18,000</td>
          </tr>
          <tr>
            <td>Total Price</td>
            <td>$6,000</td>
            <td>$24,000</td>
            <td>$54,000</td>
          </tr>
        </tbody>
      </table>

      <p>
        Every certified Lumera Diamond includes the price-per-carat so you can
        easily compare diamonds of various carat weights.{' '}
      </p>
      <p>
        <TextButton
          onClick={e => setPopupContent(CaratWeightContent)}
          text='Learn more about Carat Weight.'
        />
      </p>
    </div>
  );
}
