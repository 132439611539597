// @flow

import styles from '@styles/why_choose_lumera';

type PropsType = {
  setPopupContent: (N: React.Node) => void
};

export function Content({ setPopupContent }: PropsType) {
  return (
    <div>
      <h3 className={styles.h3}>Security</h3>
      <p>
        Your order is shipped fully insured. Find out how we protect your{' '}
        <a href='/customer-service/privacy-policy' className={styles.link}>
          privacy
        </a>
        , your{' '}
        <a href='/customer-service/shipping' className={styles.link}>
          package
        </a>
        , even your{' '}
        <a href='/customer-service/shipping#secure' className={styles.link}>
          surprise
        </a>
        .
      </p>
    </div>
  );
}
