// @flow

import TextButton from '@components/popups/TextButton';
import * as CrystalContent from './Crystal';
import * as CloudContent from './Cloud';
import * as DiamondPlotContent from './DiamondPlot';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const pinpoint = '/images/glossary/pinpoint.gif';

export const Header = 'Pinpoint';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p className="style1">
        A very small{' '}
        <TextButton
          onClick={(e) => setPopupContent(CrystalContent)}
          text="crystal"
        />
        , often whitish in color. When clustered together, these pinpoints can
        form a{' '}
        <TextButton
          onClick={(e) => setPopupContent(CloudContent)}
          text="cloud"
        />
        .
      </p>
      <img src={pinpoint} />
      <p>
        <TextButton
          onClick={(e) => setPopupContent(DiamondPlotContent)}
          text="Learn more about inclusion and blemish types, and how they are represented"
        />
        .
      </p>
    </div>
  );
}
