// @flow
import { useReducer } from 'react';

import Base from './Base';

import type { Node } from 'react';

type TProps = {
  show: boolean | void,
  onCloseClick: () => void,
  onHeaderClick?: () => void,
  onMouseOver?: () => void,
  onMouseOut?: () => void,
  onClick?: e => void,
  onClose?: () => void,
  onShow?: () => void,
  anchorElement: HTMLElement,
  minWidth?: number | string,
  maxWidth?: number | string,
  hideCloseButton?: boolean,
  doNotScrollToPopup?: boolean,
  preferSideAttachment?: boolean,
  initialContent: {
    Header?: string,
    Content: Node
  }
};

function reducer(state, action) {
  switch (action.type) {
    case 'PUSH_CONTENT':
      return [...state, action.payload];

    case 'POP_CONTENT':
      if (state.length > 1) {
        return state.slice(0, -1);
      } else {
        return state;
      }

    case 'RESET_STACK':
      return [state[0]];

    default:
      return state;
  }
}

function DynamicBase(props: TProps) {
  const { initialContent, onCloseClick, ...otherProps } = props;
  const [contentItems, dispatch] = useReducer(reducer, [initialContent]);

  const Content = contentItems.slice(-1)[0];

  const showBubble = Content => {
    dispatch({
      type: 'PUSH_CONTENT',
      payload: Content
    });
  };

  const navigateBack = () => {
    dispatch({ type: 'POP_CONTENT' });
  };

  const handleClose = () => {
    dispatch({ type: 'RESET_STACK' });
    onCloseClick && onCloseClick();
  };

  const headerText = contentItems.length > 1 ? '< Back' : Content.Header;

  return (
    <Base
      {...otherProps}
      onCloseClick={handleClose}
      onHeaderClick={contentItems.length > 1 ? navigateBack : undefined}
      title={headerText}
    >
      <Content.Content setPopupContent={showBubble} />
    </Base>
  );
}

export default DynamicBase;
