// @flow

import Carousel from '../../carousels/popups/ModernExamples';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Modern';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Clean lines, simple silhouettes, and innovative design are the hallmarks
        of modern design in engagement rings.
      </p>

      <Carousel />
    </div>
  );
}
