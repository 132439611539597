// @flow
import axios from 'axios';
import axiosRetry from 'axios-retry';
import type { ObjectType } from '../types/misc';

import { handleError } from '../utils/errors';

import type { Axios } from 'axios';

function isRetriableError(error) {
  // For now, we want to retry all requests
  return true;
}

export default function AxiosInstanceFactory(options: ObjectType = {}): Axios {
  if (typeof window === 'undefined') return {}; // for server side rendering

  const defaultTimeout = 15000;
  const defaultHeaders = {
    ...ReactOnRails.authenticityHeaders(),

    'Cache-Control': 'no-cache',
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  const instance = axios.create({
    timeout: options.timeout || defaultTimeout,
    withCredentials: true,
    headers: { ...defaultHeaders, ...options.headers }
  });

  // eslint-disable-next-line
  axiosRetry(instance, {
    retries: 3,
    retryCondition: isRetriableError,
    retryDelay: axiosRetry.exponentialDelay
  });

  instance.CancelToken = axios.CancelToken;

  instance.interceptors.response.use(
    response => response,
    error => {
      // We really want to throw the error so it is handled and we don't get
      // an unhandledrejection error. By throwing here, we are handling the
      // rejection, and bubbling up to the closest error handler (try/catch or
      // catch method call on a promise).
      throw error;
    }
  );

  return instance;
}
