// @flow

import * as CaratWeightContent from './CaratWeight';
import * as ShapeContent from './Shape';
import * as CutContent from './Cut';
import * as ColorContent from './Color';
import * as ClarityContent from './Clarity';
import * as DiamondDimensionsContent from './DiamondDimensions';
import * as GiaCertificateContent from './GiaCertificate';
import * as QualityGuaranteeContent from './QualityGuarantee';

import TextButton from '../TextButton';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Appraisals';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        An appraisal documents the key characteristics of an item, such as the{' '}
        <TextButton
          onClick={e => setPopupContent(CaratWeightContent)}
          text='carat weight'
        />
        ,{' '}
        <TextButton onClick={e => setPopupContent(ShapeContent)} text='shape' />
        , <TextButton
          onClick={e => setPopupContent(CutContent)}
          text='cut'
        />,{' '}
        <TextButton onClick={e => setPopupContent(ColorContent)} text='color' />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(ClarityContent)}
          text='clarity'
        />
        , and{' '}
        <TextButton
          onClick={e => setPopupContent(DiamondDimensionsContent)}
          text='measurements'
        />{' '}
        of a diamond, and the weight, purity, and styling of a ring (if the
        diamond is set). An appraisal can often be obtained from the retailer
        (such as Lumera Diamonds) or from an independent appraiser.
      </p>
      <p>
        The primary benefit of an appraisal is to establish the value of the
        item for insurance purposes. Your insurance company will require an
        appraisal of any item that is added to your policy.{' '}
      </p>
      <p>
        When you receive your Lumera purchase, a detailed appraisal, suitable
        for insurance purposes, will be included (along with the diamond's{' '}
        <TextButton
          onClick={e => setPopupContent(GiaCertificateContent)}
          text='GIA certificate'
        />
        , if applicable). The value stated on the appraisal is based on current
        market prices, and may be higher than the actual purchase price.{' '}
      </p>
      <p>
        You may also choose to have your purchase independently appraised.
        Lumera Diamonds guarantees that your purchase will appraise at or higher
        than the original purchase price (
        <TextButton
          onClick={e => setPopupContent(QualityGuaranteeContent)}
          text='see guarantee details'
        />
        ) .
      </p>
      <div id='customerBox2'>
        <h2 style={{ marginBottom: 0, paddingBottom: 0 }}>
          Look for the following when choosing an independent appraiser:
        </h2>
        <ul style={{ marginTop: 0, paddingTop: 0, paddingLeft: 0 }}>
          <li style={{ marginBottom: 0 }}>
            True Independence - not employed by or affiliated with any jewelry
            retailer.
          </li>
          <li style={{ marginBottom: 0 }}>
            Graduate Gemologist - certified by the GIA.
          </li>
          <li style={{ marginBottom: 0 }}>
            Member of a reputable appraisal association - such as ASA, ISA, or
            NGJA.
          </li>
          <li style={{ marginBottom: 0 }}>
            Conforms to USPAP - Uniform Standard of Professional Appraisal
            Practice.
          </li>
          <li style={{ marginBottom: 0 }}>
            Full-time appraiser - experienced in the appraisal of natural and
            synthetic gems.
          </li>
          <li style={{ marginBottom: 0 }}>
            Flat fee structure - never a percentage of the value of the item.
          </li>
          <li style={{ marginBottom: 0 }}>
            Open Environment - willing to allow you to be present, with the item
            in view at all times.
          </li>
        </ul>
      </div>
    </div>
  );
}
