// @flow

import TextButton from '@components/popups/TextButton';
import * as ServiceHoursContent from './ServiceHours';
import * as LooseDiamondPackagingContent from './LooseDiamondPackaging';

import Dropdown from '../../ForeignShippingCountriesList';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'International Shipping';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>Lumera is pleased to offer service to our international customers.</p>

      <Dropdown />

      <p>
        All prices are displayed in U.S. dollars. Orders for shipment outside
        the U.S. may not be placed online, but may be placed via email with a
        representative. Payment options for international orders are either by
        bank wire or paypal (both initiated by contacting a representative via
        email). To contact a representattive, email{' '}
        <a rel='nofollow' href='mailto:CustomerService@LumeraDiamonds.com'>
          CustomerService@LumeraDiamonds.com
        </a>{' '}
        (include the item number or link to the diamond you wish to purchase.
        You can also chat online; a representative will respond immediately
        during{' '}
        <TextButton
          onClick={e => setPopupContent(ServiceHoursContent)}
          text='store hours'
        />
        .
      </p>
      <p>
        Orders are shipped via FedEx International Priority for $95 USD. Once
        shipped, transit time should be no more than three business days. Should
        customs choose to inspect the order, your package may be delayed one or
        more days.
      </p>
      <p>
        Duties and Taxes may be assessed for your purchase according to the
        rates listed below. These charges will be paid by you, the customer,
        prior to delivery.
      </p>
      <h2>CANADA</h2>
      <div id='customerBox4'>
        <table>
          <tbody>
            <tr>
              <td>
                <h2>
                  Alberta, Northwest Territories, Nunavut, Yukon Territory
                </h2>
              </td>
              <td>
                <p style={{ padding: '0 20px' }}>5% GST</p>
              </td>
            </tr>
            <tr>
              <td>
                <h2>New Brunswick, Newfoundland, Nova Scotia, Labrador</h2>
              </td>
              <td>
                <p style={{ padding: '0 20px' }}>13% HST</p>
              </td>
            </tr>
            <tr>
              <td>
                <h2>British Columbia, Manitoba</h2>
              </td>
              <td>
                <p style={{ padding: '0 20px' }}>5% GST + 7.35% PST</p>
              </td>
            </tr>
            <tr>
              <td>
                <h2>Ontario</h2>
              </td>
              <td>
                <p style={{ padding: '0 20px' }}>5% GST + 8.40% PST</p>
              </td>
            </tr>
            <tr>
              <td>
                <h2>Prince Edward Island</h2>
              </td>
              <td>
                <p style={{ padding: '0 20px' }}>5% GST + 10.50% PST</p>
              </td>
            </tr>
            <tr>
              <td>
                <h2>Quebec</h2>
              </td>
              <td>
                <p style={{ padding: '0 20px' }}>5% GST + 7.87% PST</p>
              </td>
            </tr>
            <tr>
              <td>
                <h2>Saskatchewan</h2>
              </td>
              <td>
                <p style={{ padding: '0 20px' }}>5% GST + 5.25% PST</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2>OTHER COUNTRIES</h2>
      <div id='customerBox4'>
        <table>
          <tbody>
            <tr>
              <td>
                <h2>Australia</h2>
              </td>
              <td>
                <p style={{ padding: '0 20px' }}>10% VAT (Value Added Tax) </p>
              </td>
            </tr>
            <tr>
              <td>
                <h2>New Zealand </h2>
              </td>
              <td>
                <p style={{ padding: '0 20px' }}>
                  12.5% VAT (Value Added Tax){' '}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <h2>United Kingdom </h2>
              </td>
              <td>
                <p style={{ padding: '0 20px' }}>20% VAT (Value Added Tax) </p>
              </td>
            </tr>
            <tr>
              <td>
                <h2>Additional Countries</h2>
              </td>
              <td>
                <p style={{ padding: '0 20px' }}>Contact customs office</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        Please understand that Lumera makes every effort to communicate accurate
        and timely information, but can not be responsible for any errors or
        omissions in the above recap of taxes and duties.{' '}
        <b>
          We strongly urge you to contact customs officials in the receiving
          country to confirm all policies and charges prior to placing your
          order.
        </b>
      </p>
      <h2>SIGNATURE REQUIREMENTS</h2>
      <p>
        For your security, FedEx will require an adult's signature at delivery,
        even if you have a signature waiver on file with FedEx. FedEx will not
        leave your package unattended or leave it at another delivery address
        (such as a neighbor). If no one is available to receive the package,
        FedEx will attempt a second delivery at another time.
      </p>
      <h2>SECURE AND DISCREET</h2>
      <p>
        We don't want to spoil any surprises, so at checkout you will have the
        opportunity to specify exactly how you would like to be contacted
        regarding your order. Lumera will contact you only at the email or phone
        number you designate.{' '}
      </p>
      <div id='customerBox2'>
        <h2>
          For your privacy and security, your purchase will be shipped as
          follows:
        </h2>
        <ul style={{ marginBottom: '15px' }}>
          <li>
            In a standard FedEx shipping box. Lumera Diamonds will not appear
            anywhere on the outside of the box.
          </li>
          <li>
            Fully insured against loss or theft. If your package appears damaged
            or tampered with upon delivery, do not accept it or sign for it.
            Lumera will work directly with FedEx to resolve the issue.
          </li>
          <li>
            Loose diamonds (those not set in a ring prior to shipment) will be
            shipped in a{' '}
            <TextButton
              onClick={e => setPopupContent(LooseDiamondPackagingContent)}
              text='specially designed package'
            />
            , to keep your diamond secure until you have it set.
          </li>
          <li>
            Diamonds set in a ring will be shipped in a presentation ready ring
            box.
          </li>
        </ul>
      </div>
      <h2>DELIVERY ESTIMATE</h2>
      <p>
        To help you plan for the receipt of your package, an estimated delivery
        date is given on the detail page for every diamond, as well as on the
        shopping bag page. This estimate is for domestic orders; please add 3
        days for international orders. After your purchase, you will receive
        email updates letting you know exactly when to expect your package.
        These updates will be sent only to the email address you specify (so as
        not to spoil any planned surprise).{' '}
      </p>
      <p>
        For questions regarding international orders, please call{' '}
        <span className='no-wrap'><a href="tel:+01-888-658-6372">1-888-658-6372</a></span> or contact us at{' '}
        <a rel='nofollow' href='mailto:CustomerService@LumeraDiamonds.com'>
          CustomerService@LumeraDiamonds.com
        </a>
        . You can also{' '}
        <a href='javascript:void(0);' className='chat-link '>
          chat online
        </a>
        ; a representative will respond immediately during{' '}
        <TextButton
          onClick={e => setPopupContent(ServiceHoursContent)}
          text='store hours'
        />
        .
      </p>
    </div>
  );
}
