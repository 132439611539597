// @flow

import Carousel from '../../carousels/popups/DiamondAccentExamples';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Diamond Accent';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Used to describe diamonds not arranged in a solitaire, halo, or
        three-stone style. Accent diamonds are typically larger than on a pave
        style. Round is the most popular accent shape, as it works well with any
        center diamond, and can be set in virtually any arrangement.
      </p>

      <Carousel />
    </div>
  );
}
