// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const image = '/images/popups/shape/emerald.jpg';

export const Header = 'Emerald Cut Diamonds';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <img
        src={image}
        style={{ float: 'left', width: 55, marginTop: 18, marginRight: 13 }}
      />
      <span style={{ fontWeight: 'bold' }}>Emerald</span>
      <p>
        The unique look of the emerald cut diamond is created by the step cuts
        of its pavilion and its large, open table. Instead of the sparkle of a
        brilliant-cut, emerald cut diamonds produce a hall-of-mirrors effect,
        with the interplay of light and dark planes.
      </p>

      <p>
        Emerald cut diamonds vary from nearly square to a narrow rectangle. If
        you prefer the look of the square emerald cut diamond, be sure to
        consider the asscher cut as well (which has a very similar appearance,
        and is defined by GIA as a square cut emerald).
      </p>

      <p>
        Often, body color and inclusions are easier to see in an emerald cut
        diamond (especially over 1.50 carats) because of the large, open facets.
      </p>

      <p>
        <a href="/diamond-education/emerald-cut-diamond" target="_blank">
          Learn more about emerald cut diamonds
        </a>
        , including their history, as well as how to evaluate cut parameters,
        color, and clarity.
      </p>
    </div>
  );
}
