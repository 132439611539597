// @flow

import TextButton from '@components/popups/TextButton';
import * as CertificationContent from './Certification';
import * as GiaContent from './Gia';
import * as ShapeContent from './Shape';
import * as DiamondDimensionsContent from './DiamondDimensions';
import * as CaratWeightContent from './CaratWeight';
import * as DepthContent from './Depth';
import * as TableContent from './Table';
import * as GirdleContent from './Girdle';
import * as CuletContent from './Culet';
import * as PolishContent from './Polish';
import * as SymmetryContent from './Symmetry';
import * as ClarityContent from './Clarity';
import * as ColorContent from './Color';
import * as CutContent from './Cut';
import * as FluorescenceContent from './Fluorescence';
import * as LaserInscriptionContent from './LaserInscription';
import * as DiamondPlotContent from './DiamondPlot';
import * as InclusionContent from './Inclusion';
import * as BlemishContent from './Blemish';
import * as ProportionDiagramContent from './ProportionDiagram';

import type { Node } from 'react';

type TProps = {
  setPopupContent: (N: Node) => void
};

const gradingReportIcon =
  '/images/diamond-education/GIA_diamond_grading_report.gif';

export const Header = 'GIA Grading Report';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p className="style1">
        A{' '}
        <TextButton
          onClick={(e) => setPopupContent(CertificationContent)}
          text="certification"
        />{' '}
        issued by the{' '}
        <TextButton onClick={(e) => setPopupContent(GiaContent)} text="GIA" />,
        providing an impartial judgment on the characteristics and quality of a
        diamond. The GIA grading report includes the following information:
      </p>
      <ul className="style1">
        <li style={{ marginBottom: 0 }}>
          Date: The date the diamond was examined by GIA
        </li>
        <li style={{ marginBottom: 0 }}>
          Report Number: The unique tracking number assigned to each diamond and
          report.
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(ShapeContent)}
            text="Shape"
          />{' '}
          (e.g. brilliant round, square modified brilliant)
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(DiamondDimensionsContent)}
            text="Measurements:"
          />{' '}
          to the nearest one-hundredth of a millimeter
        </li>{' '}
        to the nearest one-hundredth of a millimeter
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(CaratWeightContent)}
            text="Carat Weight:"
          />{' '}
          to the nearest one-hundredth of a carat
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(DepthContent)}
            text="Depth %"
          />{' '}
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(TableContent)}
            text="Table %"
          />{' '}
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(GirdleContent)}
            text="Girdle"
          />{' '}
          Thickness{' '}
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(CuletContent)}
            text="Culet"
          />{' '}
          Size{' '}
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(PolishContent)}
            text="Polish"
          />{' '}
          Grade
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(SymmetryContent)}
            text="Symmetry"
          />{' '}
          Grade
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(ClarityContent)}
            text="Clarity"
          />{' '}
          Grade
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(ColorContent)}
            text="Color"
          />{' '}
          Grade{' '}
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(CutContent)}
            text="Cut"
          />{' '}
          Grade: for round diamonds only
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(FluorescenceContent)}
            text="Fluorescence"
          />{' '}
          Grade
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(LaserInscriptionContent)}
            text="Laser Inscription:"
          />{' '}
          Notes the content of any inscription present on the girdle of the
          diamond.
        </li>
        <li style={{ marginBottom: 0 }}>
          General Comments: Additional identifying characteristics or features
          not otherwise represented in the report.
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(DiamondPlotContent)}
            text="Diamond Plot:"
          />{' '}
          showing all{' '}
          <TextButton
            onClick={(e) => setPopupContent(InclusionContent)}
            text="inclusions"
          />{' '}
          and{' '}
          <TextButton
            onClick={(e) => setPopupContent(BlemishContent)}
            text="blemishes"
          />{' '}
          found.
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(ProportionDiagramContent)}
            text="Proportion Diagram:"
          />{' '}
          A graphic representation of the diamond's actual proportions.
        </li>
        <li style={{ marginBottom: 0 }}>
          Security Features: A variety of document security features (such as
          hologram, security screen, micro-print lines) which confirm the
          authenticity of the report.
        </li>
      </ul>
      <p>
        The certificate for any Lumera Diamond may be viewed by clicking the
        name "GIA" next to any diamond.
      </p>
      <div id="customerBox4">
        <div id="certGIA01">
          {' '}
          <a href="/files/GIA_grading_report.pdf" target="_blank">
            <img
              src={gradingReportIcon}
              style={{ width: 60, marginRight: 10 }}
            />
          </a>{' '}
          <a href="/files/GIA_grading_report.pdf" target="_blank">
            View a GIA Grading Report
          </a>{' '}
        </div>
      </div>
    </div>
  );
}
