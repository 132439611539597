// @flow

import TextButton from '@components/popups/TextButton';
import * as BrillianceContent from './Brilliance';
import * as FireContent from './Fire';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Scintillation';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Scintillation refers to the intense sparkle of a diamond as it moves.
        These flashes of black and white are most pronounced in flood lit areas
        where strong light enters the diamond from multiple angles.{' '}
      </p>
      <p>
        Maximum{' '}
        <TextButton
          onClick={e => setPopupContent(BrillianceContent)}
          text='brilliance'
        />{' '}
        is achieved in diamonds that posses both{' '}
        <TextButton onClick={e => setPopupContent(FireContent)} text='fire' />{' '}
        and scintillation.
      </p>
    </div>
  );
}
