// @flow
import Carousel from '../TextOnly'
import { style, media } from 'typestyle'

const items = [
  {
    headerText: 'FL-IF',
    text: `
      <article>
        <h2>
          Flawless / Internally Flawless
        </h2>
        <p class="no-bottom-margin">
          No inclusions are visible to a skilled grader using 10x magnification.
          FL and IF diamonds appear identical unless viewed under 10x
          magnification by a skilled grader.  Less than 3% of jewelry quality
          diamonds are rated FL or IF.
        </p>
      </article>
    `
  }, {
    headerText: 'VVS1-VVS2',
    text: `
      <article>
        <h2>
          Very, Very Slightly Included
        </h2>
        <p class="no-bottom-margin">
          VVS1 inclusions are typically only visible from the pavilion, while
          VVS2 inclusions are visible from the crown. In each, the inclusions
          are invisible to the eye, and difficult for a skilled grader to see
          under 10x magnification.
        </p>
      </article>
    `
  }, {
    headerText: 'VS1-VS2',
    text: `
      <article>
        <h2>
          Very Slightly Included
        </h2>
        <p class="no-bottom-margin">
          Inclusions are clearly visible under 10x magnification but can be
          characterized as minor.  Inclusions are not visible to the naked eye.
          Perhaps 1 in 100 untrained observers can detect VS2 inclusions with
          the naked eye, on close inspection.
        </p>
      </article>
    `
  }, {
    headerText: 'SI1-SI2',
    text: `
      <article>
        <h2>
          Slightly Included
        </h2>
        <p class="no-bottom-margin">
          Inclusions are easily noticeable using 10x magnification. SI1 is the
          lowest grade with flaws often invisible to the naked eye. SI2
          inclusions are usually visible to the naked eye, although they may
          require close inspection.
        </p>
      </article>
    `
  }, {
    headerText: 'I1',
    text: `
      <article>
        <h2>
          Included
        </h2>
        <p class="no-bottom-margin">
          Inclusions are visible to the naked eye and may affect transparency
          and brilliance. In diamonds under .75 ct., the chance of a visually
          clean I1 clarity diamond is higher. Work with a consultant prior to
          purchase to confirm the visibility of inclusions.
        </p>
      </article>
    `
  }, {
    headerText: 'I2-I3',
    text: `
      <article>
        <h2>
          Included
        </h2>
        <p class="no-bottom-margin">
          Inclusions are obvious to the naked eye. Because I2-I3 diamonds have
          pronounced inclusions, and in the case of I3 may even affect the
          diamond's durability, they are not offered by Lumera. 
        </p>
      </article>
    `
  }
]

export default (props: Object) => {
  return (
    <Carousel
      {...props}
      items={items}
      textAlign='left'
      style={{marginTop: 18}}
      contentStyles={{ minHeight: 230, margin: 15 }}
    />
  );
}
