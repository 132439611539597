// @flow

import TextButton from '@components/popups/TextButton';
import * as LifetimeTradeInGuaranteeContent from './LifetimeTradeInGuarantee';
import * as ReturnPolicyContent from './ReturnPolicy';
import * as LumeraAppraisalContent from './LumeraAppraisal';
import * as QualityGuaranteeContent from './QualityGuarantee';
import * as FedExDeliveryContent from './FedExDelivery';
import * as SecurePackagingShippingContent from './SecurePackagingShipping';
import * as SafeShoppingGuaranteeContent from './SafeShoppingGuarantee';
import * as GiaCertificateContent from './GiaCertificate';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Refer A Friend';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        We want you to tell others about your Lumera experience. When you do,
        and a friend makes a purchase from Lumera Diamonds, we'll reward you
        with a check equal to 2.5% of your friend's total purchase (up to $500)!
      </p>
      <p>
        Simply email{' '}
        <a rel='nofollow' href='mailto:CustomerService@LumeraDiamonds.com'>
          customerservice@LumeraDiamonds.com
        </a>{' '}
        with your name and the order number from your Lumera purchase. Also
        include the full name of your friend, once they have made a purchase.
        After we confirm that you and your friend have both purchased from Lumera
        Diamonds, we will mail your payment to you. It's that simple!
      </p>
      <div id='customerBox2'>
        <h2>In order to qualify for the Refer A Friend payment:</h2>
        <ul style={{ marginBottom: '15px' }}>
          <li>
            You must have purchased from Lumera. If you have returned
            your purchase, you are not eligible to participate.
          </li>
          <li>
            Your purchase must be at least 30 days old. You may still refer a
            friend in the meantime, but your payment will be held until the 30
            day window has passed.{' '}
          </li>
          <li>
            Send an email to Lumera Diamonds with your full name and order
            number, as well as your friend's name (after they have made their
            purchase).
          </li>
          <li>
            Your friend must be a new Lumera customer, and their purchase must
            be at least 30 days old. This waiting period allows us to confirm
            that the purchase is final, and not returned. You
            may still refer a friend, but your payment will be held until the 30
            day window for both purchases has passed.
          </li>
          <li>
            Your friend's purchase may not be more than 3 months old, or made
            prior to your own.
          </li>
          <li>
            A purchase may only be claimed once. If more than one person submits
            a claim on a friend's purchase, only the first Refer A Friend
            request will be honored.
          </li>
        </ul>
      </div>
      <p>
        Your payment will be equal to 2.5% of your friend's total purchase
        (excludes setting and sales tax), up to $500. You may keep the entire
        amount, or share it with your friend. Lumera respects your privacy; your
        friend will only be aware of your Refer A Friend payment if you tell
        them.
      </p>
      <p>
        For questions regarding the Lumera Refer A Friend program, please call{' '}
        <span className='no-wrap'><a href="tel:+01-888-658-6372">1-888-658-6372</a></span>, or contact us at{' '}
        <a rel='nofollow' href='mailto:CustomerService@LumeraDiamonds.com'>
          CustomerService@LumeraDiamonds.com
        </a>
        .
      </p>
      <p>
        By offering our exclusive Refer A Friend program, as well as our{' '}
        <TextButton
          onClick={e => setPopupContent(LifetimeTradeInGuaranteeContent)}
          text='Lifetime Trade-In Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(ReturnPolicyContent)}
          text='30-Day Return Policy'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(LumeraAppraisalContent)}
          text='Free Appraisal'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(QualityGuaranteeContent)}
          text='Quality Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(FedExDeliveryContent)}
          text='Insured Express Delivery'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(SecurePackagingShippingContent)}
          text='Secure Packaging &amp; Shipping'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(SafeShoppingGuaranteeContent)}
          text='Safe Shopping Guarantee'
        />
        , and{' '}
        <TextButton
          onClick={e => setPopupContent(GiaCertificateContent)}
          text='GIA Certificate'
        />
        , Lumera hopes to instill complete confidence in your purchase.
      </p>
    </div>
  );
}
