// @flow

import Carousel from '../../carousels/popups/ThreeStoneExamples';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Three Stone';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Typically, a larger center diamond flanked by two smaller, identically
        sized, side stones. An iconic representation of love; representing past,
        present, and future. Popular as an anniversary ring as well.
      </p>

      <Carousel />
    </div>
  );
}
