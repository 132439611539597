// @flow

import Carousel from '../../carousels/popups/BezelSetExamples';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Bezel Set';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Refers to the metal rim which completely encircles a center or accent
        diamond. A bezel setting provides maximum security, both holding the
        diamond firmly in place, and protecting all sides from chipping.
      </p>

      <Carousel />
    </div>
  );
}
