// @flow

import TextButton from '@components/popups/TextButton';
import * as LifetimeTradeInGuaranteeContent from './LifetimeTradeInGuarantee';
import * as ReturnPolicyContent from './ReturnPolicy';
import * as LumeraAppraisalContent from './LumeraAppraisal';
import * as QualityGuaranteeContent from './QualityGuarantee';
import * as FedExDeliveryContent from './FedExDelivery';
import * as SecurePackagingShippingContent from './SecurePackagingShipping';
import * as ReferAFriendContent from './ReferAFriend';
import * as GiaCertificateContent from './GiaCertificate';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Safe Shopping Guarantee';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>Every purchase is covered by our Safe Shopping Guarantee.</p>
      <ul>
        <li>
          Your personal information is protected by secure socket layer VeriSign
          (SSL) software, the industry leader in secure commerce transactions.
          Your credit card number is encrypted so that it cannot be read by
          others as the information passes over the internet.
        </li>
        <li>
          Lumera will not store your credit card information after your order is
          shipped. This makes it impossible for your information to be
          compromised in the future.
        </li>
        <li>
          Your bank cannot hold you liable for more than $50 of fraudulent
          charges on your card (as outlined in the Fair Credit Billing Act).
          Even if your bank holds you liable for a portion of the $50 maximum,
          Lumera Diamonds will reimburse you for any charges incurred, up to the
          full $50.{' '}
        </li>
      </ul>
      <p>
        By offering our exclusive Safe Shopping Guarantee, as well as our{' '}
        <TextButton
          onClick={e => setPopupContent(LifetimeTradeInGuaranteeContent)}
          text='Lifetime Trade-In Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(ReturnPolicyContent)}
          text='30-Day Return Policy'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(LumeraAppraisalContent)}
          text='Free Appraisal'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(QualityGuaranteeContent)}
          text='Quality Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(FedExDeliveryContent)}
          text='Insured Express Delivery'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(SecurePackagingShippingContent)}
          text='Secure Packaging &amp; Shipping'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(ReferAFriendContent)}
          text='Refer a Friend program'
        />
        , and{' '}
        <TextButton
          onClick={e => setPopupContent(GiaCertificateContent)}
          text='GIA Certificate'
        />
        , Lumera hopes to instill complete confidence in your purchase.
      </p>
    </div>
  );
}
