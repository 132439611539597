// @flow

import TextButton from '@components/popups/TextButton';
import * as GiaContent from './Gia';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const diamondFractureFilling =
  '/images/diamond-education/diamond-fracture-filling.jpg';

export const Header = 'Diamond Treatments';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <div className="multimedia-box">
        <img
          style={{ width: '100%' }}
          alt="Before and After: Fracture Filled Diamond"
          src={diamondFractureFilling}
        />
        <div
          className="caption"
          style={{
            marginTop: 0,
            padding: '9px 5px 12px 5px',
            color: '#9b9b9b'
          }}>
          Before and After: Diamond Fracture Filling
        </div>
      </div>
      <p>
        Approximately <b>1 in 3</b> diamonds sold has been treated or "enhanced"
        in some way. A variety of techniques exist to artificially improve the
        natural clarity of a diamond. By drilling a pathway to an internal
        inclusion with a laser beam, acid can be poured into the tunnel to
        bleach the inclusion. The laser tunnel appears as a tiny white dot when
        viewed from the top of the diamond (where the drilling was performed),
        but as a long white line when viewed from the side. In addition,
        fractures in a diamond can be filled with a clear glass-like material,
        making them less visible.{' '}
        <TextButton
          onClick={(e) => setPopupContent(GiaContent)}
          text="GIA"
        />{' '}
        will not certify diamonds which have been laser drilled or fracture
        filled.
      </p>
      <p>
        While laser drilling and fracture filling are used to improve a
        diamond's clarity, high pressure / high temperature (HPHT) treatments
        are used to improve color by removing brown colorations from the
        diamond. HPHT involves placing the diamond in a pressure vessel and
        applying extremely high pressure and temperature. This environment
        mimics the conditions the diamond crystal was originally formed under.
        The effects of HPHT are permanent, and the presence of the treatment is
        very difficult to detect. GIA will certify a HPHT diamond, but will note
        the presence of the treatment on the diamond's certificate.
      </p>
      <p style={{ marginBottom: 0 }}>
        Any treatment or "enhancement" made to a diamond lowers its value.{' '}
        <b>
          Lumera does not sell diamonds that have been treated in any way,
          including HPHT.
        </b>{' '}
      </p>
    </div>
  );
}
