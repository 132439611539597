// @flow

import TextButton from '@components/popups/TextButton';
import * as FedExInternationalDeliveryContent from './FedExInternationalDelivery';
import * as SecurePackagingShippingContent from './SecurePackagingShipping';
import * as QualityGuaranteeContent from './QualityGuarantee';
import * as SafeShoppingGuaranteeContent from './SafeShoppingGuarantee';
import * as LumeraAppraisalContent from './LumeraAppraisal';
import * as GiaCertificateContent from './GiaCertificate';
import * as FedExDeliveryContent from './FedExDelivery';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Free FedEx Delivery on Every Order';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Lumera offers Free FedEx shipping on every domestic order.  
        Because each order involves manufacturing and/or processing 
        (fraud screening, appraisal, cleaning, etc.). the actual delivery time will vary. 
        Always note the delivery estimate displayed for each item.{' '}
      </p>
      <p>
        Orders may be shipped anywhere in the United States where FedEx delivers
        (learn about{' '}
        <TextButton
          onClick={e => setPopupContent(FedExInternationalDeliveryContent)}
          text='international delivery'
        />
        ). FedEx will not deliver to P.O. Boxes or military (APO/FPO) addresses.
      </p>
      <p>
        For your security, FedEx will require an adult's signature at delivery,
        even if you have a signature waiver on file with FedEx. FedEx will not
        leave your package unattended or leave it at another delivery address
        (such as a neighbor). If no one is available to receive the package,
        FedEx will attempt a second delivery at another time.
      </p>
      <h2>DELIVERY OPTIONS</h2>
      <p>Your package can be delivered to any of the following addresses:</p>
      <div id='customerBox14'>
        <ul>
          <li>
            The address associated with your credit card (this is typically your
            residence).
          </li>
          <li>Your work address.</li>
          <li>
            The business address of a jeweler, if you would like a diamond
            delivered directly.
          </li>
          <li>
            A nearby FedEx Office / Kinkos. You will be notified by the local
            FedEx as soon as your package arrives, and may pick it up at any
            time. This allows you to avoid waiting for a driver. To choose this
            option at checkout, use your bill-to as your ship-to address, then
            use the comment field to ask to have your package delivered to a
            local FedEx instead. You will receive confirmation of your request
            via email, including the address of the nearest FedEx store.
          </li>
        </ul>
      </div>
      <p>
        More details on Lumera's{' '}
        <TextButton
          onClick={e => setPopupContent(SecurePackagingShippingContent)}
          text='safe packaging & secure shipping'
        />
        .
      </p>
      <p>
        By offering free FedEx delivery, as well as our{' '}
        <TextButton
          onClick={e => setPopupContent(LifetimeTradeInGuaranteeContent)}
          text='Lifetime Trade-In Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(ReturnPolicyContent)}
          text='30-Day Return Policy'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(LumeraAppraisalContent)}
          text='Free Appraisal'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(QualityGuaranteeContent)}
          text='Quality Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(SecurePackagingShippingContent)}
          text='Secure Packaging & Shipping'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(SafeShoppingGuaranteeContent)}
          text='Safe Shopping Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(ReferAFriendContent)}
          text='Refer a Friend program'
        />
        , and{' '}
        <TextButton
          onClick={e => setPopupContent(GiaCertificateContent)}
          text='GIA Certificate'
        />
        , Lumera hopes to instill complete confidence in your purchase.
      </p>
    </div>
  );
}
