// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Fluorescence';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Fluorescence refers to a diamond's tendency to emit a soft colored glow
        when subjected to ultraviolet light (such as a "black light"). Roughly
        30% of diamonds fluoresce to some degree.
      </p>

      <p>
        Colorless (D-F) fluorescent diamonds sell at up to a 15% discount since
        the fluorescence is perceived as a defect, even though the visible
        effects of Faint to Medium fluorescence are perceptible only to a
        gemologist using a special UV light source.
      </p>

      <p>
        Because the fluorescent glow is usually blue (which is the complementary
        color to yellow) fluorescence can make diamonds of I-M color appear up
        to one grade whiter. For this reason, I-M diamonds tend to sell at a
        slight premium when they possess Medium to Very Strong fluorescence.
      </p>

      <p>
        Exercise caution in purchasing a diamond with Strong fluorescence in D-F
        color diamonds or Very Strong fluorescence in G-H color diamonds (which
        do not possess enough body color to offset the higher degree of
        fluorescence).
      </p>

      <p>
        Learn more about diamond{' '}
        <a href='/diamond-education/diamond-fluorescence' target='_blank'>
          fluorescence
        </a>
        , including the results of a GIA study, photos, and the affect on price
        for various colors and clarity ranges.
      </p>
    </div>
  );
}
