// @flow
import { Component } from 'react';

import type { Node as NodeType } from 'react';

type TProps = {
  children: NodeType,
  enabled?: boolean,
  onClickOutside: (event: MouseEvent | TouchEvent) => void,
  elRef?: { current: HTMLElement | void | null },
  className?: string
};

// ref: https://stackoverflow.com/a/41652791/617243
export default class WatchClickOutside extends Component<TProps> {
  container: ?HTMLElement;

  componentDidMount() {
    if (typeof document === 'undefined' || !this.props.enabled) return;

    this.addListeners();
  }

  componentWillUnmount() {
    if (typeof document === 'undefined') return;

    // remember to remove all events to avoid memory leaks
    this.removeListeners();
  }

  componentDidUpdate(prevProps: TProps) {
    if (prevProps.enabled && !this.props.enabled) {
      this.removeListeners();
    } else if (!prevProps.enabled && this.props.enabled) {
      this.addListeners();
    }
  }

  addListeners = () => {
    if (document) {
      if (window.PointerEvent) {
        document.addEventListener('pointerup', this.handleClick);
      } else {
        document.addEventListener('click', this.handleClick);
        document.addEventListener('touchstart', this.handleClick);
      }
    }
  };

  removeListeners = () => {
    if (document) {
      if (window.PointerEvent) {
        document.removeEventListener('pointerup', this.handleClick);
      } else {
        document.removeEventListener('click', this.handleClick);
        document.removeEventListener('touchstart', this.handleClick);
      }
    }
  };

  handleClick = (event: MouseEvent | TouchEvent) => {
    let { container } = this;
    const { onClickOutside } = this.props;

    const { target } = event;

    // if there is no proper callback - no point of checking
    if (typeof onClickOutside !== 'function') return;

    if (!container) return;

    // If body does not contain target anymore, we removed the element for some reason (likely a transition) and the container can't contain the target. This will cause the callback to called when we don't want it to (container did contain target, but doesn't anymore).
    // if target is container - container was not clicked outside
    // if container contains clicked target - click was not outside of it
    if (target instanceof Node && document.documentElement.contains(target)) {
      if (target !== container && !container.contains(target)) {
        onClickOutside(event); // clicked outside - fire callback
      }
    }
  };

  render() {
    const { enabled, onClickOutside, elRef, ...otherProps } = this.props;
    return (
      <div
        ref={el => {
          this.container = el;
        }}
        {...otherProps}
      >
        {this.props.children}
      </div>
    );
  }
}
