// @flow

import TextButton from '@components/popups/TextButton';
import * as DiamondPlotContent from './DiamondPlot';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const cloud = '/images/glossary/cloud.gif';

export const Header = 'Cloud';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        A tight grouping of pinpoint inclusions. The individual pinpoints are
        often impossible to see under 10x magnification, but the cloud they form
        can be.{' '}
      </p>
      <img src={cloud} />
      <p>
        <TextButton
          onClick={(e) => setPopupContent(DiamondPlotContent)}
          text="Learn more about inclusion and blemish types, and how they are represented"
        />
        .
      </p>
    </div>
  );
}
