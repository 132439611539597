// @flow

import Carousel from '@components/carousels/diamond_search_popups/Color';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const giaColorGrades = '/images/diamond-education/gia-color-grades.jpg';

export const Header = 'Color';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        GIA grades diamonds on a scale of D (colorless) through Z (light color).
        All D-Z diamonds are considered white, even though they contain varying
        degrees of color.
      </p>

      <div className="multimedia-box">
        <img
          style={{ width: '100%' }}
          alt="GIA Diamond Color Grades"
          src={giaColorGrades}
        />
        <div
          className="caption"
          style={{ marginTop: 0, padding: 5, color: '#9b9b9b' }}>
          Diamonds of D, K, and Z
          <br />
          GIA Color Grade
        </div>
      </div>

      <p>
        Keep in mind that while virtually all diamonds sold are given a color
        grade using the GIA scale, most are not actually graded by GIA. A
        retailer may assign their own grade of D-Z. Always make sure the diamond
        you are considering is actually GIA graded. All Lumera loose diamonds
        are GIA graded.
      </p>

      <strong>GIA colors and definitions:</strong>

      <Carousel style={{ marginTop: 10 }} />

      <p>
        Color becomes much harder to detect once a stone is set in a ring and
        placed in an environment that contains color. The majority of untrained
        observers (and many gemologists) cannot distinguish a color grade from
        the one just above or below unless the diamonds are compared side by
        side in a controlled environment.
      </p>

      <p>
        <a href="/diamond-education/diamond-color" target="_blank">
          Learn more about diamond color.
        </a>
      </p>
    </div>
  );
}
