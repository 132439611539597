// @flow

import Carousel from '../../carousels/popups/ContourExamples';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Contour Band';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        A band which has been custom designed to conform to a specific
        engagement ring.
      </p>

      <Carousel />

      <p>
        Each of our contour bands is made for one specific engagement ring,
        which is displayed with the band on the band’s detail page.
      </p>
    </div>
  );
}
