// @flow

import TextButton from '@components/popups/TextButton';
import * as CrownContent from './Crown';
import * as PavilionContent from './Pavilion';
import * as FacetContent from './Facet';
import * as CuletContent from './Culet';
import * as CutContent from './Cut';
import * as ShapeContent from './Shape';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Step Cut';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        A cutting style that features long, narrow facets in rows (usually
        three) running parallel to the girdle on both the{' '}
        <TextButton onClick={e => setPopupContent(CrownContent)} text='crown' />{' '}
        and{' '}
        <TextButton
          onClick={e => setPopupContent(PavilionContent)}
          text='pavilion'
        />
        . The emerald cut is the most popular step cut. Like round brilliants,
        emerald cuts normally have 58{' '}
        <TextButton
          onClick={e => setPopupContent(FacetContent)}
          text='facets'
        />{' '}
        (including the{' '}
        <TextButton onClick={e => setPopupContent(CuletContent)} text='culet' />
        ). The table of a step cut diamond is usually square or rectangular,
        with beveled corners.
      </p>
      <p>
        Steps cuts produce a "hall-of-mirrors" effect with their interplay of
        light and dark panes, in contrast to the "crushed ice" appearance of
        most brilliant cuts.
      </p>
      <p>
        Learn more about{' '}
        <TextButton
          onClick={e => setPopupContent(CutContent)}
          text='diamond cut'
        />{' '}
        or{' '}
        <TextButton
          onClick={e => setPopupContent(ShapeContent)}
          text='diamond shapes'
        />
        .
      </p>
    </div>
  );
}
