// @flow

import TextButton from '@components/popups/TextButton';
import * as PricePerCaratContent from './PricePerCarat';
import * as CutContent from './Cut';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const diagram = '/images/diamond-education/diamond_cut_diagram.gif';

export const Header = 'Yield';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Yield refers to the weight of finished diamonds that are cut from the
        rough stone. Cutting a 1 carat diamond from a 2 carat rough stone would
        be a higher yield than cutting a 2 carat diamond from a 6 carat rough
        stone.
      </p>
      <img
        src={diagram}
        alt="Diamond Cuts"
        style={{ width: 175, display: 'block', margin: '0 auto' }}
      />
      <p>
        Two potential diamonds can be cut from the same rough stone (shown in
        blue).
      </p>
      <p>
        A too-deep cut diamond (orange) would yield a significantly larger
        diamond, earning the diamond cutter a larger profit on his investment.
      </p>
      <p>
        A smaller, well cut diamond (white) may sell for less in total than the
        larger diamond, but it will command a higher{' '}
        <TextButton
          onClick={(e) => setPopupContent(PricePerCaratContent)}
          text="price-per-carat"
        />{' '}
        not only because of its superior appearance, but also due to decreased
        yield from the rough stone (which makes the diamond more expensive to
        create).
      </p>
      <p>
        <TextButton
          onClick={(e) => setPopupContent(CutContent)}
          text="Learn more about cut"
        />
        , and its affect on diamond appearance and value.
      </p>
    </div>
  );
}
