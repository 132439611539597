// @flow

import * as GirdleContent from './Girdle';
import * as TableContent from './Table';
import * as DiamondAnatomyContent from './DiamondAnatomy';

import TextButton from '@components/popups/TextButton';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const crown = '/images/glossary/crown.gif';

export const Header = 'Crown';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <img
        src={crown}
        style={{ margin: '0 auto 10px auto', width: '80%', display: 'block' }}
      />
      <p>
        The entire portion of the diamond that sits above the{' '}
        <TextButton
          onClick={(e) => setPopupContent(GirdleContent)}
          text="girdle"
        />
        . The crown includes the{' '}
        <TextButton
          onClick={(e) => setPopupContent(TableContent)}
          text="table"
        />
        , as well as other crown facets. The crown height is the vertical
        distance from the girdle to the table when viewed from the side. Crown
        height may be expressed in mm, or as a percentage of the diamond's
        average diameter.{' '}
      </p>
      <p>
        Learn more about the{' '}
        <TextButton
          onClick={(e) => setPopupContent(DiamondAnatomyContent)}
          text="anatomy of a diamond"
        />
        .
      </p>
    </div>
  );
}
