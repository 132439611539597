// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Privacy Policy';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        When you visit Lumera, we track the pages you visit in order to supply you with helpful information (such as recently viewed items) as well as to understand how Lumeradiamonds.com is used by our guests.  We do not utilize this information to target our users with promotions, emails, or marketing of any kind.
      </p>
      <p>
        When you place an order, Lumera uses your email and phone number (if necessary) to communicate regarding delivery.   Your contact information is used for no other purpose.  Lumera will not share your information with anyone, except as necessary to complete your purchase (such as sharing your delivery address with FedEx), or as required by law (such as law-enforcement agents, in the case of fraud). The sole purpose of the information we gather is to provide you better service.
      </p>
      <h2>Information Collected</h2>
      <p>
        Following are the means used for collecting information, and specific
        information collected from visitors:
      </p>
      <div id='customerBox4'>
        <h3>Cookies </h3>
        <p>
          A cookie is a piece of data stored on a visitor's computer, containing
          information about the visit. Information collected by cookies allows
          us to recognize a user on subsequent visits, to know what pages of the
          website were visited, and to keep a visitor logged in as they travel
          around the website. Use of cookies is in no way linked to any
          personally identifiable information about a visitor.{' '}
        </p>
        <h3>Log Files</h3>
        <p>
          Like most websites, our server automatically recognizes the Internet
          URL from which a visitor accesses LumeraDiamonds.com. We may also log
          a visitor's Internet protocol ("IP") address, Internet service
          provider, and date/time stamp for system administration, order
          verification, and system troubleshooting purposes. IP addresses are
          not linked to personally identifiable information.
        </p>
        <h3>Email Address / Username</h3>
        <p>
          In order to save diamonds for future review and purchase, visitors
          create a username using their email address. This email address is not
          used for any other purpose. Lumera does not send emails to the
          address, or share it with other parties.
        </p>
        <h3>Order Information</h3>
        <p>
          When a visitor places an order, they are required to enter their
          shipping, billing, and payment information. This information is used
          for no purpose other than fulfilling the order. The credit card
          information is not stored after the order is shipped. Therefore it is
          impossible for the user's sensitive information to be compromised in
          the future.
          <strong />
        </p>
      </div>
      <h2>Security</h2>
      <p>
        Lumera Diamonds incorporates physical, electronic, and administrative
        procedures to safeguard the confidentiality of visitor's personal
        information. Among those procedures are the following:
      </p>
      <div id='customerBox4'>
        <h3>Data Encryption</h3>
        <p />
        <div style={{ width: '135px', float: 'right' }} />
        All sensitive information entered online (such as payment information)
        is protected by VeriSign secure socket layer (SSL) software, the
        industry standard for secure commerce transactions. Your credit card
        number is encrypted so that it cannot be read by others as the
        information passes over the internet.
        <p />
        <h3>Employee Access</h3>
        <p>
          Your information is not available to any employee of Lumera Diamonds,
          except as required to serve you (such as giving your delivery address
          to the shipping department, or your phone number to customer service).
        </p>
        <h3>Verification</h3>
        <p>
          Lumera employs a third party monitoring service to independently
          confirm that data is being encrypted where appropriate, that the site
          is secure, and that each visitor's privacy is respected. Look for the
          following seals:
        </p>
        <p />
      </div>
      <h2>CUSTOMER CONTACT</h2>
      <p>
        When you place an order, we will contact you regarding that order, using
        only the email or phone number you specified. Out of sensitivity to any
        surprise you may be planning and out of respect for your privacy, you
        will not be contacted or emailed for any other reason, now or in the
        future.
      </p>
      <h2>WEBSITE ERRORS</h2>
      <p>
        Although we take great care to insure the accuracy of all aspects of the
        website, errors are rare but inevitable. Lumera cannot honor inaccurate
        information or prices. However, if an error is discovered pertaining to
        an item already ordered, you will be made aware of the error, and given
        the choice of amending or canceling your order at no cost or penalty.
      </p>
      <h2>QUESTIONS</h2>
      <p>
        For questions regarding this privacy policy, please contact us at{' '}
        <a rel='nofollow' href='mailto:CustomerService@LumeraDiamonds.com'>
          CustomerService@LumeraDiamonds.com
        </a> or call {' '}
        <span className='no-wrap'><a href="tel:+01-888-658-6372">1-888-658-6372</a></span>.
      </p>
    </div>
  );
}
