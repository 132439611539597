// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Insurance';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Jewelers Mutual's Personal Jewelry Insurance program provides protection
        for loss, theft, damage, and mysterious disappearance on finished pieces
        of jewelry. Jewelers Mutual will repair or replace your insured jewelry
        with like kind and quality. The policy is available with no deductible,
        unless you choose one.
      </p>
      <p>
        You can get a free quote anytime, or apply online after you purchase, at{' '}
        <a href='https://JewelersMutual.com/Protect' target='_blank'>
          Jewelers Mutual
        </a>{' '}
        (enter code <b>A01475</b>). Coverage will begin on the date you submit
        your online application. To maintain coverage, be sure to fax or email a
        copy of the appraisal you receive with your purchase within 10 days of
        applying (you will receive a reminder with your order). Upon request,
        Lumera will email a copy of your appraisal to you, which can be
        forwarded to Jewelers Mutual (or any other insurance company).{' '}
      </p>
      <p>
        Lumera is not a licensed insurance agent for Jeweler's Mutual and cannot
        sell or recommend insurance. Lumera offers this option as a service to
        our customers, and in no way benefits from your participation.
      </p>
      <h2>More about Jewelers Mutual:</h2>
      <ul>
        <li style={{ margin: '0 0 0 15px' }}>Established in 1913 </li>
        <li style={{ margin: '0 0 0 15px' }}>
          A+ Superior rating for the past 21 years by A.M. Best Company{' '}
        </li>
        <li style={{ margin: '0 0 0 15px' }}>
          Only insurer to specialize in jewelry coverage
        </li>
        <li style={{ margin: '0 0 0 15px' }}>
          $2 billion of individuals' personal jewelry insured{' '}
        </li>
        <li style={{ margin: '0 0 0 15px' }}>Licensed in all 50 states </li>
        <li style={{ margin: '0 0 0 15px' }}>
          GIA Graduate Gemologists on staff{' '}
        </li>
        <li style={{ margin: '0 0 10px 15px' }}>
          Dedicated to preventing jewelry theft with loss prevention education{' '}
        </li>
      </ul>
      <p>
        <i>
          (Must be a U.S. resident age 18 years or older to apply. Jewelers
          Mutual is an alternative to other insurance companies you may be
          considering. Insurance coverage is not guaranteed, subject to
          underwriting guidelines and application approval.)
        </i>
      </p>
      <p>
        Your Lumera Diamond purchase is covered by our own insurance up to the
        moment it is signed for at delivery. You are not liable in any way up to
        that point. Once received, most customers choose to have their purchase
        insured.
      </p>
      <p>
        Insurance for your jewelry or engagement ring is a good idea for any
        item you cannot readily afford to lose (a good guideline might be
        anything over two weeks pay in value). Your existing home owner's or
        renter's insurance may already provide coverage (subject to a
        deductible) for jewelry. Depending on the value of your item, you may
        need to purchase a separate policy or rider to obtain full coverage.
        Check with your insurer after any new purchase to understand your
        coverage and liability.
      </p>
      <p>
        You can receive an instant jewelry insurance quote for any piece of
        jewelry you own or are contemplating the purchase of , using the{' '}
        <a href='https://JewelersMutual.com/Protect' target='_blank'>
          Jewelers Mutual instant quote tool
        </a>
        . Just enter code <b>A01475</b> (letter A, followed by the number
        01475), along with your email (used only to contact you at your
        request), zip code (location affects insurance rates) and the value of
        your item. You will receive an instant quote for annual coverage, as
        well as options to apply online or learn more. This quote can be used to
        compare with other rates, such as those you receive from your current
        insurance provider.
      </p>
    </div>
  );
}
