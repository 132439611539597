// @flow

import * as FacetContent from './Facet';
import * as CuletContent from './Culet';
import * as BrillianceContent from './Brilliance';
import * as CutContent from './Cut';
import * as ShapeContent from './Shape';

import TextButton from '../TextButton';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Brilliant cut';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Generally used to refer to diamonds of any shape with facets that are
        mostly triangular or kite shaped, and that radiate from the center. By
        far, the most popular brilliant cut is round, which has 58{' '}
        <TextButton
          onClick={e => setPopupContent(FacetContent)}
          text='facets'
        />{' '}
        (including the{' '}
        <TextButton onClick={e => setPopupContent(CuletContent)} text='culet' />
        ). Other popular brilliant cuts are oval, marquise, radiant, cushion,
        pear, and heart shapes.
      </p>
      <p>
        As their name suggests, brilliant cut diamonds usually have more{' '}
        <TextButton
          onClick={e => setPopupContent(BrillianceContent)}
          text='brilliance'
        />{' '}
        than step cut diamonds (such as emerald and asscher) due to the
        position, number, and angle of their facets.
      </p>
      <p>
        Learn more about{' '}
        <TextButton
          onClick={e => setPopupContent(CutContent)}
          text='diamond cut'
        />{' '}
        or{' '}
        <TextButton
          onClick={e => setPopupContent(ShapeContent)}
          text='diamond shapes'
        />
        .
      </p>
    </div>
  );
}
