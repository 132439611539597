// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const image = '/images/popups/shape/pear.jpg';

export const Header = 'Pear Shaped Diamonds';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <img
        src={image}
        style={{ float: 'left', width: 55, marginTop: 18, marginRight: 13 }}
      />
      <span style={{ fontWeight: 'bold' }}>Pear</span>
      <p>
        The pear shape is a combination of a round and a marquise, with a
        tapered point on one end. The diamond is always worn with the narrow end
        pointing toward the hand of the wearer.
      </p>

      <p>
        Ideally, a pear shaped diamond should possess excellent or very good
        symmetry. The point should line up with the apex of the rounded end.
      </p>

      <p>
        A pear diamond should always be set with a prong at the point - the most
        likely location for chipping on a pear cut diamond.
      </p>

      <p>
        <a href="/diamond-education/pear-shaped-diamond" target="_blank">
          Learn more about pear shaped diamonds
        </a>
        , including their history, as well as how to evaluate cut parameters,
        color, and clarity.
      </p>
    </div>
  );
}
