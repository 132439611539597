// @flow

import TextButton from '@components/popups/TextButton';
import * as NaturalContent from './Natural';
import * as DiamondPlotContent from './DiamondPlot';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const indented = '/images/glossary/indented.gif';

export const Header = 'Indented Natural';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p className="style1">
        A{' '}
        <TextButton
          onClick={(e) => setPopupContent(NaturalContent)}
          text="natural"
        />{' '}
        that leaves an actual depression or indentation in the finished
        diamond's surface.
      </p>
      <img src={indented} />
      <p>
        <TextButton
          onClick={(e) => setPopupContent(DiamondPlotContent)}
          text="Learn more about inclusion and blemish types, and how they are represented"
        />
        .
      </p>
    </div>
  );
}
