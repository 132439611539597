// @flow

import * as InclusionContent from './Inclusion';
import * as CutContent from './Cut';

import TextButton from '../TextButton';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Brilliance';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Brilliance refers to the brightness of a diamond, created by the
        combination of all the white light reflections from the surface and the
        inside of a polished diamond to the eye. Contrast refers to the light
        and dark areas seen in a diamond. The dark areas are not{' '}
        <TextButton
          onClick={e => setPopupContent(InclusionContent)}
          text='inclusions'
        />
        , they are shadows created by the cut of the diamond or objects between
        the light source and the diamond (such as the observer's head).
      </p>
      <p>
        The greater the amount of reflected light, combined with strong
        contrasts, the more brilliant the stone. A round diamond will typically
        exhibit more brilliance than a fancy shape due to the superior mechanics
        of the round cut for reflecting light.
      </p>
      <p>
        <TextButton onClick={e => setPopupContent(CutContent)} text='Cut' /> is
        the most important factor in achieving brilliance in a diamond.
      </p>
    </div>
  );
}
