// @flow
import Carousel from '../TextOnly'

const items = [
  {
    headerText: 'Excellent',
    text: 'No polish defects visible at 10x magnification.'
  }, {
    headerText: 'Very Good',
    text: 'Any defects are extremely difficult to see at 10x magnification.'
  }, {
    headerText: 'Good',
    text: 'Any defects are difficult to see at 10x magnification.'
  }, {
    headerText: 'Fair',
    text: 'Defects are noticeable at 10x magnification, and may be visible to the naked eye.'
  }, {
    headerText: 'Poor',
    text: 'Defects are visible to the naked eye. Not offered by Lumera.'
  }
]

export default (props: Object) => {
  return (
    <Carousel
      {...props}
      items={items}
      textColor='#7f7f7f'
      textAlign='left'
      contentStyles={{ margin: '10px 15px', minHeight: 86 }}
    />
  )
};
