// @flow
import { useEffect, useState } from 'react';

import video from '@videos/presentation_solitaire.mp4';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const solitaireLinkHref = '/engagement-rings/detail/123213:330:P';

export const Header = 'Presentation Solitaire';

export function Content({ setPopupContent }: TProps) {
  const [showVideo, setShowVideo] = useState(false);

  {
    /*
      If a .mediabox element is the last element, it messes up the
      auto-growing/auto-scrolling of bubbles. This effect allows us not to show
      the video until after the growing/scrolling should be completed.
    */
  }
  useEffect(() => {
    let timeout;

    if (typeof window !== 'undefined') {
      timeout = setTimeout(() => setShowVideo(true), 1500);

      return () => clearTimeout(timeout);
    }
  }, []);

  return (
    <div>
      <p>Shopping for an engagement ring can be challenging for two reasons:</p>
      <ol className='no-item-bottom-margins'>
        <li>Not knowing the correct ring size</li>
        <li>Not knowing what style to choose</li>
      </ol>
      <p>
        Our{' '}
        <a href={solitaireLinkHref} target='_blank'>
          Presentation Solitaire
        </a>{' '}
        is offered to address both concerns.
      </p>
      <p>
        Any classic solitaire, including our{' '}
        <a href={solitaireLinkHref} target='_blank'>
          Presentation Solitaire
        </a>
        , can be quickly and easily resized. A local jeweler will likely be able
        to alter your ring size in as little as one day. The cost is usually
        minimal, although moving up several sizes will cost more (since gold or
        platinum is being added) than sizing down (where precious metal is
        actually being removed).
      </p>
      <p>
        Every Lumera purchase comes with a standard 30 day return or exchange.
        In addition, an extended <strong>One Year Exchange</strong> window is
        offered on our{' '}
        <a href={solitaireLinkHref} target='_blank'>
          Presentation Solitaire
        </a>{' '}
        to accommodate those who want to purchase a ring now, but also want to
        give the recipient time to choose what they like, and not be rushed.
      </p>
      <p>
        <strong>To take advantage of the One Year Exchange option:</strong>
      </p>
      <ul className='no-item-bottom-margins'>
        <li>
          Purchase the{' '}
          <a href={solitaireLinkHref} target='_blank'>
            Presentation Solitaire
          </a>
          , set with the center diamond of your choice.
        </li>

        <li>
          Within one year of your original purchase, exchange your Presentation
          Solitaire for any fancy (non-solitaire) setting of equal or greater
          value.
        </li>

        <li>
          Please note: the replacement setting itself is not eligible for return
          or exchange.
        </li>

        <li>
          The <strong>One Year Exchange</strong> applies only to the{' '}
          <a href={solitaireLinkHref} target='_blank'>
            Presentation Solitaire
          </a>
          , and not to any band, or other purchases.
        </li>
      </ul>
      <p>
        Our Presentation Solitaire is not a temporary setting. This beautiful
        raised edge style, available in gold and platinum, is our most popular
        setting, which is why it was chosen for this offer.
      </p>
      {showVideo ? (
        <div className='multimedia-box'>
          <video
            autoPlay
            loop
            style={{ maxWidth: '100%', display: showVideo ? 'unset' : 'none' }}
          >
            <source src={video} type='video/mp4' />
            <p>
              <a href={video}>video link</a>
            </p>
          </video>
          <div
            className='caption'
            style={{ marginTop: 0, padding: 14, color: '#9b9b9b' }}
          >
            Presentation Solitaire
          </div>
        </div>
      ) : null}
    </div>
  );
}
