// @flow

import Carousel from '@components/carousels/diamond_search_popups/Clarity';
import SecondCarousel from '@components/carousels/popups/DiamondClarity';

import * as DiamondPlotContent from '@components/popups/content/DiamondPlot';

import TextButton from '../TextButton';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Clarity';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p className='no-bottom-margin'>
        Clarity refers to the degree to which imperfections in or on a diamond
        are present. Almost all diamonds are graded for clarity using the 11
        point scale created by the GIA, including diamonds which were not
        actually graded by GIA. In grading diamond clarity, the GIA considers
        the number, size, color, reflectivity, and position of every flaw
        visible under 10x magnification.
      </p>

      <Carousel />

      <p>
        <strong>Clarity Definitions:</strong>
        <br />
        Because a photograph cannot capture the subtleties of clarity, GIA uses
        a{' '}
        <TextButton
          onClick={e => setPopupContent(DiamondPlotContent)}
          text='diamond plot'
        />{' '}
        to map a diamond's interior and exterior flaws.
      </p>

      <p>
        Common inclusions and blemishes, as they are represented on the GIA
        diamond plot, are illustrated below. GIA uses the color green for
        surface blemishes, and red for internal inclusions.
      </p>

      <SecondCarousel setPopupContent={setPopupContent} />

      <p>
        <strong>Buying Tips:</strong>
        <br />
        If you cannot tolerate imperfections, even those you cannot see, choose
        a VVS2 or better diamond. About 10% of all diamonds sold fall into this
        category.
      </p>

      <p>
        The most popular range is the VS1-VS2 diamond. These diamonds appear
        flawless to the naked eye, and are a fraction of the price of a truly
        flawless diamond. Almost half of all diamonds purchased fall into this
        range.
      </p>

      <p>
        The next most popular range is SI1, where the inclusions are usually not
        significant enough to impact the appearance of the diamond for the
        casual observer. Often, customers will opt for this clarity range in
        \exchange for a higher cut or color grade. This combination often
        results in a beautiful, lively diamond with imperfections detectable
        only upon close inspection. In diamonds under 1 carat, the same can be
        said for an SI2 grade. In diamonds over 1 carat (where clarity is more
        important, and SI2 inclusions are often easier to detect), an SI2 is
        often half the price of a VS1 diamond. About one third of diamonds sold
        fall into the SI1-SI2 range.
      </p>

      <p>
        The larger the diamond, the easier imperfections are to detect;
        therefore Clarity becomes more important. For diamonds over 2 carats, a
        clarity grade of VS2 or higher is the safest bet for avoiding any signs
        of visible inclusions. In diamonds between 1 and 2 carats, clarity
        grades of SI1 or better will not have inclusions easily visible to the
        naked eye. In diamonds under 1 carat, clarity should be considered the
        least important of the traditional 4 Cs.
      </p>

      <p>
        Brilliant-cut diamonds (such as round, princess, cushion, oval, pear,
        and marquise) hide inclusions better than step cuts (emerald, asscher).
        When purchasing a step-cut, move up one clarity grade (e.g. purchase a
        VS2 instead of an SI1 if you desire the lowest grade that has no visible
        inclusions).
      </p>

      <p>
        If, while shopping for a diamond, you are ever given a clarity range
        (e.g. SI1-SI2) as opposed to a specific clarity grade, the diamond is
        not certified by GIA. The seller is only estimating the diamond's
        clarity using GIA terminology.
      </p>

      <p>
        <a href='/diamond-education/diamond-clarity' target='_blank'>
          Learn more about diamond clarity
        </a>
        , including the different types of inclusions.
      </p>
    </div>
  );
}
