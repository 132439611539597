// @flow
import DynamicBase from './DynamicBase';

const showDelay = 50;

type TProps = {
  selector: string,
  initialContent: {
    Header?: string,
    Content: Node
  }
};

type ContainerStateType = {
  showPopup: boolean
};

export default class ExternalElementPopup extends React.Component<
  TProps,
  ContainerStateType
> {
  targetRef: { current: void | null | HTMLElement };
  doNotCauseShow: boolean;

  state = {
    showPopup: false
  };

  constructor(props, opts) {
    super(props, opts);

    this.targetRef = React.createRef();
    this.doNotCauseShow = false;
  }

  componentDidMount() {
    if (typeof document === 'undefined') return;

    this.targetRef.current = document.querySelector(
      `[data-popup=${this.props.selector}]`
    );

    if (this.targetRef.current) {
      const target = this.targetRef.current;

      target.addEventListener('mouseup', this.showPopup);
      target.addEventListener('touchend', this.showPopup);

      target.addEventListener('keypress', this.handleKeyPress);
      // target.addEventListener('blur', this.handleBlur);
    }
  }

  componentWillUnmount() {
    if (this.targetRef.current) {
      const target = this.targetRef.current;

      target.removeEventListener('mouseup', this.showPopup);
      target.removeEventListener('touchend', this.showPopup);

      target.removeEventListener('keypress', this.handleKeyPress);
      // target.removeEventListener('blur', this.handleBlur);
    }
  }

  handleKeyPress = (e: SyntheticKeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      this.setState({ showPopup: !this.state.showPopup });
    }
  };

  handleBlur = (e: SyntheticKeyboardEvent<HTMLElement>) => {
    this.setState({ showPopup: false });
  };

  showPopup = (e: SyntheticMouseEvent<HTMLElement>) => {
    e.preventDefault();

    // We have to make sure the mouse button is up before showing the popup or WatchClickOutside will close the popup immediately
    setTimeout(() => {
      if (!this.doNotCauseShow && !this.state.showPopup)
        this.setState({ showPopup: true });
    }, showDelay);
  };

  closePopup = () => {
    this.doNotCauseShow = true;

    this.setState({ showPopup: false }, () =>
      setTimeout(() => {
        this.doNotCauseShow = false;
      }, showDelay + 25)
    );
  };

  render() {
    return (
      <DynamicBase
        {...this.props}
        show={this.state.showPopup}
        onCloseClick={this.closePopup}
        anchorElement={this.targetRef.current}
      />
    );
  }
}
