// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const lengthWidth = '/images/diamond-education/length_width.jpg';

export const Header = 'Length / Width Ratio';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p className="no-bottom-margin">
        The length to width (L/W) ratio is calculated by dividing a diamond's
        length (the longer measure) by its width (the shorter measure that runs
        perpendicular to length).
      </p>

      <div
        style={{
          paddingLeft: 10,
          paddingTop: 19,
          paddingBottom: 18,
          textAlign: 'center'
        }}>
        <img
          src={lengthWidth}
          alt="Diamond length / width ratio"
          style={{ width: 168 }}
        />
      </div>

      <p>
        For example, an Oval Cut diamond that is 5.00 mm in length and 3.3 mm in
        width will have a L/W ratio of 1.50 (5.00/3.33). A perfectly round or
        square diamond will have a length to width ratio of 1.00, but many will
        be off slightly since they are often not perfectly cut. Any L/W ratio of
        1.05 or less will appear perfectly round or square to the naked eye.
      </p>

      <p>
        L/W ratio is more commonly used in fancy shapes. There is no "ideal" L/W
        ratio in a fancy shape diamond. What is important is what looks pleasing
        to you.
      </p>
    </div>
  );
}
