// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const depthWidth = '/images/diamond-education/depth_width.jpg';

export const Header = 'Depth';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Depth refers to the distance between the culet and the table when a
        diamond is viewed from the side.
      </p>

      <div
        style={{
          paddingLeft: 20,
          paddingTop: 1,
          paddingBottom: 32,
          textAlign: 'center'
        }}>
        <img
          src={depthWidth}
          alt="Diamond depth and width"
          style={{ width: 197 }}
        />
      </div>

      <p>
        The depth % of a diamond is calculated by dividing the depth of the
        diamond by the width of the diamond. So, if a diamond is 3 mm in depth,
        and 4.5 mm in width, its depth % is 66.7.
      </p>

      <p>
        The lower the depth %, the larger a diamond of a given carat weight will
        appear when viewed from above (since more of the diamond's size is in
        its width vs. in its depth).
      </p>

      <p>
        Depth % is incorporated in the cut grade of a diamond. For this reason,
        when purchasing a diamond, use the Cut grade first (since it balances
        multiple measurements, not just depth %). Once two diamonds of equal cut
        are being compared, consider depth % as a further refinement
      </p>
    </div>
  );
}
