// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const round10 = '/images/diamond-education/10.svg';
const round20 = '/images/diamond-education/20.svg';
const round30 = '/images/diamond-education/30.svg';
const caratWeightComparison =
  '/images/diamond-education/carat-weight-comparison.jpg';

export const Header = 'Carat Weight';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Though most think of a carat (ct.) in terms of size, the carat is
        actually a unit of weight, equal to exactly 0.2 grams (about the weight
        of a paper clip).
      </p>

      <p>
        As carat weight increases, price increases at an even faster rate, due
        to the increasing rarity of larger sizes of rough diamonds. So, as carat
        weight increases, you will typically pay more not only in total, but on
        a price-per -carat basis as well. The table below illustrates the
        typical relationship between diamonds of equal quality and increasing
        carat weights:
      </p>

      <table>
        <thead>
          <tr>
            <th>Carat Weight</th>
            <th>1.00</th>
            <th>2.00</th>
            <th>3.00</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Price / carat</td>
            <td>$6,000</td>
            <td>$12,000</td>
            <td>$18,000</td>
          </tr>
          <tr>
            <td>Total Price</td>
            <td>$6,000</td>
            <td>$24,000</td>
            <td>$54,000</td>
          </tr>
        </tbody>
      </table>

      <p>
        Even though the price of a diamond increases exponentially with the
        carat weight, the actual size does not. The table below illustrates the
        typical size relationship between diamonds of increasing carat weights.
        Note that when carat weight triples (from 1 to 3 carats), perceived size
        (represented in the images below) roughly triples as well, even though
        the diameter increases only 45%.
      </p>

      <table>
        <thead>
          <tr>
            <th>Carat Weight</th>
            <th>1.00</th>
            <th>2.00</th>
            <th>3.00</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Approx. Size</td>
            <td style={{ width: 50 }}>
              <img
                src={round10}
                alt="1ct Round diamond"
                style={{ width: 24 }}
              />
            </td>
            <td style={{ width: 50 }}>
              <img
                src={round20}
                alt="2ct Round diamond"
                style={{ width: 29 }}
              />
            </td>
            <td style={{ width: 60 }}>
              <img
                src={round30}
                alt="3ct Round diamond"
                style={{ width: 33 }}
              />
            </td>
          </tr>
          <tr>
            <td>Diameter (mm)</td>
            <td>6.50</td>
            <td>8.20</td>
            <td>9.40</td>
          </tr>
          <tr>
            <td>
              Crown (mm<sup>2</sup>)
            </td>
            <td>33.2</td>
            <td>52.8</td>
            <td>69.4</td>
          </tr>
        </tbody>
      </table>

      <p>
        When viewing diamonds on Lumera, look beyond the carat weight, and check
        the measurements listed for each diamond to understand its true size.
      </p>

      <p>
        Two diamonds of the same carat weight may appear different in size based
        on the cut proportions. A deeply cut diamond has a greater proportion of
        its total weight "hidden" in the depth, resulting in a smaller diameter
        than a well cut diamond. A well cut diamond may even have a slightly
        lower carat weight than a deeply cut diamond, yet still have a larger
        diameter, making it appear larger in size.
      </p>

      <div className="multimedia-box">
        <img
          src={caratWeightComparison}
          style={{ margin: '0 10px' }}
          alt="Diamond Weight Comparison Chart"
        />
        <div
          className="caption"
          style={{ marginTop: 0, padding: 14, color: '#9b9b9b' }}>
          The diamond on the left has a deep cut and appears smaller from above
        </div>
      </div>

      <p>
        <a href="/diamond-education/diamond-carat-weight" target="_blank">
          Learn more about carat weight
        </a>
        ,<br />
        including how various diamond shapes of the same weight compare in size.
      </p>
    </div>
  );
}
