// @flow

import TextButton from '@components/popups/TextButton';
import * as GiaContent from './Gia';
import * as GiaGradingReportContent from './GiaGradingReport';
import * as GiaDossierContent from './GiaDossier';
import * as CertificationContent from './Certification';
import * as LifetimeTradeInGuaranteeContent from './LifetimeTradeInGuarantee';
import * as QualityGuaranteeContent from './QualityGuarantee';
import * as SafeShoppingGuaranteeContent from './SafeShoppingGuarantee';
import * as LumeraAppraisalContent from './LumeraAppraisal';
import * as SecurePackagingShippingContent from './SecurePackagingShipping';
import * as FedExDeliveryContent from './FedExDelivery';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const diamondGradingReportIcon =
  '/images/diamond-education/GIA_diamond_grading_report.gif';

export const Header = 'GIA Certificate';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        A GIA certificate (technically referred to as a lab report) is an
        impartial judgment on the characteristics and quality of a given diamond
        as determined by the{' '}
        <TextButton onClick={(e) => setPopupContent(GiaContent)} text="GIA" />.
        Every certified Lumera Diamond is accompanied by its GIA{' '}
        <TextButton
          onClick={(e) => setPopupContent(GiaGradingReportContent)}
          text="Grading Report"
        />{' '}
        or{' '}
        <TextButton
          onClick={(e) => setPopupContent(GiaDossierContent)}
          text="Dossier"
        />
        , the two types of certification offered by GIA.
      </p>
      <p>
        Learn more about the content{' '}
        <TextButton
          onClick={(e) => setPopupContent(CertificationContent)}
          text="certification"
        />{' '}
        process.
      </p>

      <p>
        The certificate for a Lumera Diamond may be viewed prior to purchase by
        clicking the name "GIA" next to any diamond.
      </p>

      <div className="clearfix" style={{ marginBottom: 30 }}>
        <div style={{ marginBottom: 30 }}>
          <a href="/files/GIA_grading_report.pdf" target="_blank">
            <img
              src={diamondGradingReportIcon}
              style={{ width: 40, float: 'left', marginRight: 10 }}
            />
          </a>{' '}
          <a href="/files/GIA_grading_report.pdf" target="_blank">
            View a sample GIA Grading Report
          </a>
        </div>
        <div>
          <a href="/files/GIA_Dossier.pdf" target="_blank">
            <img
              src={diamondGradingReportIcon}
              style={{ width: 40, float: 'left', marginRight: 10 }}
            />
          </a>{' '}
          <a href="/files/GIA_Dossier.pdf" target="_blank">
            View a sample GIA Dossier
          </a>
        </div>
      </div>

      <p>
        By offering a GIA Certificate, as well as our{' '}
        <TextButton
          onClick={(e) => setPopupContent(LifetimeTradeInGuaranteeContent)}
          text="Lifetime Trade-In Guarantee"
        />
        ,{' '}
        <TextButton
          onClick={(e) => setPopupContent(QualityGuaranteeContent)}
          text="Quality Guarantee"
        />
        ,{' '}
        <TextButton
          onClick={(e) => setPopupContent(SafeShoppingGuaranteeContent)}
          text="Safe Shopping Guarantee"
        />
        ,{' '}
        <TextButton
          onClick={(e) => setPopupContent(LumeraAppraisalContent)}
          text="Free Appraisal"
        />
        ,{' '}
        <TextButton
          onClick={(e) => setPopupContent(SecurePackagingShippingContent)}
          text="Secure Packaging & Shipping"
        />{' '}
        and{' '}
        <TextButton
          onClick={(e) => setPopupContent(FedExDeliveryContent)}
          text="Insured Express Delivery"
        />
        , Lumera hopes to instill complete confidence in your purchase.
      </p>
    </div>
  );
}
