// @flow

import TextButton from '@components/popups/TextButton';
import * as PavilionContent from './Pavilion';
import * as FacetContent from './Facet';
import * as TableContent from './Table';
import * as GiaContent from './Gia';
import * as CrownContent from './Crown';
import * as InclusionContent from './Inclusion';
import * as DiamondAnatomyContent from './DiamondAnatomy';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const diamondCuletSize = '/images/diamond-education/diamond_culet_size.gif';

export const Header = 'Culet';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <img src={diamondCuletSize} alt="Diamond Culet Size" />
      <p>
        The culet (pronounced cue-let) is the small area at the bottom of a
        diamond's{' '}
        <TextButton
          onClick={(e) => setPopupContent(PavilionContent)}
          text="pavilion"
        />
        . The culet can be a point or a very small{' '}
        <TextButton
          onClick={(e) => setPopupContent(FacetContent)}
          text="facet"
        />{' '}
        sitting parallel to the{' '}
        <TextButton
          onClick={(e) => setPopupContent(TableContent)}
          text="table"
        />
        .
      </p>
      <p>
        Every Lumera Diamond displays the culet size as determined by the{' '}
        <TextButton onClick={(e) => setPopupContent(GiaContent)} text="GIA" />,
        using the following scale:
      </p>
      <p className="blue-text">
        <b>
          None, Very Small, Small, Medium, Slightly Large, Large, Very Large.
        </b>{' '}
      </p>
      <p>
        Any culet size of Medium or smaller will be invisible to the naked eye,
        and have no negative impact on a diamond's appearance. However, if a
        culet is Slightly Large or larger, it may allow light entering from the{' '}
        <TextButton
          onClick={(e) => setPopupContent(CrownContent)}
          text="crown"
        />{' '}
        to pass straight through the culet facet, reducing the diamond's
        brilliance. This may also make the culet appear as an{' '}
        <TextButton
          onClick={(e) => setPopupContent(InclusionContent)}
          text="inclusion"
        />
        , or create a dead area on the diamond where the light is escaping
        through the bottom.
      </p>
      <p>
        Learn more about the{' '}
        <TextButton
          onClick={(e) => setPopupContent(DiamondAnatomyContent)}
          text="anatomy of a diamond"
        />
        .
      </p>
    </div>
  );
}
