// @flow

import TextButton from '@components/popups/TextButton';
import * as ShapeContent from './Shape';
import * as LengthToWidthRatioContent from './LengthToWidthRatio';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const sizeChartImage = '/images/buying-diamonds/size_chart.svg';

export const Header = 'Size & Shape on Ring';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        The diamond displayed is a sample image, not the actual diamond.
        However, the sample image does match the{' '}
        <TextButton
          onClick={(e) => setPopupContent(ShapeContent)}
          text="shape"
        />{' '}
        and{' '}
        <TextButton
          onClick={(e) => setPopupContent(LengthToWidthRatioContent)}
          text="length to width ratio"
        />{' '}
        of the actual diamond. This means you can use the sample image to see
        the true shape of the diamond; whether elongated, near-square, wide,
        etc.
      </p>

      <p>
        The ring and diamond are not shown actual size; they are enlarged to
        show detail. The image does accurately depict how a diamond of this size
        will look when set in a ladies band of 2 millimeter width.
      </p>

      <p>
        If you would like to see the true size of this or other diamonds, print
        the Actual Diamond Size Chart below:
      </p>

      <section>
        <a href="/files/size_chart.pdf" target="_blank">
          <img
            src={sizeChartImage}
            style={{
              float: 'left',
              margin: '0 20px 10px 0',
              border: 0,
              width: 43
            }}
          />
        </a>

        <h2 style={{ paddingTop: 3 }}>PRINTABLE SIZE CHART</h2>

        <p style={{ marginBottom: 35 }}>
          Print a copy of our diamond size chart to see the actual size of a
          diamond.
        </p>

        <section style={{ display: 'inline-block' }}>
          <a
            href="/files/size_chart.pdf"
            target="_blank"
            className="blue button"
            style={{ marginBottom: 20 }}>
            PRINT SIZE CHART
          </a>
        </section>

        <p>
          (Requires Adobe Acrobat Reader. Download it{' '}
          <a href="http://get.adobe.com/reader/?promoid=BUIGO" target="_blank">
            here
          </a>
          .){' '}
        </p>
      </section>
    </div>
  );
}
