// @flow

import TextButton from '@components/popups/TextButton';
import * as CrownContent from './Crown';
import * as PavilionContent from './Pavilion';
import * as GiaContent from './Gia';
import * as CutContent from './Cut';
import * as BrillianceContent from './Brilliance';
import * as FireContent from './Fire';
import * as ScintillationContent from './Scintillation';
import * as CaratWeightContent from './CaratWeight';
import * as DiamondAnatomyContent from './DiamondAnatomy';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const girdleExThin = '/images/diamond-education/girdle_ex_thin.gif';
const girdleVThin = '/images/diamond-education/girdle_v_thin.jpg';
const girdleSThin = '/images/diamond-education/girdle_s_thin.jpg';
const girdleMedium = '/images/diamond-education/girdle_medium.jpg';
const girdleSThick = '/images/diamond-education/girdle_s_thick.jpg';
const girdleThick = '/images/diamond-education/girdle_thick.jpg';
const girdleVThick = '/images/diamond-education/girdle_v_thick.jpg';
const girdleExThick = '/images/diamond-education/girdle_ex_thick.jpg';
const girdleWidth = '/images/diamond-education/diamond_girdle_width.gif';

export const Header = 'Girdle';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <img
        alt="Diamond Girdle Width"
        src={girdleWidth}
        style={{ display: 'block', margin: '0 auto 20px auto' }}
      />
      <p>
        The girdle is the outer edge of the diamond, where the{' '}
        <TextButton
          onClick={(e) => setPopupContent(CrownContent)}
          text="crown"
        />{' '}
        (top) meets the{' '}
        <TextButton
          onClick={(e) => setPopupContent(PavilionContent)}
          text="pavilion"
        />{' '}
        (bottom).
      </p>
      <p>
        A girdle may be faceted (a series of tiny polished sides going around
        the diamond), bruted (a single continuous unpolished surface going round
        the diamond; no longer common), or polished (a bruted girdle that has
        been polished smooth). Whether a girdle is faceted, bruted, or polished
        usually has no impact on the appearance or value of the diamond.
      </p>
      <p style={{ clear: 'both' }}>
        The girdle is described according to its width. Often, the width of the
        girdle varies at different points around the diamond, and is quoted in a
        range designating the thinnest and thickest point along the girdle (e.g.
        "Thin - Medium" means the diamond's girdle varies in width from thin at
        the narrowest point to medium at the widest point). Each Lumera Diamond
        displays the girdle width as determined by the{' '}
        <TextButton onClick={(e) => setPopupContent(GiaContent)} text="GIA" />,
        using the following scale:{' '}
      </p>
      <div>
        <table style={{ width: '250px' }}>
          <tbody>
            <tr>
              <td style={{ borderRight: 0 }}>
                <h2>Extremely Thin</h2>
              </td>
              <td style={{ borderLeft: 0 }}>
                <img src={girdleExThin} />
              </td>
            </tr>
            <tr>
              <td style={{ borderRight: 0 }}>
                <h2>Very Thin</h2>
              </td>
              <td style={{ borderLeft: 0 }}>
                <img src={girdleVThin} />
              </td>
            </tr>
            <tr>
              <td style={{ borderRight: 0 }}>
                <h2>Thin</h2>
              </td>
              <td style={{ borderLeft: 0 }}>
                <img src={girdleSThin} />
              </td>
            </tr>
            <tr>
              <td style={{ borderRight: 0 }}>
                <h2>Slightly Thin</h2>
              </td>
              <td style={{ borderLeft: 0 }}>
                <img src={girdleSThin} />
              </td>
            </tr>
            <tr>
              <td style={{ borderRight: 0 }}>
                <h2>Medium</h2>
              </td>
              <td style={{ borderLeft: 0 }}>
                <img src={girdleMedium} />
              </td>
            </tr>
            <tr>
              <td style={{ borderRight: 0 }}>
                <h2>Slightly Thick</h2>
              </td>
              <td style={{ borderLeft: 0 }}>
                <img src={girdleSThick} />
              </td>
            </tr>
            <tr>
              <td style={{ borderRight: 0 }}>
                <h2>Thick</h2>
              </td>
              <td style={{ borderLeft: 0 }}>
                <img src={girdleThick} />
              </td>
            </tr>
            <tr>
              <td style={{ borderRight: 0 }}>
                <h2>Very Thick</h2>
              </td>
              <td style={{ borderLeft: 0 }}>
                <img src={girdleVThick} />
              </td>
            </tr>
            <tr>
              <td style={{ borderRight: 0 }}>
                <h2>Extremely Thick</h2>
              </td>
              <td style={{ borderLeft: 0 }}>
                <img src={girdleExThick} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p style={{ marginBottom: 0 }}>
        The girdle can impact a diamond in three important ways:
      </p>
      <div>
        <ul>
          <li style={{ textIndent: '30px' }}>
            The thickness of the girdle affects the relative position of the
            surrounding facets, and so can impact cut. Because girdle width is
            already factored into the diamond's overall{' '}
            <TextButton
              onClick={(e) => setPopupContent(CutContent)}
              text="Cut"
            />{' '}
            grade, a diamond graded as well cut will produce superior{' '}
            <TextButton
              onClick={(e) => setPopupContent(BrillianceContent)}
              text="brilliance"
            />
            ,{' '}
            <TextButton
              onClick={(e) => setPopupContent(FireContent)}
              text="fire"
            />
            , and{' '}
            <TextButton
              onClick={(e) => setPopupContent(ScintillationContent)}
              text="scintillation"
            />
            , even if the girdle itself is not in the ideal Thin-Slightly Thick
            range of width.
          </li>
          <li style={{ textIndent: '30px' }}>
            A thicker girdle will add weight to a diamond, thereby increasing
            its price. Since a thick girdle adds depth to a diamond but not
            width, a thick girdled diamond will not appear any larger when
            viewed from the top (e.g. when set in a piece of jewelry), despite
            the additional carat weight. The effect on{' '}
            <TextButton
              onClick={(e) => setPopupContent(CaratWeightContent)}
              text="carat weight"
            />{' '}
            and price is minimal, but real.
          </li>
          <li style={{ textIndent: '30px' }}>
            An Extremely Thin girdle is more susceptible to chipping, and for
            that reason should be avoided for diamonds that are to be set in a
            ring. Earrings or pendants are less exposed to rough contact and so
            are less susceptible to chipping around the girdle. Avoid both
            Extremely Thin and Very Thin girdles in princess cut diamonds, as
            this shape already has sharp corners more prone to chipping. If you
            do purchase a princess cut diamond with a Very Thin girdle, consider
            setting it in a style that covers and protects the corners.
          </li>
        </ul>
      </div>
      <p>
        Learn more about the{' '}
        <TextButton
          onClick={(e) => setPopupContent(DiamondAnatomyContent)}
          text="anatomy of a diamond"
        />
        .
      </p>
    </div>
  );
}
