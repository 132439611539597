// @flow

import Carousel from '../../carousels/popups/VintageExamples';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Vintage';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Refers to anything between Victorian and art deco. Common hallmarks of
        vintage design are handcrafted details, intricate styling, and ornate
        presentations.
      </p>

      <Carousel />
    </div>
  );
}
