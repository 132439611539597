// @flow

import TextButton from '@components/popups/TextButton';
import * as GirdleContent from './Girdle';
import * as CaratWeightContent from './CaratWeight';
import * as FacetContent from './Facet';
import * as CuletContent from './Culet';
import * as DiamondAnatomyContent from './DiamondAnatomy';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const pavilion = '/images/glossary/pavilion.gif';

export const Header = 'Pavilion';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <img src={pavilion} style={{ marginBottom: 10 }} />
      <p>
        The entire portion of the diamond that sits below the{' '}
        <TextButton
          onClick={(e) => setPopupContent(GirdleContent)}
          text="girdle"
        />
        . The pavilion usually constitutes the bulk of a diamond's{' '}
        <TextButton
          onClick={(e) => setPopupContent(CaratWeightContent)}
          text="carat weight"
        />{' '}
        and consists of the pavilion{' '}
        <TextButton
          onClick={(e) => setPopupContent(FacetContent)}
          text="facets"
        />{' '}
        and{' '}
        <TextButton
          onClick={(e) => setPopupContent(CuletContent)}
          text="culet"
        />
        . Pavilion height may be expressed in millimeters, or as a percentage of
        a diamond's diameter.
      </p>
      <p>
        Learn more about the{' '}
        <TextButton
          onClick={(e) => setPopupContent(DiamondAnatomyContent)}
          text="anatomy of a diamond"
        />
        .
      </p>
    </div>
  );
}
