// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const ringSizerIcon = '/images/buying-diamonds/ring_sizer.svg';

export const Header = 'How to determine ring size';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Pulling off a surprise proposal can be a challenge if you need to ask
        your intended what their ring size is beforehand. Following are some
        suggestions for determining the correct ring size. If you need to guess,
        go with a{' '}
        <a
          href="/engagement-rings/search?shape=round&sort=asc&style%5B%5D=Solitaire&design%5B%5D=Classic"
          title="Shop Classic Solitaire Engagement Rings">
          classic solitaire
        </a>
        , to ensure any resizing is a simple and inexpensive procedure that can
        be done quickly at any jewelry store.
      </p>
      <h2>A Physical Ring Sizer: Your Best Option</h2>
      <p>
        The most accurate way to measure ring size is with a physical sizer.
        Using a physical ring sizer will make your intentions obvious, but you
        can still keep the details of your proposal plan secret. Fortunately,
        ring sizers are available online for a few dollars, and can be delivered
        within a day or two. Two nice options are available{' '}
        <a
          href="https://www.amazon.com/MUDDER-Stainless-Ring-Gauges-Measuring/dp/B01CCG6IQY"
          target="_blank">
          here
        </a>{' '}
        and{' '}
        <a
          href="https://www.amazon.com/NIUPIKA-Finger-Measure-Jewelry-Sizing/dp/B01MQO6NDU"
          target="_blank">
          here
        </a>
        .
      </p>
      <div id="customerBox">
        <h2>How to determine ring size:</h2>
        <ul>
          <li>
            If you can get possession of a ring that already fits the
            recipient's third finger, do so. By comparing the ring to the
            printable ring sizer below, or a physical ring sizer, you can
            accurately determine the correct size.
          </li>
          <li>
            Check with a parent or close friend. Often women will have tried on
            each other's jewelry, or a parent will have purchased a ring in the
            past.
          </li>
          <li>
            Ask a friend who appears to have similar sized fingers what size
            they wear. This may not be exact, but it will allow you to purchase
            a ring that fits reasonably well and is easily resized after the
            proposal.
          </li>
          <li>
            If all else fails, simply choose the size that looks closest from
            the printable ring sizer below. The most common ring size for a
            woman is between 5 and 7. Most women can easily accommodate a ring
            within a half size of their true ring size.{' '}
          </li>
        </ul>
      </div>
      <p>
        Above all, keep in mind that not knowing the ring size does not have to
        upset your proposal plan. Choose a{' '}
        <a
          href="/engagement-rings/search?shape=round&sort=asc&style%5B%5D=Solitaire&design%5B%5D=Classic"
          title="Shop Classic Solitaire Engagement Rings">
          classic solitaire
        </a>
        , and go with your best estimate using the options above, and size up
        when in doubt. After the proposal, the ring can be correctly sized, if
        needed.
      </p>
      <div id="customerBox4" style={{ marginTop: 40 }}>
        <a href="/files/printable_ring_sizer.pdf" target="_blank">
          <img
            src={ringSizerIcon}
            width={122}
            height={162}
            style={{ display: 'block', margin: '0 auto 10px auto' }}
          />
        </a>
        <h2>PRINTABLE RING SIZER</h2>
        <p>
          Print a copy of our free ring sizer, follow the simple instructions
          and determine the correct ring size.
        </p>
        <div style={{ display: 'inline-block' }}>
          <a
            href="/files/printable_ring_sizer.pdf"
            target="_blank"
            className="blue button"
            style={{ marginBottom: '20px' }}>
            PRINT RING SIZER
          </a>
        </div>
        <p>
          (Requires Adobe Acrobat Reader. Download it{' '}
          <a href="http://get.adobe.com/reader/?promoid=BUIGO" target="_blank">
            here
          </a>{' '}
          for free.){' '}
        </p>
      </div>
    </div>
  );
}
