// @flow

import TextButton from '@components/popups/TextButton';
import * as HowToDetermineRingSizeContent from './HowToDetermineRingSize';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Resizing';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p className='style1'>
        Lumera will resize your ring at no charge, under the following
        conditions:
      </p>
      <ul className='style1'>
        <li>
          The ring and diamond were purchased from Lumera, and shipped within
          the continental U.S.
        </li>
        <li>
          You ship the ring back to Lumera for resizing within 30 days of your
          original purchase.
        </li>
      </ul>
      <p>
        Find out{' '}
        <TextButton
          onClick={e => setPopupContent(HowToDetermineRingSizeContent)}
          text='how to determine the correct ring size'
        />
        .{' '}
      </p>
    </div>
  );
}
