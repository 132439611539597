// @flow

import { ObjectType } from '@types/misc';

type TProps = {
  text: string,
  onClick: (e: React.SyntheticMouseEvent<HTMLElement>) => void,
  style?: ObjectType,
  className?: string
};

export default function TextButton({
  text,
  onClick,
  style = {},
  className = ''
}: TProps) {
  const handleKeyPress = (e: SyntheticKeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };
  return (
    <span
      className={`text-link link-gray text-underline ${className}`}
      style={style}
      onClick={onClick}
      onKeyPress={handleKeyPress}
      role='button'
      tabIndex={0}
    >
      {text}
    </span>
  );
}
