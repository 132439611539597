// @flow

import styles from '@styles/why_choose_lumera';

type PropsType = {
  setPopupContent: (N: React.Node) => void
};

export function Content({ setPopupContent }: PropsType) {
  return (
    <div>
      <h3 className={styles.h3}>Quality Guarantee</h3>
      <p>
        Your GIA diamond will appraise at or higher than what you paid or your
        money back, plus $100. (
        <a href='/customer-service/quality-guarantee' className={styles.link}>
          get the details
        </a>
        )
      </p>
    </div>
  );
}
