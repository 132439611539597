// @flow

import Carousel from '@components/carousels/diamond_search_popups/Polish';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const diamondPolishDefects =
  '/images/diamond-education/diamond-polish-defects.jpg';

export const Header = 'Polish';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <div className="multimedia-box">
        <img
          style={{ width: '100%' }}
          alt="Diamond Polish Defects"
          src={diamondPolishDefects}
        />
        <div
          className="caption"
          style={{
            marginTop: 0,
            padding: '9px 5px 12px 5px',
            color: '#9b9b9b'
          }}>
          Polish defects seen on
          <br />
          diamond surface
        </div>
      </div>

      <p>
        Polish refers to the degree of smoothness of each facet of a diamond.
        When a diamond is cut and polished, microscopic surface defects may be
        created by the polishing wheel. Depending on the severity, these defects
        may affect appearance.
      </p>

      <strong>Polish Grades:</strong>

      <Carousel style={{ marginTop: 10 }} />

      <p>
        Poor is the only polish grade that should be avoided regardless of the
        size or clarity of the diamond. For this reason, Lumera does not carry
        diamonds with a polish grade of Poor.
      </p>

      <p>
        A diamond should not be chosen or rejected based solely on polish.
        Because the overall Cut grade already incorporates polish, it should be
        used as the primary determinant when choosing a diamond. When comparing
        two diamonds of equal Cut grade, polish (and symmetry) can then be used
        as a further refinement.
      </p>

      <p>
        <a href="/diamond-education/diamond-polish" target="_blank">
          Learn more about diamond polish.
        </a>
      </p>
    </div>
  );
}
