// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Mohs Scale';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        The Mohs Scale designates the comparative hardness of various natural
        minerals. The scale runs from 1 (very soft) to 10 (very hard). A
        diamond, the hardest substance known to man, is the only mineral that is
        rated 10. This means that it is extremely resistant to scratches or
        abrasions. Rubies and sapphires are rated 9, and other semi precious
        gems are rated from 7-8.
      </p>
    </div>
  );
}
