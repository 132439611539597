// @flow

import TextButton from '@components/popups/TextButton';
import * as ScintillationContent from './Scintillation';
import * as BrillianceContent from './Brilliance';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const fire = '/images/glossary/fire.jpg';

export const Header = 'Fire';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <img
        src={fire}
        style={{ margin: '0 auto 10px auto', display: 'block' }}
      />
      <p>
        The light that reflects out of a diamond can appear in brilliant white
        flashes, or in a rainbow of color, referred to as fire. The fire effect
        is caused by the proper bending and dispersion of light as it travels
        through the diamond. The more colorless the diamond, the truer the
        dispersed colors will appear.
      </p>
      <p>
        Observable fire is increased in a slightly darker room when there are
        fewer light sources (such as a candle light). Conversely,{' '}
        <TextButton
          onClick={(e) => setPopupContent(ScintillationContent)}
          text="scintillation"
        />{' '}
        is maximized in strongly lit areas (such as an office). Fire and
        scintillation are simultaneously maximized by very strong, pinpointed
        light sources (such as the spot lights prevalent in jewelry showrooms).
      </p>
      <p>
        Maximum{' '}
        <TextButton
          onClick={(e) => setPopupContent(BrillianceContent)}
          text="brilliance"
        />{' '}
        is achieved in diamonds that posses both fire and{' '}
        <TextButton
          onClick={(e) => setPopupContent(ScintillationContent)}
          text="scintillation"
        />
        .
      </p>
    </div>
  );
}
