import * as ActualDiamondImage from './content/ActualDiamondImage.jsx';
import * as AllShapeIcons from './content/AllShapeIcons.jsx';
import * as Appraisal from './content/Appraisal.jsx';
import * as ApproximateSizeShape from './content/ApproximateSizeShape.jsx';
import * as AsscherCut from './content/AsscherCut.jsx';
import * as BankWire from './content/BankWire.jsx';
import * as BezelSet from './content/BezelSet.jsx';
import * as Blemish from './content/Blemish.jsx';
import * as BowTie from './content/BowTie.jsx';
import * as Brilliance from './content/Brilliance.jsx';
import * as BrilliantCut from './content/BrilliantCut.jsx';
import * as Bruise from './content/Bruise.jsx';
import * as CaratWeight from './content/CaratWeight.jsx';
import * as Cathedral from './content/Cathedral.jsx';
import * as Cavity from './content/Cavity.jsx';
import * as CenterDiamondIllustration from './content/CenterDiamondIllustration.jsx';
import * as CenterShape from './content/CenterShape.jsx';
import * as Certification from './content/Certification.jsx';
import * as Chip from './content/Chip.jsx';
import * as Clarity from './content/Clarity.jsx';
import * as Classic from './content/Classic.jsx';
import * as Cloud from './content/Cloud.jsx';
import * as Color from './content/Color.jsx';
import * as Contour from './content/Contour.jsx';
import * as Crown from './content/Crown.jsx';
import * as CrownAngle from './content/CrownAngle.jsx';
import * as Crystal from './content/Crystal.jsx';
import * as Culet from './content/Culet.jsx';
import * as CushionCut from './content/CushionCut.jsx';
import * as Cut from './content/Cut.jsx';
import * as CVV from './content/CVV.jsx';
import * as DelayedPayment from './content/DelayedPayment.jsx';
import * as Depth from './content/Depth.jsx';
import * as DiamondAccent from './content/DiamondAccent.jsx';
import * as DiamondAnatomy from './content/DiamondAnatomy.jsx';
import * as DiamondConsultant from './content/DiamondConsultant.jsx';
import * as DiamondDimensions from './content/DiamondDimensions.jsx';
import * as DiamondMeasurements from './content/DiamondMeasurements.jsx';
import * as DiamondPave from './content/DiamondPave.jsx';
import * as DiamondPlot from './content/DiamondPlot.jsx';
import * as DiamondTreatments from './content/DiamondTreatments.jsx';
import * as EmeraldCut from './content/EmeraldCut.jsx';
import * as Engraved from './content/Engraved.jsx';
import * as ExtraFacet from './content/ExtraFacet.jsx';
import * as Facet from './content/Facet.jsx';
import * as Feather from './content/Feather.jsx';
import * as FedExInternationalDelivery from './content/FedExInternationalDelivery.jsx';
import * as FedExDelivery from './content/FedExDelivery.jsx';
import * as Fire from './content/Fire.jsx';
import * as Fluorescence from './content/Fluorescence.jsx';
import * as Gia from './content/Gia.jsx';
import * as GiaCertificate from './content/GiaCertificate.jsx';
import * as GiaDossier from './content/GiaDossier.jsx';
import * as GiaGradingReport from './content/GiaGradingReport.jsx';
import * as Girdle from './content/Girdle.jsx';
import * as Halo from './content/Halo.jsx';
import * as HeartShape from './content/HeartShape.jsx';
import * as HowToDetermineRingSize from './content/HowToDetermineRingSize.jsx';
import * as Inclusion from './content/Inclusion.jsx';
import * as IndentedNatural from './content/IndentedNatural.jsx';
import * as Infinity from './content/Infinity.jsx';
import * as Insurance from './content/Insurance.jsx';
import * as LaserInscription from './content/LaserInscription.jsx';
import * as LengthToWidthRatio from './content/LengthToWidthRatio.jsx';
import * as LifetimeTradeInGuarantee from './content/LifetimeTradeInGuarantee.jsx';
import * as LooseDiamondPackaging from './content/LooseDiamondPackaging.jsx';
import * as LumeraAppraisal from './content/LumeraAppraisal.jsx';
import * as MarquiseCut from './content/MarquiseCut.jsx';
import * as MatchingBand from './content/MatchingBand.jsx';
import * as Modern from './content/Modern.jsx';
import * as MohsScale from './content/MohsScale.jsx';
import * as Natural from './content/Natural.jsx';
import * as Needle from './content/Needle.jsx';
import * as OvalCut from './content/OvalCut.jsx';
import * as Pavilion from './content/Pavilion.jsx';
import * as PavilionAngle from './content/PavilionAngle.jsx';
import * as Paypal from './content/Paypal.jsx';
import * as PearShape from './content/PearShape.jsx';
import * as Pinpoint from './content/Pinpoint.jsx';
import * as Polish from './content/Polish.jsx';
import * as PresentationSolitaire from './content/PresentationSolitaire.jsx';
import * as PricePerCarat from './content/PricePerCarat.jsx';
import * as PrincessCut from './content/PrincessCut.jsx';
import * as PrivacyPolicy from './content/PrivacyPolicy.jsx';
import * as Proportion from './content/Proportion.jsx';
import * as ProportionDiagram from './content/ProportionDiagram.jsx';
import * as QualityGuarantee from './content/QualityGuarantee.jsx';
import * as RadiantCut from './content/RadiantCut.jsx';
import * as ReferAFriend from './content/ReferAFriend.jsx';
import * as Resizing from './content/Resizing.jsx';
import * as ReturnPolicy from './content/ReturnPolicy.jsx';
import * as RingSizeSelection from './content/RingSizeSelection.jsx';
import * as Round from './content/Round.jsx';
import * as SafeShoppingGuarantee from './content/SafeShoppingGuarantee.jsx';
import * as SalesTax from './content/SalesTax.jsx';
import * as SampleImage from './content/SampleImage.jsx';
import * as SaveDiamonds from './content/SaveDiamonds.jsx';
import * as SaveForLater from './content/SaveForLater.jsx';
import * as Scintillation from './content/Scintillation.jsx';
import * as SecurePackagingShipping from './content/SecurePackagingShipping.jsx';
import * as ServiceHours from './content/ServiceHours.jsx';
import * as Shape from './content/Shape.jsx';
import * as SizeShapeOnRing from './content/SizeShapeOnRing.jsx';
import * as Solitaire from './content/Solitaire.jsx';
import * as Stackable from './content/Stackable.jsx';
import * as ShownSetWith from './content/ShownSetWith.jsx';
import * as StepCut from './content/StepCut.jsx';
import * as Symmetry from './content/Symmetry.jsx';
import * as Table from './content/Table.jsx';
import * as TheBestWay from './content/TheBestWay.jsx';
import * as ThreeStone from './content/ThreeStone.jsx';
import * as TwinningWisp from './content/TwinningWisp.jsx';
import * as Vintage from './content/Vintage.jsx';
import * as WhyChooseLumera__Quality from './content/WhyChooseLumera__Quality.jsx';
import * as WhyChooseLumera__Returns from './content/WhyChooseLumera__Returns.jsx';
import * as WhyChooseLumera__Security from './content/WhyChooseLumera__Security.jsx';
import * as WhyChooseLumera__Selection from './content/WhyChooseLumera__Selection.jsx';
import * as WhyChooseLumera__TradeIn from './content/WhyChooseLumera__TradeIn.jsx';
import * as WhyChooseLumera__Value from './content/WhyChooseLumera__Value.jsx';
import * as Yield from './content/Yield.jsx';

export default {
  ActualDiamondImage: ActualDiamondImage,
  AllShapeIcons: AllShapeIcons,
  Appraisal: Appraisal,
  ApproximateSizeShape: ApproximateSizeShape,
  AsscherCut: AsscherCut,
  BankWire: BankWire,
  BezelSet: BezelSet,
  Blemish: Blemish,
  BowTie: BowTie,
  Brilliance: Brilliance,
  BrilliantCut: BrilliantCut,
  Bruise: Bruise,
  CaratWeight: CaratWeight,
  Cathedral: Cathedral,
  Cavity: Cavity,
  CenterDiamondIllustration: CenterDiamondIllustration,
  CenterShape: CenterShape,
  Certification: Certification,
  Chip: Chip,
  Clarity: Clarity,
  Classic: Classic,
  Cloud: Cloud,
  Color: Color,
  Contour: Contour,
  Crown: Crown,
  CrownAngle: CrownAngle,
  Crystal: Crystal,
  Culet: Culet,
  CushionCut: CushionCut,
  Cut: Cut,
  CVV: CVV,
  DelayedPayment: DelayedPayment,
  Depth: Depth,
  DiamondAccent: DiamondAccent,
  DiamondAnatomy: DiamondAnatomy,
  DiamondConsultant: DiamondConsultant,
  DiamondDimensions: DiamondDimensions,
  DiamondMeasurements: DiamondMeasurements,
  DiamondPave: DiamondPave,
  DiamondPlot: DiamondPlot,
  DiamondTreatments: DiamondTreatments,
  EmeraldCut: EmeraldCut,
  Engraved: Engraved,
  ExtraFacet: ExtraFacet,
  Facet: Facet,
  Feather: Feather,
  FedExInternationalDelivery: FedExInternationalDelivery,
  FedExDelivery: FedExDelivery,
  Fire: Fire,
  Fluorescence: Fluorescence,
  Gia: Gia,
  GiaCertificate: GiaCertificate,
  GiaDossier: GiaDossier,
  GiaGradingReport: GiaGradingReport,
  Girdle: Girdle,
  Halo: Halo,
  HeartShape: HeartShape,
  HowToDetermineRingSize: HowToDetermineRingSize,
  Inclusion: Inclusion,
  IndentedNatural: IndentedNatural,
  Infinity: Infinity,
  Insurance: Insurance,
  LaserInscription: LaserInscription,
  LengthToWidthRatio: LengthToWidthRatio,
  LifetimeTradeInGuarantee: LifetimeTradeInGuarantee,
  LooseDiamondPackaging: LooseDiamondPackaging,
  LumeraAppraisal: LumeraAppraisal,
  MarquiseCut: MarquiseCut,
  MatchingBand: MatchingBand,
  Modern: Modern,
  MohsScale: MohsScale,
  Natural: Natural,
  Needle: Needle,
  OvalCut: OvalCut,
  Pavilion: Pavilion,
  PavilionAngle: PavilionAngle,
  Paypal: Paypal,
  PearShape: PearShape,
  Pinpoint: Pinpoint,
  Polish: Polish,
  PresentationSolitaire: PresentationSolitaire,
  PricePerCarat: PricePerCarat,
  PrincessCut: PrincessCut,
  PrivacyPolicy: PrivacyPolicy,
  Proportion: Proportion,
  ProportionDiagram: ProportionDiagram,
  QualityGuarantee: QualityGuarantee,
  RadiantCut: RadiantCut,
  ReferAFriend: ReferAFriend,
  Resizing: Resizing,
  ReturnPolicy: ReturnPolicy,
  RingSizeSelection: RingSizeSelection,
  Round: Round,
  SafeShoppingGuarantee: SafeShoppingGuarantee,
  SalesTax: SalesTax,
  SampleImage: SampleImage,
  SaveDiamonds: SaveDiamonds,
  SaveForLater: SaveForLater,
  Scintillation: Scintillation,
  SecurePackagingShipping: SecurePackagingShipping,
  ServiceHours: ServiceHours,
  Shape: Shape,
  ShownSetWith: ShownSetWith,
  Stackable: Stackable,
  SizeShapeOnRing: SizeShapeOnRing,
  Solitaire: Solitaire,
  StepCut: StepCut,
  Symmetry: Symmetry,
  Table: Table,
  TheBestWay: TheBestWay,
  ThreeStone: ThreeStone,
  TwinningWisp: TwinningWisp,
  Vintage: Vintage,
  WhyChooseLumera__Quality: WhyChooseLumera__Quality,
  WhyChooseLumera__Returns: WhyChooseLumera__Returns,
  WhyChooseLumera__Security: WhyChooseLumera__Security,
  WhyChooseLumera__Selection: WhyChooseLumera__Selection,
  WhyChooseLumera__TradeIn: WhyChooseLumera__TradeIn,
  WhyChooseLumera__Value: WhyChooseLumera__Value,
  Yield: Yield
};
