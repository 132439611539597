// @flow
import { useRef } from 'react';

import AxiosInstanceFactory from '@src/utils/axiosInstance';

export default function useAxios (options = {}) {
  const axiosRef = useRef(AxiosInstanceFactory(options));

  return axiosRef.current;
}
