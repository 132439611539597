// @flow

import Carousel from '@components/carousels/popups/DiamondMeasurements';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Key Diamond Measurements';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <Carousel style={{ marginTop: 10 }} setPopupContent={setPopupContent} />
    </div>
  );
}
