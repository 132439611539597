// @flow

import styles from '@styles/why_choose_lumera';

type PropsType = {
  setPopupContent: (N: React.Node) => void
};

export function Content({ setPopupContent }: PropsType) {
  return (
    <div>
      <h3 className={styles.h3}>Lifetime Trade-In</h3>
      <p>
        Receive the full price you paid for your original GIA diamond purchase.
        (
        <a href='/customer-service/trade-in' className={styles.link}>
          get the details
        </a>
        )
      </p>
    </div>
  );
}
