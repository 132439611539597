// @flow
import qs from 'qs';

import type { ObjectType } from '@types/misc';

export const apiExampleSettings = '/api/engagement-rings/example-data.json';

export function detailPath (
  setting: { vendor_sku: string, url_segment_description: string } = {},
  params: ObjectType = {}
) {
  const queryString: string = qs.stringify(params);
  const { vendor_sku: id, url_segment_description } = setting;

  if (!id) return '';

  let path;

  if (url_segment_description) {
    path = `/engagement-rings/detail/${url_segment_description}/${id}`;
  } else {
    path = `/engagement-rings/detail/${id}`;
  }

  if (queryString.length > 0) {
    return `${path}?${queryString}`;
  } else {
    return path;
  }
}

export function searchPath () {
  return '/engagement-rings/search';
}

export function apiSearch (params: Object = {}) {
  const queryString: string = qs.stringify(params, { arrayFormat: 'brackets' });
  const path = '/api/engagement-rings.json';

  if (queryString.length > 0) {
    return `${path}?${queryString}`;
  } else {
    return path;
  }
}

export function apiExtents (params: Object = {}) {
  const queryString: string = qs.stringify(params, { arrayFormat: 'brackets' });
  const path = '/api/engagement-rings/extents.json';

  if (queryString.length > 0) {
    return `${path}?${queryString}`;
  } else {
    return path;
  }
}

export function apiDyor (sku: string) {
  if (!sku) throw new Error('SKU is required');

  return `/api/engagement-rings/${sku}/dyor.json`;
}

export function apiDetail (sku: string) {
  if (!sku) throw new Error('SKU is required');

  return `/api/engagement-rings/${sku}/detail.json`;
}

export function apiExtendedDetail (sku: string) {
  if (!sku) throw new Error('SKU is required');

  return `/api/engagement-rings/${sku}/extended_detail.json`;
}

export function apiSettingSizeImages (sku: string) {
  if (!sku) throw new Error('SKU is required');

  return `/api/engagement-rings/${sku}/size_images.json`;
}

export function apiSettingUpdatedDeliveryDate (sku: string) {
  if (!sku) throw new Error('SKU is required');

  return `/api/engagement-rings/${sku}/updated_delivery_date.json`;
}

export function savePath (sku: string) {
  const path = `/api/engagement-rings/${sku}/save.json`;

  return path;
}

export const getSaved = '/api/engagement-rings/saved.json';
