// @flow

import TextButton from '@components/popups/TextButton';
import * as DepthContent from './Depth';
import * as CuletContent from './Culet';
import * as TableContent from './Table';
import * as LengthToWidthRatioContent from './LengthToWidthRatio';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const measurements = '/images/glossary/measurements.gif';

export const Header = 'Diamond Dimensions';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <img src={measurements} style={{ marginBottom: 10 }} />
      <p>
        The length, width, and depth (in that order) of a diamond, expressed in
        millimeters. For round and square diamonds, the length and width are
        identical, or nearly so.
      </p>
      <ul>
        <li>
          Length is the measure of the diamond from "top" to "bottom" as it
          would sit in a ring. In fancy shape diamonds, length is always longer
          than width, except in heart shapes, which may actually be wider than
          they are tall when mounted in a ring.
        </li>
        <li>Width is the measure that runs perpendicular to length.</li>
        <li>
          <TextButton
            onClick={(e) => setPopupContent(DepthContent)}
            text="Depth"
          />{' '}
          is the distance between the{' '}
          <TextButton
            onClick={(e) => setPopupContent(CuletContent)}
            text="culet"
          />{' '}
          and the{' '}
          <TextButton
            onClick={(e) => setPopupContent(TableContent)}
            text="table"
          />{' '}
          when the diamond is viewed from the side.
        </li>
      </ul>
      <p>
        The Length and Width are used to calculate the{' '}
        <TextButton
          onClick={(e) => setPopupContent(LengthToWidthRatioContent)}
          text="L/W ratio"
        />
        , an important indicator of a diamond's appearance.
      </p>
    </div>
  );
}
