// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'The Best Way';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        What could be less romantic than standing next to your fiancée in a
        jewelry store, negotiating with a salesperson about how much you are
        willing and able to spend on a{' '}
        <a href="/engagement-rings" title="Shop Diamond Engagement Rings">
          diamond engagement ring
        </a>
        ?{' '}
      </p>
      <p>
        There is an alternative; place your engagement diamond in a{' '}
        <a
          href="/engagement-rings/search?style%5B%5D=Solitaire&shape=round&sort=asc"
          title="Shop Solitaire Engagement Rings">
          classic solitaire setting
        </a>
        . After you propose, give the recipient the option of later choosing a{' '}
        <a
          href="/engagement-rings/search?shape=round&sort=desc"
          title="Shop All Engagement Rings">
          fancy setting
        </a>{' '}
        or{' '}
        <a
          href="/wedding-bands/search?sort=asc&gender%5B%5D=Women%27s"
          title="Shop Wedding Bands">
          matching band
        </a>{' '}
        that suits their personal taste. If needed, your diamond can later be
        reset in a ring of the recipient's choice. This approach has the
        following benefits:
      </p>
      <div id="customerBox4">
        <ul>
          <li>
            <b className="blue-text">
              You maintain the surprise of "popping the question"
            </b>
            , one of the most memorable events in a couple's life together.
            Often, the person proposing feels ill equipped to choose a ring for
            their partner. By placing the diamond in a{' '}
            <a
              href="/engagement-rings/search?style%5B%5D=Solitaire&shape=round&sort=asc"
              title="Solitaire Engagement Rings">
              solitaire setting
            </a>
            , the element of surprise is retained while the anxiety of choosing
            the right ring is lost.
          </li>
          <li>
            <b className="blue-text">You remain in control.</b> Buying a diamond
            is not a romantic process. It is a significant investment that
            requires careful research and budgeting. Standing with your fiancée
            in front of a salesperson is a poor environment for thoughtful
            decision making. In most cases, the center diamond is the majority
            of the cost of an engagement ring. By purchasing the diamond in a{' '}
            <a
              href="/engagement-rings/search?style%5B%5D=Solitaire&shape=round&sort=asc"
              title="Classic Solitaire Rings">
              simple solitaire
            </a>
            , you can privately decide what best fits your budget and needs for
            the center diamond.
          </li>
          <li>
            <b className="blue-text">
              Greater satisfaction for your partner.
            </b>{' '}
            Chances are, your significant other does not enjoy the pressure of
            having to decide how much you should spend on a diamond either.
            Instead, allow them to focus on choosing the setting that they love,
            whether{' '}
            <a
              href="/engagement-rings/search?shape=round&sort=asc&design%5B%5D=Modern&style%5B%5D=Halo&style%5B%5D=3-Stone&style%5B%5D=Diamond%20Accent&style%5B%5D=Pave"
              title="Modern Engagement Rings">
              contemporary
            </a>{' '}
            or{' '}
            <a
              href="/engagement-rings/search?shape=round&sort=asc&design%5B%5D=Vintage&style%5B%5D=Halo&style%5B%5D=3-Stone&style%5B%5D=Diamond%20Accent&style%5B%5D=Pave"
              title="Vintage Engagement Rings">
              vintage
            </a>
            ,{' '}
            <a
              href="/engagement-rings/search?shape=round&sort=desc&style%5B%5D=Halo&style%5B%5D=3-Stone&style%5B%5D=Diamond%20Accent&style%5B%5D=Pave&metal%5B%5D=14KW"
              title="White Gold Engagement Rings">
              white gold
            </a>{' '}
            or{' '}
            <a
              href="/engagement-rings/search?shape=round&sort=desc&style%5B%5D=Halo&style%5B%5D=3-Stone&style%5B%5D=Diamond%20Accent&style%5B%5D=Pave&metal%5B%5D=PLAT"
              title="Platinum Engagement Rings">
              platinum
            </a>
            ,{' '}
            <a
              href="/engagement-rings/search?shape=round&sort=desc&style%5B%5D=Halo"
              title="Halo Engagement Rings">
              halo
            </a>{' '}
            or{' '}
            <a
              href="/engagement-rings/search?shape=round&sort=desc&style%5B%5D=3-Stone"
              title="3-Stone Engagement Rings">
              three-stone
            </a>
            , etc., knowing that from a financial standpoint, you have already
            taken care of the most important part of the purchase. Often, the
            recipient will decide to keep the solitaire permanently, since the{' '}
            <a
              href="/engagement-rings/search?style%5B%5D=&style%5B%5D=Solitaire&shape=round&sort=asc"
              title="Shop Solitaire Engagement Rings">
              classic solitaire
            </a>{' '}
            showcases a diamond better than any other style and works with every
            wearer's personal style.
          </li>
          <li>
            <b className="blue-text">Better selection.</b> Even a very large
            jewelry store will have no more than a few hundred diamonds to
            choose from. Many will have fewer than twenty. Lumera has over
            50,000 diamonds to choose from, guaranteeing that you will find the
            exact shape, size, quality, and price that works best for you. The
            diamond you purchase in a solitaire can later be set in virtually
            any ring, creating a one of a kind style that you won't see on
            anyone else's finger.
          </li>
          <li>
            <b className="blue-text">The ability to customize your ring.</b> A
            custom jeweler would be happy to take a diamond you purchase from
            Lumera or elsewhere and design a ring to showcase it. Consider
            allowing your fiancée to design something original, instead of
            settling for what the jewelry store has in the case. The design
            process can be both romantic and creative, especially with the cost
            of the diamond removed from the process. If you originally proposed
            using a solitaire, the jeweler can even use the gold from that
            setting in the creation of a custom design.{' '}
          </li>
        </ul>
      </div>
      <p>
        At Lumera, we recommend the diamond solitaire approach because it
        typically leads to happier, more satisfied customers, who pay less for
        what they want. If you have questions or need help with your engagement
        diamond purchase, please call us at{' '}
        <span className="no-wrap"><a href="tel:+01-888-658-6372">1-888-658-6372</a></span>. Or, contact us at{' '}
        <a rel="nofollow" href="mailto:CustomerService@LumeraDiamonds.com">
          CustomerService@LumeraDiamonds.com
        </a>
        , and a diamond professional will respond to your query the same day.
      </p>
    </div>
  );
}
