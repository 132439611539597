// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Shown set with:';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        The description and price on this page are for the setting only, and exclude any center diamond. However, the image shows a center diamond for illustrative purposes.
      </p>

      <p>
        To change the center diamond shape illustration, choose from “Can also be set with”, below.
      </p>

      <p>
        To change the center diamond illustrated size, adjust the slider under the main image.
      </p>
      
      <p>
        Notice that when you change the center diamond illustration, the setting price often changes as well.  Changes to the center diamond may affect the weight of a setting's metal or accent diamonds.  These changes affect the setting's price.
      </p>
      
      <p>
        Once you choose a setting, you will proceed to the selection of an actual center diamond.
      </p>
    </div>
  );
}
