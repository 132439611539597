// @flow

import Carousel from '../../carousels/popups/HaloExamples';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Halo';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Increasingly popular, halo refers to the crown of diamonds or metalwork
        surrounding a center diamond. A halo can transform a smaller diamond
        into a dramatic statement. While round is the most common, any center
        diamond shape can be surrounded in halo.
      </p>

      <Carousel />
    </div>
  );
}
