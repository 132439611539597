// @flow

import * as DiamondAnatomyContent from './DiamondAnatomy';

import TextButton from '@components/popups/TextButton';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const crownAngle = '/images/glossary/crown_angle.gif';

export const Header = 'Crown Angle';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <img
        src={crownAngle}
        style={{ float: 'right', margin: '0 10px 10px 10px' }}
      />
      <p>
        Refers to the number of degrees between the plane of the table and the
        bezel facets. The crown angle will determine how much light is reflected
        into the pavilion at an angle that will allow it to be reflected back up
        through the crown (where the observer can perceive it).{' '}
      </p>
      <p>
        Learn more about the{' '}
        <TextButton
          onClick={(e) => setPopupContent(DiamondAnatomyContent)}
          text="anatomy of a diamond"
        />
        .
      </p>
    </div>
  );
}
