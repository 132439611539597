// @flow
import classify from '@src/utils/typestyle';
import { classes } from 'typestyle';
import BaseCarousel from './Base';
import baseStyles from './base_styles';
import deepmerge from 'deepmerge';

import type { Element as ReactElement } from 'react';

const styles = classify(
  deepmerge(baseStyles, {
    contentText: {
      'p:last-of-type': {
        marginBottom: 0
      }
    }
  })
);

type ItemType = {
  text: string,
  imageUrl: string,
  imageAlt: string,
  imageLocation: string,
  headerText: string,
  imageStyles?: {
    height?: number,
    width?: number
  },
  textStyles?: object
};

type PropsType = {
  items: Array<ItemType>,
  style?: { [string]: string | number },
  contentStyles?: { [string]: string | number }
};

class ImageAndTextCarousel extends BaseCarousel<PropsType> {
  imagePreloads = () => {
    return this.props.items
      .filter(item => item.imageUrl)
      .map((item, index) => (
        <img
          key={index}
          src={item.imageUrl}
          className={styles.preloadedImage}
        />
      ));
  };

  activeMainContent = () => {
    const activeItem = this.activeItem();
    const contentStyles = this.props.contentStyles || {};
    const textStyles = activeItem.textStyles || {};
    const imageStyles = activeItem.imageStyles || {};
    const ActiveContent = activeItem.component;

    return (
      <div
        className={styles.content}
        style={{
          minHeight: parseInt(contentStyles.minHeight) || 250,
          flexDirection: activeItem.imageLocation === 'left' ? 'row' : 'column'
        }}
      >
        <div
          style={{
            textAlign: activeItem.imageLocation === 'top' ? 'center' : 'left'
          }}
        >
          <img
            style={deepmerge(imageStyles, {
              maxWidth:
                activeItem.imageLocation === 'top'
                  ? `calc(100% - ${(contentStyles.margin
                      ? Number(contentStyles.margin)
                      : 20) * 2}px)`
                  : 'inherit'
            })}
            src={activeItem.imageUrl}
            alt={activeItem.imageAlt}
          />
        </div>
        <div
          className={styles.contentText}
          style={{
            margin:
              activeItem.imageLocation === 'left'
                ? 30
                : contentStyles.margin
                ? Number(contentStyles.margin)
                : 20,
            ...textStyles
          }}
          dangerouslySetInnerHTML={
            activeItem.text ? { __html: activeItem.text } : undefined
          }
        >
          {ActiveContent ? ActiveContent : null}
        </div>
      </div>
    );
  };

  navItems = () => {
    const activeItem = this.activeItem();

    return (
      <div className={styles.nav}>
        <div className={styles.navTop}>
          <button
            className={classes(
              styles.navButton,
              this.prevButtonIsDisabled() ? 'disabled' : ''
            )}
            onClick={this.handlePrevClick}
          />
          <p className={styles.navTitle}>{activeItem.headerText}</p>
          <button
            className={classes(
              styles.navButton,
              this.nextButtonIsDisabled() ? 'disabled' : ''
            )}
            onClick={this.handleNextClick}
          />
        </div>
        <div className={styles.navBottom}>
          {this.props.items.map((item, index) => {
            return (
              <button
                className={classes(
                  styles.navDot,
                  `${index === this.state.selectedItemIndex ? 'active' : ''}`
                )}
                style={{ height: 8, width: 8 }}
                onClick={() => this.handleDotClick(index)}
                key={index}
              />
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    const propStyles = this.props.style || {};

    return (
      <div style={propStyles} className={styles.carousel}>
        <div id='preloads'>{this.imagePreloads()}</div>
        {this.activeMainContent()}
        {this.navItems()}
      </div>
    );
  }
}

export default ImageAndTextCarousel;
