// @flow
import { Fragment } from 'react';
import Carousel from '../ImageAndText';

import TextButton from '@components/popups/TextButton';

import * as DiamondDimensionsContent from '../../popups/content/DiamondDimensions';
import * as LengthToWidthRatioContent from '../../popups/content/LengthToWidthRatio';
import * as GirdleContent from '../../popups/content/Girdle';
import * as CuletContent from '../../popups/content/Culet';
import * as DepthContent from '../../popups/content/Depth';
import * as TableContent from '../../popups/content/Table';
import * as CrownAngleContent from '../../popups/content/CrownAngle';
import * as PavilionAngleContent from '../../popups/content/PavilionAngle';

const crownAngle = '/images/measurements_carousel/crown_angle.gif';
const culetSize = '/images/measurements_carousel/culet_size.gif';
const depthPercent = '/images/measurements_carousel/depth_percent.gif';
const depth = '/images/measurements_carousel/depth.gif';
const girdleWidth = '/images/measurements_carousel/girdle_width.gif';
const length = '/images/measurements_carousel/length.gif';
const lwRatio = '/images/measurements_carousel/lw_ratio.gif';
const pavilionAngle = '/images/measurements_carousel/pavilion_angle.gif';
const tablePercent = '/images/measurements_carousel/table_percent.gif';
const width = '/images/measurements_carousel/width.gif';

const items = (setPopupContent) => {
  return [
    {
      headerText: 'Length',
      component: (
        <Fragment>
          Top to bottom measurement when viewed from above. (
          <TextButton
            onClick={(e) => setPopupContent(DiamondDimensionsContent)}
            text="learn more"
          />
          )
        </Fragment>
      ),
      imageUrl: length,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 20,
        maxHeight: 150
      }
    },
    {
      headerText: 'Width',
      component: (
        <Fragment>
          Perpendicular to Length, when viewed from above. (
          <TextButton
            onClick={(e) => setPopupContent(DiamondDimensionsContent)}
            text="learn more"
          />
          )
        </Fragment>
      ),
      imageUrl: width,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 20,
        maxHeight: 150
      }
    },
    {
      headerText: 'L/W Ratio',
      component: (
        <Fragment>
          Length measurement divided by width measurement. (
          <TextButton
            onClick={(e) => setPopupContent(LengthToWidthRatioContent)}
            text="learn more"
          />
          )
        </Fragment>
      ),
      imageUrl: lwRatio,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 20,
        maxHeight: 150
      }
    },
    {
      headerText: 'Girdle Width',
      component: (
        <Fragment>
          Thickness as measured from the narrow valleys. (
          <TextButton
            onClick={(e) => setPopupContent(GirdleContent)}
            text="learn more"
          />
          )
        </Fragment>
      ),
      imageUrl: girdleWidth,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 20,
        maxHeight: 150
      }
    },
    {
      headerText: 'Culet Size',
      component: (
        <Fragment>
          A point, or else a very small facet at bottom of pavilion. (
          <TextButton
            onClick={(e) => setPopupContent(CuletContent)}
            text="learn more"
          />
          )
        </Fragment>
      ),
      imageUrl: culetSize,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 20,
        maxHeight: 150
      }
    },
    {
      headerText: 'Depth',
      component: (
        <Fragment>
          Distance from table to culet, when measured from side. (
          <TextButton
            onClick={(e) => setPopupContent(DepthContent)}
            text="learn more"
          />
          )
        </Fragment>
      ),
      imageUrl: depth,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 20,
        maxHeight: 150
      }
    },
    {
      headerText: 'Depth %',
      component: (
        <Fragment>
          Depth measurement divided by width. (
          <TextButton
            onClick={(e) => setPopupContent(DepthContent)}
            text="learn more"
          />
          )
        </Fragment>
      ),
      imageUrl: depthPercent,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 20,
        maxHeight: 150
      }
    },
    {
      headerText: 'Table %',
      component: (
        <Fragment>
          Table measurement divided by total diamond width. (
          <TextButton
            onClick={(e) => setPopupContent(TableContent)}
            text="learn more"
          />
          )
        </Fragment>
      ),
      imageUrl: tablePercent,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 20,
        maxHeight: 150
      }
    },
    {
      headerText: 'Crown Angle',
      component: (
        <Fragment>
          Number of degrees from table plane to bezel facets. (
          <TextButton
            onClick={(e) => setPopupContent(CrownAngleContent)}
            text="learn more"
          />
          )
        </Fragment>
      ),
      imageUrl: crownAngle,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 20,
        maxHeight: 150
      }
    },
    {
      headerText: 'Pavilion Angle',
      component: (
        <Fragment>
          Degrees from table plane to pavilion facets. (
          <TextButton
            onClick={(e) => setPopupContent(PavilionAngleContent)}
            text="learn more"
          />
          )
        </Fragment>
      ),
      imageUrl: pavilionAngle,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 20,
        maxHeight: 150
      }
    }
  ];
};

const Anatomy = (props: Object) => {
  const { setPopupContent, ...otherProps } = props;

  return (
    <Carousel
      {...otherProps}
      items={items(setPopupContent)}
      contentStyles={{ minHeight: 260 }}
    />
  );
};

export default Anatomy;
