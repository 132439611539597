// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const image = '/images/popups/shape/princess.jpg';

export const Header = 'Princess Cut Diamond';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <img
        src={image}
        style={{ float: 'left', width: 55, marginTop: 18, marginRight: 13 }}
      />
      <span style={{ fontWeight: 'bold' }}>Princess</span>
      <p>
        First created in 1980, Princess Cut is the most popular fancy shape,
        especially for engagement rings. Like round cut diamonds, princess cut
        diamonds are a good choice for their flexibility in working in almost
        any style of ring.
      </p>

      <p>
        Princess cut diamonds tend to have a slightly lower price-per-carat than
        round cut diamonds because the four sided pyramid shape of the princess
        cut is similar to the octahedron rough stone from which a princess
        diamond is cut. This results in less cut waste and a higher yield.
      </p>

      <p>
        Of all of the square cuts, the brilliant-cut princess (along with the
        radiant cut) comes the closet to achieving the fire and brilliance of a
        round diamond, making it an ideal combination of unique shape, sparkling
        appearance, and relative price value.
      </p>

      <p>
        <a href="/diamond-education/princess-cut-diamond" target="_blank">
          Learn more about princess cut diamonds
        </a>, including their history, as well as how to evaluate cut
        parameters, color, and clarity.
      </p>
    </div>
  );
}
