// @flow

import TextButton from '@components/popups/TextButton';
import * as GiaGradingReportContent from './GiaGradingReport';
import * as GiaDossierContent from './GiaDossier';
import * as CertificationContent from './Certification';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const laserInscription = '/images/glossary/gia-laser-inscription.jpg';

export const Header = 'Laser Inscription';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <div className="multimedia-box">
        <img
          style={{ width: '100%' }}
          alt="GIA Laser Inscription"
          src={laserInscription}
        />
        <div
          className="caption"
          style={{
            marginTop: 0,
            padding: '9px 5px 12px 5px',
            color: '#9b9b9b'
          }}>
          Example of GIA laser inscription
        </div>
      </div>
      <p>
        Text which is etched into the girdle of a diamond, usually for purposes
        of identification. The text is invisible to the naked eye, but clearly
        visible under magnification.
      </p>
      <p>
        Often, a diamond will be laser inscribed with its{' '}
        <TextButton
          onClick={(e) => setPopupContent(GiaGradingReportContent)}
          text="GIA Grading Report"
        />{' '}
        or{' '}
        <TextButton
          onClick={(e) => setPopupContent(GiaDossierContent)}
          text="Dossier"
        />{' '}
        number. If any laser inscription is present on a diamond, the text of
        the inscription will be documented on the diamond's{' '}
        <TextButton
          onClick={(e) => setPopupContent(CertificationContent)}
          text="certificate"
        />
        .
      </p>
    </div>
  );
}
