// @flow

import TextButton from '@components/popups/TextButton';
import * as DiamondPlotContent from './DiamondPlot';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const feather = '/images/glossary/feather.gif';

export const Header = 'Feather';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p className="style1">
        A fracture that often has fine lines radiating from it, resembling a
        feather. Feathers usually appear white or transparent, depending on the
        viewing angle.
      </p>
      <img src={feather} />
      <p>
        <TextButton
          onClick={(e) => setPopupContent(DiamondPlotContent)}
          text="Learn more about inclusion and blemish types, and how they are represented"
        />
        .
      </p>
    </div>
  );
}
