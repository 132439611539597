// @flow

import TextButton from '@components/popups/TextButton';
import * as FedExInternationalDeliveryContent from './FedExInternationalDelivery';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Sales Tax';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Sales tax will be collected only for orders shipped to the state of
        Missouri, where Lumera Diamonds is located. For orders shipped to
        Missouri, the tax rate is 9.24%.
      </p>
      <p>
        <TextButton
          onClick={e => setPopupContent(FedExInternationalDeliveryContent)}
          text='International orders'
        />{' '}
        may be subject to additional taxes and duties.
      </p>
    </div>
  );
}
