// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'About "Actual" Diamond Images';

export function Content ({ setPopupContent: _setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Photo and video (images) of a diamond do not accurately convey cut,
        color, or clarity.  Used by many diamond retailers, images are proven to
        increase sales.  However, when customers rely on them, images also
        increase returns.
      </p>

      <p>
        Many of the images shown online are not of the actual diamond, but are
        recycled to save time and reduce cost.  All are enhanced, which makes
        them misleading at best.
      </p>

      <p>
        Even actual, unenhanced images cannot be accurately transmitted to your
        screen (esp. in regard to color and cut, difficult to distinguish even
        in person). This is why no diamond seller relies on images in making
        their own purchases.
      </p>

      <p>
        The information provided online (measurements, grading, certification)
        is useful in refining your search criteria, and identifying a group of
        diamonds you are interested in. But that information should never be
        relied upon to choose the final diamond.
      </p>

      <p>
        Instead, Lumera provides (and strongly encourages) a free gemological
        review of any diamond prior to purchase. It takes as little as an hour,
        and virtually eliminates the risk of an unpleasant surprise. Our return
        rate is the lowest in the industry due to this approach.
      </p>

      <p>
        To receive gemological review of a diamond, or to ask for help in the
        purchase or education process, email{' '}
        <a rel='nofollow' href='mailto:consultant@lumeradiamonds.com'>
          consultant@lumeradiamonds.com
        </a>. Feel
        free to include any evaluation criteria you are using, or to share your
        saved diamonds. This will allow the gemologist to provide meaningful
        feedback and assist you in refining your approach.
      </p>

      <p>
        We want you to be happy with your purchase. Lumera is unique in that we
        do no advertising at all; your positive word of mouth is our only
        promotion.
      </p>
    </div>
  );
}
