// @flow

import * as DiamondPlotContent from './DiamondPlot';

import TextButton from '../TextButton';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const cavity = '/images/glossary/cavity.gif';

export const Header = 'Cavity';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        An opening or depression in the surface of the diamond, usually the
        result of a natural flaw in the rough stone.{' '}
      </p>
      <img src={cavity} />
      <p>
        <TextButton
          onClick={(e) => setPopupContent(DiamondPlotContent)}
          text="Learn more about inclusion and blemish types, and how they are represented"
        />
        .
      </p>
    </div>
  );
}
