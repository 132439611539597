// @flow

import TextButton from '@components/popups/TextButton';
import * as FeatherContent from './Feather';
import * as DiamondPlotContent from './DiamondPlot';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const needle = '/images/glossary/needle.gif';

export const Header = 'Needle';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p className="style1">
        A very fine, elongated inclusion. Similar in appearance to a{' '}
        <TextButton
          onClick={(e) => setPopupContent(FeatherContent)}
          text="feather"
        />
        , without the radiating lines.
      </p>
      <img src={needle} />
      <p>
        <TextButton
          onClick={(e) => setPopupContent(DiamondPlotContent)}
          text="Learn more about inclusion and blemish types, and how they are represented"
        />
        .
      </p>
    </div>
  );
}
