// @flow
import * as csx from 'csstips';
import classify from '@src/utils/typestyle';
import { classes } from 'typestyle';
import BaseCarousel from './Base';
import baseStyles from './base_styles';
import deepmerge from 'deepmerge';

type ItemType = {
  headerText: string,
  imageUrl: string,
  imageLocation?: string,
  imageAlt: string,
  imageStyles?: {
    marginLeft?: number,
    marginTop?: number,
    height?: number,
    width?: number
  }
};

const styles = classify(deepmerge(baseStyles, {
  content: csx.centerJustified
}));

type PropsType = {
  items: Array<ItemType>,
  style?: {[string]: string | number},
  contentStyles?: {[string]: string | number},
  contentClassName?: string,
};

class ImageOnlyCarousel extends BaseCarousel<PropsType> {
  imagePreloads = () => {
    return this.props.items
      .filter((item) => item.imageUrl)
      .map((item, index) => (
        <img
          key={index}
          src={item.imageUrl}
          className={styles.preloadedImage}
        />
      ));
  }

  activeMainContent = () => {
    const activeItem = this.activeItem();
    const contentStyles = this.props.contentStyles || {};
    const imageStyles = activeItem.imageStyles || {};

    return (
      <div
        className={styles.content}
        style={{ minHeight: parseInt(contentStyles.minHeight) || 'auto' }}
      >
        <img
          style={deepmerge(imageStyles, {
            maxWidth: 'calc(100% - 40px)'
          })}
          src={activeItem.imageUrl}
          alt={activeItem.imageAlt}
        />
      </div>
    );
  }

  navItems = () => {
    const activeItem = this.activeItem();

    return (
      <div className={styles.nav}>
        <div className={styles.navTop}>
          <button
            className={classes(styles.navButton, (this.prevButtonIsDisabled() ? 'disabled' : ''))}
            onClick={this.handlePrevClick} />
          <p className={styles.navTitle}>{activeItem.headerText}</p>
          <button
            className={classes(styles.navButton, (this.nextButtonIsDisabled() ? 'disabled' : ''))}
            onClick={this.handleNextClick} />
        </div>
        <div className={styles.navBottom}>
          {this.props.items.map((item, index) => {
            return (
              <button
                className={classes(styles.navDot, `${index === this.state.selectedItemIndex ? 'active' : ''}`)}
                style={{ height: 8, width: 8 }}
                onClick={() => this.handleDotClick(index)}
                key={index}
              />
            );
          })}
        </div>
      </div>
    );
  }

  render () {
    const propStyles = this.props.style || {};

    return (
      <div style={propStyles} className={styles.carousel}>
        <div id='preloads'>
          {this.imagePreloads()}
        </div>
        {this.activeMainContent()}
        {this.navItems()}
      </div>
    );
  }
}

export default ImageOnlyCarousel;
