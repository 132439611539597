// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const image = '/images/popups/shape/oval.jpg';

export const Header = 'Oval Diamonds';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <img
        src={image}
        style={{ float: 'left', width: 55, marginTop: 18, marginRight: 13 }}
      />
      <span style={{ fontWeight: 'bold' }}>Oval</span>
      <p>
        First popularized in the 1960's, oval diamonds are a modified
        brilliant-cut (like virtually all round cuts). Because the two shapes
        possess a similar fire and brilliance, the oval is an ideal choice for a
        customer who likes the look of a round diamond, but wants something more
        unique.
      </p>

      <p>
        Oval diamonds have the added advantage of an elongated shape, which can
        create the illusion of greater size.
      </p>

      <p>
        Oval cut diamonds posses some degree of bow-tie, varying from near
        invisible to severe. The visibility of a bow-tie effect cannot be
        ascertained by reviewing the diamond certificate or dimensions, but only
        upon visual inspection.
      </p>

      <p>
        <a href="/diamond-education/oval-diamond" target="_blank">
          Learn more about oval diamonds
        </a>
        , including their history as well as how to evaluate cut parameters,
        color, and clarity.
      </p>
    </div>
  );
}
