// @flow
import * as csstips from 'csstips';
import throttle from 'lodash/throttle';
import { style } from 'typestyle';
import type { ObjectType } from '@types/misc';

import WatchClickOutside from '@components/WatchClickOutside';

import calculatePosition, {
  pageScrollTop,
  scrollIncrement,
  pageHeight,
  viewportHeight,
  MAX_POPUP_HEIGHT,
  POPUP_BOTTOM_MARGIN
} from '../../utils/popupLocationCalculator';

type ContainerPropsType = {
  show: boolean | void,
  children: React.Node,
  onCloseClick: () => void,
  onHeaderClick?: () => void,
  onMouseOver?: () => void,
  onMouseOut?: () => void,
  onClick?: e => void,
  onClose?: () => void,
  onShow?: () => void,
  anchorElement?: HTMLElement | null,
  title?: string | React.Node,
  minWidth?: number | string,
  maxWidth?: number | string,
  hideCloseButton?: boolean,
  doNotScrollToPopup?: boolean,
  preferSideAttachment?: boolean,
  popupLocationOptions?: ObjectType
};

type PopupPropsType = {
  children: React.Node,
  onCloseClick: () => void,
  onHeaderClick?: () => void,
  onMouseOver?: () => void,
  onMouseOut?: () => void,
  onClick?: e => void,
  onClose?: () => void,
  onShow?: () => void,
  anchorElement: HTMLElement,
  title?: string | React.Node,
  minWidth?: number | string,
  maxWidth?: number | string,
  hideCloseButton?: boolean,
  doNotScrollToPopup?: boolean,
  preferSideAttachment?: boolean,
  popupLocationOptions?: ObjectType
};

type StateType = {
  positions: ObjectType
};

const closeIcon = '/images/close_popup_x.svg';

const classes = {
  container: (pos, minWidth?: number | string, maxWidth?: number | string) => {
    return style({
      cursor: 'default', // if nested in an element with a cursor set, we want to reset the cursor within the popup
      width: '100%',
      backgroundColor: 'white',
      zIndex: 5,
      border: '1px solid #e6e5e5',
      borderLeft: '1px solid #e1a913',
      borderRadius: '5px',
      top: pos.top,
      left: pos.popupLeft,
      whiteSpace: 'normal', // If used within an element that has altered white space, don't inherit the white space changes
      boxShadow: '0 6px 35px rgba(135, 135, 135, 0.24)',
      minWidth: minWidth || 'none',
      maxWidth: maxWidth || minWidth || 'none',
      maxHeight: Math.max(pos.maxHeight, 60),
      textAlign: 'left',
      fontSize: 14,
      lineHeight: '22px',
      $nest: {
        '&::after': {
          position: 'absolute',
          display: 'block',
          left: pos.anchorLeft,
          top: pos.anchorTop,
          content: `"▲"`,
          color: 'transparent',
          fontSize: '8px',
          lineHeight: '22px',
          '-webkit-transform': `scale${pos.anchorRotation === 0
            ? 'X'
            : 'Y'}(2) rotate(${pos.anchorRotation}deg)`,
          '-ms-transform': `scale${pos.anchorRotation === 0
            ? 'X'
            : 'Y'}(2) rotate(${pos.anchorRotation}deg)`,
          transform: `scale${pos.anchorRotation === 0
            ? 'X'
            : 'Y'}(2) rotate(${pos.anchorRotation}deg)`,
          textShadow:
            '0 0 0 white, 1px 1.5px 0 white, -1px 1.5px 0 white, 1.5px 2.5px 0 white, -1.5px 2.5px 0 white, -1px 0 0 #e6e5e5, 0 -2px 0 #e6e5e5, 1px 0 0 #E6E5E6'
        }
      }
    });
  },
  title: (clickable: boolean) => {
    return style({
      color: '#21528d !important',
      fontSize: '14px !important',
      lineHeight: '25px !important',
      margin: '5px 14px !important',
      marginLeft: '14px !important',
      textTransform: 'none !important',
      cursor: clickable ? 'pointer !important' : 'inherit !important'
    });
  },
  titleUnderline: style({
    borderBottom: '1px solid #EFEFEF',
    width: '100%'
  }),
  closeIcon: style({
    position: 'absolute',
    top: 6,
    right: 7,
    cursor: 'pointer',
    padding: 8
  }),
  content: (pos, titleIsHidden) =>
    style(csstips.scroll, csstips.flex, csstips.vertical, {
      padding: '7px 14px 14px 14px',
      maxHeight:
        typeof pos.maxHeight === 'number'
          ? Math.max(
              titleIsHidden
                ? pos.maxHeight - 12 // 12 for the top caret
                : pos.maxHeight - 38, // 25px for the title height and 1px for the underline plus 12px for the top caret
              0 // never let the content have a negative max height or it will overflow visibly
            )
          : 'unset',
      color: '#7f7f7f',
      $nest: {
        '& table': {
          border: '1px solid #f6f6f6',
          borderCollapse: 'separate',
          borderSpacing: 0,
          borderRadius: '3px',
          overflow: 'hidden',
          $nest: {
            '& tr:first-of-type th:first-of-type': {
              // override a global style
              padding: '5px 5px 5px 8px'
            },
            '& tbody, & thead': {
              $nest: {
                '& tr th, & tr td': {
                  fontSize: '13px',
                  lineHeight: 1.23
                }
              }
            },
            '& th:not(:first-of-type), & td:not(:first-of-type)': {
              textAlign: 'center'
            },
            '& th': {
              padding: '5px'
            },
            '& tbody tr td:first-of-type, & tbody tr th:first-of-type': {
              padding: '5px 5px 5px 8px'
            },
            '& tbody tr td': {
              padding: '5px'
            },
            '& tbody tr + tr': {
              $nest: {
                '& th, & td': {
                  borderTop: '1px solid #f6f6f6'
                }
              }
            },
            '& tr:last-child td:first-child': {
              'border-bottom-left-radius': '10px'
            }
          }
        },
        '& ul': {
          color: '#7f7f7f'
        },
        '& div + p': {
          marginTop: 20
        },
        '& p': {
          color: '#7f7f7f',

          fontSize: '14px !important',
          lineHeight: '22px !important'
        },
        '& p:last-child': {
          marginBottom: 0
        },
        '& .multimedia-box': {
          margin: '5px 0 20px 0',
          lineHeight: 0
        },
        '& a': {
          color: '#7f7f7f',
          textDecoration: 'underline',

          fontSize: '14px !important',
          lineHeight: '22px !important'
        },
        '& > *': csstips.content
      }
    })
};

const titleUnderline = () => {
  return <div className={classes.titleUnderline} />;
};

class Popup extends React.Component<PopupPropsType, StateType> {
  popupRef: { current: HTMLDivElement | null | void };
  scrollContainerRef: { current: HTMLDivElement | null | void };
  scrollToInterval: Interval;

  static defaultProps = {
    minWidth: '300px',
    maxWidth: '300px'
  };

  state = {
    positions: calculatePosition({
      anchorEl: this.props.anchorElement,
      dimensions: {
        minWidth: this.props.minWidth,
        maxWidth: this.props.maxWidth
      },
      preferSideAttachment: this.props.preferSideAttachment,
      scrollsToPopup: !this.props.doNotScrollToPopup,
      ...(this.props.popupLocationOptions || {})
    })
  };

  updatePositionsUnthrottled = () => {
    this.setState({
      positions: calculatePosition({
        anchorEl: this.props.anchorElement,
        dimensions: {
          minWidth: this.props.minWidth,
          maxWidth: this.props.maxWidth
        },
        preferSideAttachment: this.props.preferSideAttachment,
        scrollsToPopup: !this.props.doNotScrollToPopup,
        ...(this.props.popupLocationOptions || {})
      })
    });
  };

  updatePositions = throttle(this.updatePositionsUnthrottled, 500);

  constructor (props, opts) {
    super(props, opts);

    this.popupRef = React.createRef();
    this.scrollContainerRef = React.createRef();
    this.updatePositions = this.updatePositions.bind(this);
  }

  componentWillMount () {
    if (this.props.onShow) {
      this.props.onShow();
    }
  }

  componentDidMount () {
    if (
      typeof window !== 'undefined' &&
      this.scrollContainerRef.current !== null
    ) {
      setTimeout(() => {
        if (!this.props.doNotScrollToPopup) {
          this.scrollToInterval = setInterval(() => {
            const currentPageTop = pageScrollTop();
            const currentPageHeight = pageHeight();
            const currentViewportHeight = viewportHeight();

            const scrollDistanceBasedOnPage =
              this.state.positions.scrollTo - currentPageTop;

            let scrollDistanceBasedOnPopupScrollHeight =
              this.scrollContainerRef.current.scrollHeight -
              this.scrollContainerRef.current.offsetHeight;

            const viewportBottomY = currentPageTop + currentViewportHeight;

            const popupDistanceFromTopOfPage =
              this.popupRef.current.getBoundingClientRect().top +
              currentPageTop;

            const popupHeight =
              viewportBottomY -
              POPUP_BOTTOM_MARGIN -
              popupDistanceFromTopOfPage;

            let scrollDistance = scrollIncrement(
              Math.min(
                scrollDistanceBasedOnPage,
                scrollDistanceBasedOnPopupScrollHeight,
                currentPageHeight - currentPageTop - currentViewportHeight,
                Math.max(MAX_POPUP_HEIGHT - popupHeight, 0)
              )
            );

            const contentDistanceFromTopOfViewport = this.scrollContainerRef.current.getBoundingClientRect()
              .top;

            if (contentDistanceFromTopOfViewport > currentViewportHeight) {
              // move in the largest increment we currently allow
              scrollDistance = scrollIncrement(1000);
            }

            const canScroll =
              currentPageHeight - currentPageTop > currentViewportHeight;

            const shouldScroll = scrollDistanceBasedOnPopupScrollHeight > 0;

            if (scrollDistance > 0 && shouldScroll && canScroll) {
              window.scrollTo(0, currentPageTop + scrollDistance);
              this.updatePositionsUnthrottled();
            } else {
              clearInterval(this.scrollToInterval);
            }
          }, 10);
        }

        window.addEventListener('resize', this.updatePositions);
        window.addEventListener('scroll', this.updatePositions);

        // After scrolling to the anchor, we need to adjust the height of the
        // popup.
        this.updatePositions();
      }, 30);
    }
  }

  componentWillUnmount () {
    clearInterval(this.scrollToInterval);

    if (this.props.onClose) {
      this.props.onClose();
    }

    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.updatePositions);
      window.removeEventListener('scroll', this.updatePositions);
    }
  }

  componentDidUpdate (prevProps: PopupPropsType) {
    if (!this.scrollContainerRef.current) return;

    if (this.props.children !== prevProps.children) {
      this.scrollContainerRef.current.scrollTop = 0;
      this.scrollContainerRef.current.scrollLeft = 0;
    }
  }

  handleHeaderKeyPress = (e: SyntheticKeyboardEvent<HTMLElement>) => {
    if (this.props.onHeaderClick && e.key === 'Enter') {
      this.props.onHeaderClick();
    }
  };

  handleCloseKeyPress = (e: SyntheticKeyboardEvent<HTMLElement>) => {
    if (this.props.onCloseClick && e.key === 'Enter') {
      this.props.onCloseClick();
    }
  };

  render () {
    return (
      <WatchClickOutside
        enabled
        onClickOutside={this.props.onCloseClick}
        elRef={this.popupRef}
        style={{ display: 'inline' }}>
        <div
          ref={(node) => {
            this.popupRef.current = node;
          }}
          className={classes.container(
            this.state.positions,
            this.props.minWidth,
            this.props.maxWidth
          )}
          style={{ position: 'absolute' }}
          onMouseOver={this.props.onMouseOver}
          onMouseOut={this.props.onMouseOut}
          onClick={this.props.onClick}>
          {this.props.title && (
            <h3
              className={classes.title(!!this.props.onHeaderClick)}
              onClick={this.props.onHeaderClick || (() => {})}
              onKeyPress={this.handleHeaderKeyPress}
              tabIndex={this.props.onHeaderClick ? 0 : -1}>
              {this.props.title}
            </h3>
          )}
          {this.props.title && titleUnderline()}
          {!this.props.hideCloseButton && (
            <img
              src={closeIcon}
              alt="Close popup"
              className={classes.closeIcon}
              onClick={this.props.onCloseClick}
              onKeyPress={this.handleCloseKeyPress}
              role="button"
              tabIndex={0}
              title="Close popup"
            />
          )}
          <div
            className={classes.content(
              this.state.positions,
              this.props.hideCloseButton
            )}
            ref={this.scrollContainerRef}>
            {this.props.children}
          </div>
        </div>
      </WatchClickOutside>
    );
  }
}

export default (props: ContainerPropsType) => {
  const { anchorElement, show, ...otherProps } = props;

  if (show && anchorElement) {
    return <Popup anchorElement={anchorElement} {...otherProps} />;
  } else {
    return null;
  }
};
