// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const image = '/images/popups/shape/cushion.jpg';

export const Header = 'Cushion Cut Diamonds';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <img
        src={image}
        style={{ float: 'left', width: 55, marginTop: 18, marginRight: 13 }}
      />
      <span style={{ fontWeight: 'bold' }}>Cushion</span>
      <p>
        The cushion cut diamond (once referred to as old mine cut) combines a
        square cut with rounded corners, much like a pillow (hence the name).
        This classic cut has been around for almost 200 years, and for the first
        century of its existence was the most popular diamond shape.
      </p>

      <p>
        Refinements to cushion cut diamonds over time (such as shrinking the
        culet, enlarging the table, and improving cut angles for increased
        brilliance), have led to a resurgence in popularity. Many buyers are
        attracted to the antique feel combined with modern performance offered
        by the cushion cut.
      </p>

      <p>
        <a href="/diamond-education/cushion-cut-diamond" target="_blank">
          Learn more about cushion cut diamonds
        </a>
        , including their history, as well as how to evaluate cut parameters,
        color, and clarity.
      </p>
    </div>
  );
}
