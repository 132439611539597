// @flow

import Carousel from '../../carousels/popups/ClassicExamples';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Classic';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Designs that have proven timeless, while still looking fresh and new. A
        classic design complements any style, from shabby chic to modern
        minimalist.
      </p>

      <Carousel />
    </div>
  );
}
