// @flow

import Carousel from '../../carousels/popups/DiamondPaveExamples';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Diamond Pave';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Pronounced “pa-vey”, meaning "paved". Pave features smaller accent
        diamonds set close enough that the overall effect is of a continuous
        surface of diamonds. Especially popular in white gold and platinum,
        where the diamond and metal surfaces blend seamlessly.
      </p>

      <Carousel />
    </div>
  );
}
