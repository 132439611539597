import { style } from 'typestyle';

const styles = {
  h3: style({
    fontSize: 14
  }),
  link: style({
    color: '#21528d !important',
    textDecoration: 'none !important',
    '&:hover': {
      textDecoration: 'underline !important'
    }
  })
};

export default styles;
