// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Matching Band';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        This ring is available with a matching band. If you would like to add
        the band, wait until you add your ring to the shopping cart.  You will then 
        be given the choice to “add the matching band”, in the correct size and metal, to your cart.
      </p>

      <p>
        Click “Matching Band” above, for additional images and details.
      </p>
    </div>
  );
}
