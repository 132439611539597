// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Center Shape';

export function Content({ setPopupContent }: TProps) {
  return (
    <span>
      <p>
        All of the settings shown below will work with the highlighted center
        diamond.
      </p>

      <p>
        If you <strong>have not</strong> yet chosen your center diamond, feel
        free to choose any shape to see how it looks in the settings below.
      </p>

      <p>
        If you <strong>have</strong> already chosen your center diamond, your
        shape is highlighted here. Every setting shown below is compatible with
        your diamond. If you wish to choose a different shape, simply delete
        your chosen diamond at the top of this page.
      </p>
    </span>
  );
}
