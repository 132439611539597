// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'About PayPal';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p style={{ margin: 0 }}>
        PayPal is a third party payment provider that allows you to:
      </p>
      <ul>
        <li>
          Pay for your purchase using multiple funding sources (such as checking
          account, credit card, and your PayPal balance).
        </li>
        <li>
          Make payments without sharing your financial information (such as
          credit card number) with anyone (such as Lumera) other than PayPal
          itself.
        </li>
        <li>
          Shop securely, since PayPal monitors your account to protect you from
          fraudulent activity.
        </li>
      </ul>
      <h2 style={{ marginTop: 40 }}>Using PayPal</h2>
      <p>
        To use PayPal for your Lumera Diamond purchase, simply click the yellow
        PayPal button in the shopping bag when you are ready to checkout. You
        will be directed to your PayPal account log-in (or be given the option
        to create an account if you are new to PayPal).{' '}
      </p>
      <p>
        Once logged in, you will select your payment options and consent to
        having Lumera charge your PayPal account for your purchase. You will
        then be directed back to Lumera Diamonds to complete your order.
      </p>
      <p style={{ marginBottom: '15px' }}>
        You will receive an order confirmation number and email just as you
        would if paying by credit card.
      </p>
    </div>
  );
}
