// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const image = '/images/popups/shape/radiant.jpg';

export const Header = 'Radiant Cut Diamonds';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <img
        src={image}
        style={{ float: 'left', width: 55, marginTop: 18, marginRight: 13 }}
      />
      <span style={{ fontWeight: 'bold' }}>Radiant</span>
      <p>
        The radiant cut diamond is the first square cut (the second being the
        princess) to have a complete brilliant-cut facet pattern applied to both
        the crown and pavilion, creating a vibrant and lively square diamond.
      </p>

      <p>
        First popular in the 1980's, the cropped corner square shape of the
        radiant is a nice bridge between a cushion and a princess cut, and for
        that reason looks beautiful set with both rounded or square cornered
        diamonds.
      </p>

      <p>
        There are diverse preferences when it comes to shape in radiant cut
        diamonds, from a perfectly square 1.00 length to width ratio, to a more
        traditional rectangle. The rectangular radiant cut is an excellent
        option for buyers who like the emerald cut shape, but want something
        with the brilliance of a round. The square radiant looks very similar to
        a princess cut, but with cropped corners.
      </p>

      <p>
        <a href="/diamond-education/radiant-cut-diamond" target="_blank">
          Learn more about radiant cut diamonds
        </a>, including their history, as well as how to evaluate cut
        parameters, color, and clarity.
      </p>
    </div>
  );
}
