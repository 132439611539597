// @flow

import * as DiamondAnatomyContent from '../content/DiamondAnatomy';

import TextButton from '@components/popups/TextButton';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const tableWidth = '/images/diamond-education/table_width.jpg';

export const Header = 'Table';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p className="no-bottom-margin">
        The table is the uppermost, flat surface of a diamond, which runs
        parallel to the plane of the girdle.
      </p>

      <img
        src={tableWidth}
        alt="Diamond table and width"
        style={{ width: 140, margin: '18px auto 15px auto', display: 'block' }}
      />

      <p>
        The table % of a diamond is calculated by dividing the width of the
        table facet by the width of the diamond. So, if the table facet is 3 mm
        across, and the diamond is 4.5 mm wide, its table % is 66.7.
      </p>

      <p>
        Table % is incorporated in the Cut grade of a diamond. For this reason,
        when purchasing a diamond, use the Cut grade first (since it balances
        multiple measurements, not just table %). Once two diamonds of equal cut
        are being compared, consider table % as a further refinement.
      </p>
      <p>
        Learn more about the{' '}
        <TextButton
          onClick={(e) => setPopupContent(DiamondAnatomyContent)}
          text="anatomy of a diamond"
        />
        .
      </p>
    </div>
  );
}
