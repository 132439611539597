// @flow

import * as DiamondPlotContent from './DiamondPlot';

import TextButton from '../TextButton';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const bruise = '/images/glossary/bruise.gif';

export const Header = 'Bruise';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        A chip on the diamond surface that may include root-like feathers
        radiating into the diamond.
      </p>
      <img src={bruise} />
      <p>
        <TextButton
          onClick={(e) => setPopupContent(DiamondPlotContent)}
          text="Learn more about inclusion and blemish types, and how they are represented"
        />
        .
      </p>
    </div>
  );
}
