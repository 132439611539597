// @flow

import TextButton from '@components/popups/TextButton';
import * as ServiceHoursContent from './ServiceHours';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Bank Wire';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        All prices are displayed in U.S. dollars.
      </p>
      <p>
        A bank wire is an immediate transfer of funds, initiated by you, from
        your bank account to Lumera's. A bank wire eliminates the transaction
        charges imposed on the merchant by the credit card issuer or paypal.
        This savings is passed directly to you in the form of a 2.5% price
        discount.
      </p>

      <p>
        Please Note: Some financial institutions charge a small fee (usually
        under $20) for using a bank wire. Paying by bank wire also requires that
        you have sufficient funds on deposit with your bank to cover your
        purchase. An ACH transfer is not the same as a wire transfer. ACH
        transfers are not accepted at this time.
      </p>

      <p>
        If you would like to pay by bank wire, simply send an email to{' '}
        <a rel='nofollow' href='mailto:CustomerService@LumeraDiamonds.com'>
          CustomerService@LumeraDiamonds.com
        </a>
        . Please include a link to the page or pages containing the items you would
        like to purchase, or include each item number. Within 4 business hours, you will receive instructions
        for completing the bank wire transaction. In most cases, a bank wire
        does not delay the delivery date for an item.
      </p>

      <p>
        If you have any questions about the steps involved in a bank wire,
        contact your bank or call customer service at{' '}
        <span className='no-wrap'>1-888-658-6372</span>. You can also chat
        online; a representative will usually respond immediately during{' '}
        <TextButton
          onClick={e => setPopupContent(ServiceHoursContent)}
          text='business hours'
        />
        .
      </p>
    </div>
  );
}
