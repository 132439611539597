// @flow
import DropdownList from 'react-widgets/lib/DropdownList';

import css from '@styles/productDetail/ringSizeSelector';

const countries = [
  'Anguilla',
  'Antigua and Barbuda',
  'Australia',
  'Austria',
  'Bahamas',
  'Bahrain',
  'Barbados',
  'Belgium',
  'Bermuda',
  'Bonaire',
  'British Virgin Islands',
  'Brunei',
  'Canada',
  'Cayman Islands',
  'China',
  'Curacao',
  'Denmark',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'Germany',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Guam',
  'Hong Kong',
  'Iceland',
  'Ireland',
  'Jamaica',
  'Japan',
  'Macau',
  'Malaysia',
  'Marshall Islands',
  'Montserrat',
  'Netherlands',
  'New Zealand',
  'Norway',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Seychelles',
  'Singapore',
  'Saint Maarten',
  'South Korea',
  'Spain',
  'St. Barthelemy',
  'St. Kitts and Nevis',
  'Sweden',
  'Switzerland',
  'Taiwan',
  'Trinidad and Tobago',
  'Turks and Caicos Islands',
  'U.S. Virgin Islands',
  'United Kingdom',
  'United States'
];

const SelectIcon = <span aria-hidden='true' />;

function ListItem({ item }: { item: string }) {
  return <span>{item}</span>;
}

function ValueInput() {
  return <span>Where Lumera ships:</span>;
}

function ForeignShippingCountriesList() {
  return (
    <DropdownList
      className={css.container}
      style={{ width: 300, maxWidth: '100%', marginBottom: 20 }}
      data={countries}
      itemComponent={ListItem}
      valueComponent={ValueInput}
      selectIcon={SelectIcon}
      onChange={() => {}}
      defaultValue={'Where Lumera ships:'}
    />
  );
}

export default () => <ForeignShippingCountriesList />;
