// @flow

import Carousel from '@components/carousels/diamond_search_popups/Symmetry';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const diamondSymmetry = '/images/diamond-education/diamond_symmetry.gif';

export const Header = 'Symmetry';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Symmetry refers to how precisely the various facets of a diamond align
        and intersect.
      </p>

      <img
        src={diamondSymmetry}
        alt="Diamond symmetry"
        style={{ width: 161, margin: '6px auto 30px auto', display: 'block' }}
      />

      <p>
        A diamond with poor symmetry may misdirect light, reducing the diamond's
        brilliance.
      </p>

      <strong>Symmetry Grades:</strong>

      <Carousel style={{ marginTop: 10 }} />

      <p>
        Despite its modest impact on appearance, symmetry has a significant
        impact on price; a diamond with Excellent Symmetry and Polish may be
        priced 10%-15% higher than a diamond with Good Symmetry and Polish.
      </p>

      <p>
        Because diamonds with Poor symmetry have defects visible to the naked
        eye, these diamonds should be avoided in all cases.
      </p>

      <p>
        A diamond should not be chosen or rejected based solely on symmetry.
        Because the overall Cut grade already incorporates symmetry, it should
        be used as the primary determinant when choosing a diamond. When
        comparing two diamonds of equal Cut grade, symmetry (and polish) can
        then be used as a further refinement.
      </p>

      <p>
        <a href="/diamond-education/diamond-symmetry" target="_blank">
          Learn more about diamond symmetry.
        </a>
      </p>
    </div>
  );
}
