// @flow
import Carousel from '../TextOnly';
import { style } from 'typestyle';
import * as csx from 'csstips';

const contentStyles = style(
  {
    ...csx.horizontal,
    margin: '12px 15px'
  }
);

const items = [
  {
    headerText: 'Excellent Cut',
    text: `
      <article>
        <p class="no-bottom-margin">
          Maximum fire and brilliance. Reflects nearly all of the light that
          enters the diamond, creating exceptional sparkle and life.
        </p>
      </article>
    `
  }, {
    headerText: 'Very Good Cut',
    text: `
      <article>
        <p class="no-bottom-margin">
          Properly reflects most of the light that enters the diamond, producing
          superior fire and brilliance. Under normal lighting conditions,
          appears very similar to Excellent Cut, but for a lower price.
        </p>
      </article>
    `
  }, {
    headerText: 'Good Cut',
    text: `
      <article>
        <p class="no-bottom-margin">
          Reflects a majority of the light that enters the diamond, for an above
          average appearance. An excellent value compared to higher cut grades.
        </p>
      </article>
    `
  }, {
    headerText: 'Fair Cut',
    text: `
      <article>
        <p class="no-bottom-margin">
          Allows much of the light entering the diamond to escape from the sides
          or bottom, reducing  fire and brilliance. More acceptable in diamonds
          of less than .75 ct., where differences in sparkle are more difficult
          to perceive.
        </p>
      </article>
    `
  }, {
    headerText: 'Poor Cut',
    text: `
      <article>
        <p class="no-bottom-margin">
          Allows most of the light entering the diamond to escape from the sides
          or bottom. The diamond may appear noticeably dull and lifeless, even
          to an untrained eye. Lumera does not offer Poor cut diamonds.
        </p>
      </article>
    `
  }
];

export default (props: Object) => {
  return (
    <Carousel
      {...props}
      items={items}
      textColor='#7f7f7f'
      textAlign='left'
      contentClassName={contentStyles}
      contentStyles={{
        minHeight: 178
      }}
    />
  );
};
