// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const image = '/images/home-page/wedding-rings-stacked.png';

export const Header = 'Stackable';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Refers to bands that are designed to allow “stacking” on the finger,
        creating a dazzling fashion statement.
      </p>

      <div className="multimedia-box">
        <img
          style={{ marginTop: 20, marginLeft: 15 }}
          alt="Stack of Wedding Bands"
          src={image}
        />
        <div
          className="caption"
          style={{ marginTop: 0, padding: 5, color: '#9b9b9b' }}>
          Stackable Bands
        </div>
      </div>
    </div>
  );
}
