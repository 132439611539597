// @flow
import { Fragment } from 'react';
import Carousel from '../ImageAndText';

import TextButton from '@components/popups/TextButton';

import * as CrystalContent from '../../popups/content/Crystal';
import * as PinpointContent from '../../popups/content/Pinpoint';
import * as CloudContent from '../../popups/content/Cloud';
import * as CavityContent from '../../popups/content/Cavity';
import * as ChipContent from '../../popups/content/Chip';
import * as BruiseContent from '../../popups/content/Bruise';
import * as FeatherContent from '../../popups/content/Feather';
import * as NeedleContent from '../../popups/content/Needle';
import * as TwinningWispContent from '../../popups/content/TwinningWisp';
import * as NaturalContent from '../../popups/content/Natural';

const bruise = '/images/clarity_carousel/bruise.gif';
const cavity = '/images/clarity_carousel/cavity.gif';
const chip = '/images/clarity_carousel/chip.gif';
const cloud = '/images/clarity_carousel/cloud.gif';
const crystal = '/images/clarity_carousel/crystal.gif';
const feather = '/images/clarity_carousel/feather.gif';
const natural = '/images/clarity_carousel/natural.gif';
const needle = '/images/clarity_carousel/needle.gif';
const pinpoint = '/images/clarity_carousel/pinpoint.gif';
const twinningWisp = '/images/clarity_carousel/twinning_wisp.gif';

const items = (setPopupContent) => {
  return [
    {
      headerText: 'Crystal',
      component: (
        <Fragment>
          A single (or grouping of) mineral deposits, often black. (
          <TextButton
            onClick={(e) => setPopupContent(CrystalContent)}
            text="learn more"
          />
          )
        </Fragment>
      ),
      imageUrl: crystal,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 10
      },
      textStyles: {
        margin: '15px 20px 20px 20px'
      }
    },
    {
      headerText: 'Pinpoint',
      component: (
        <Fragment>
          A very small crystal, often whitish in color. (
          <TextButton
            onClick={(e) => setPopupContent(PinpointContent)}
            text="learn more"
          />
          )
        </Fragment>
      ),
      imageUrl: pinpoint,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 10
      },
      textStyles: {
        margin: '15px 20px 20px 20px'
      }
    },
    {
      headerText: 'Cloud',
      component: (
        <Fragment>
          A concentrated grouping of pinpoint inclusions. (
          <TextButton
            onClick={(e) => setPopupContent(CloudContent)}
            text="learn more"
          />
          )
        </Fragment>
      ),
      imageUrl: cloud,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 10
      },
      textStyles: {
        margin: '15px 20px 20px 20px'
      }
    },
    {
      headerText: 'Cavity',
      component: (
        <Fragment>
          A depression in the otherwise smooth surface. (
          <TextButton
            onClick={(e) => setPopupContent(CavityContent)}
            text="learn more"
          />
          )
        </Fragment>
      ),
      imageUrl: cavity,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 10
      },
      textStyles: {
        margin: '15px 20px 20px 20px'
      }
    },
    {
      headerText: 'Chip',
      component: (
        <Fragment>
          A shallow surface nick, often created during cutting. (
          <TextButton
            onClick={(e) => setPopupContent(ChipContent)}
            text="more"
          />
          )
        </Fragment>
      ),
      imageUrl: chip,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 10
      },
      textStyles: {
        margin: '15px 20px 20px 20px'
      }
    },
    {
      headerText: 'Bruise',
      component: (
        <Fragment>
          A surface chip, often with root-like feathers radiating out. (
          <TextButton
            onClick={(e) => setPopupContent(BruiseContent)}
            text="more"
          />
          )
        </Fragment>
      ),
      imageUrl: bruise,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 10
      },
      textStyles: {
        margin: '15px 20px 20px 20px'
      }
    },
    {
      headerText: 'Feather',
      component: (
        <Fragment>
          A fracture, often whitish, with fine radiating extensions. (
          <TextButton
            onClick={(e) => setPopupContent(FeatherContent)}
            text="learn more"
          />
          )
        </Fragment>
      ),
      imageUrl: feather,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 10
      },
      textStyles: {
        margin: '15px 20px 20px 20px'
      }
    },
    {
      headerText: 'Needle',
      component: (
        <Fragment>
          Very fine inclusion; like a feather minus the radiating lines. (
          <TextButton
            onClick={(e) => setPopupContent(NeedleContent)}
            text="more"
          />
          )
        </Fragment>
      ),
      imageUrl: needle,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 10
      },
      textStyles: {
        margin: '15px 20px 20px 20px'
      }
    },
    {
      headerText: 'Twinning Wisp',
      component: (
        <Fragment>
          A ribbon of pinpoints formed during the crystal's growth. (
          <TextButton
            onClick={(e) => setPopupContent(TwinningWispContent)}
            text="more"
          />
          )
        </Fragment>
      ),
      imageUrl: twinningWisp,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 10
      },
      textStyles: {
        margin: '15px 20px 20px 20px'
      }
    },
    {
      headerText: 'Natural',
      component: (
        <Fragment>
          Tiny remnants of original rough surface, often on the girdle. (
          <TextButton
            onClick={(e) => setPopupContent(NaturalContent)}
            text="more"
          />
          )
        </Fragment>
      ),
      imageUrl: natural,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 10
      },
      textStyles: {
        margin: '15px 20px 20px 20px'
      }
    }
  ];
};

const DiamondClarity = (props: Object) => {
  const { setPopupContent, ...otherProps } = props;

  return (
    <Carousel
      {...otherProps}
      items={items(setPopupContent)}
      contentStyles={{ minHeight: 260 }}
    />
  );
};

export default DiamondClarity;
