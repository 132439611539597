// @flow

import Carousel from '../../carousels/popups/EngravedExamples';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Engraved';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Intricate carvings in the metal surface that produce flashes of light
        and sparkle, enhancing any center diamond.
      </p>

      <Carousel />
    </div>
  );
}
