// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const image = '/images/popups/shape/marquise.jpg';

export const Header = 'Marquise Diamond';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <img
        src={image}
        style={{ float: 'left', width: 55, marginTop: 18, marginRight: 13 }}
      />
      <span style={{ fontWeight: 'bold' }}>Marquise</span>
      <p>
        The Marquise name is derived from the Marquise of Pompadour, for whom
        King Louis XIV of France allegedly had a stone fashioned to resemble
        what he considered her perfectly shaped mouth. Because marquise diamonds
        are long and narrow, they can also create the illusion of greater size.
      </p>

      <p>
        Marquise diamonds possess some degree of bow-tie, varying from near
        invisible to severe. The visibility of a bow-tie effect cannot be
        ascertained by reviewing the diamond certificate or dimensions, but only
        upon visual inspection.
      </p>

      <p>
        Symmetry is very important in marquise cuts. The two end points should
        align with each other, and the right and left sides should form a near
        mirror image. For this reason, excellent or very good symmetry is
        strongly recommended.
      </p>

      <p>
        <a href="/diamond-education/marquise-diamond" target="_blank">
          Learn more about marquise diamonds
        </a>
        , including their history, as well as how to evaluate cut parameters,{' '}
        color, and clarity.
      </p>
    </div>
  );
}
