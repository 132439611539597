// @flow

import TextButton from '@components/popups/TextButton';
import * as LifetimeTradeInGuaranteeContent from './LifetimeTradeInGuarantee';
import * as ReturnPolicyContent from './ReturnPolicy';
import * as LumeraAppraisalContent from './LumeraAppraisal';
import * as FedExDeliveryContent from './FedExDelivery';
import * as SecurePackagingShippingContent from './SecurePackagingShipping';
import * as SafeShoppingGuaranteeContent from './SafeShoppingGuarantee';
import * as ReferAFriendContent from './ReferAFriend';
import * as GiaCertificateContent from './GiaCertificate';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Quality Guarantee';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Every GIA diamond purchased from Lumera is examined prior to shipment to
        ensure it matches the description provided online and in the diamond's
        accompanying certificate. Lumera guarantees that the diamond you receive
        will be exactly as represented in the GIA report. If it is not, Lumera
        will refund your entire purchase price, <b>plus $100</b>.
      </p>
      <p>
        Every GIA diamond shown on Lumera is offered at a price lower than the
        typical retail price for a diamond of similar quality. Lumera guarantees
        that your GIA diamond will appraise for a retail value equal to or
        greater than the purchase price when appraised by a qualified,
        independent appraiser. If it does not, Lumera will refund your entire
        purchase price, <b>plus $100</b>.
      </p>

      <p>
        You will receive this guarantee in writing with your GIA diamond
        purchase.
      </p>
      <div>
        <h2 style={{ marginBottom: 15 }}>
          Complete details regarding the Lumera Diamond Quality Guarantee:
        </h2>
        <ol>
          <li>
            Lumera examines every diamond prior to shipment. In addition, each
            diamond is uniquely identified to prevent fraud in the case of
            returns or claims arising from this guarantee. Any case in which
            fraud is suspected will be pursued with the appropriate
            law-enforcement agencies.
          </li>
          <li>
            For an appraisal to qualify, it must be performed by an appraiser
            who meets the following criteria:
          </li>
          <ul>
            <li>
              Independent - not employed by or affiliated with any jewelry
              retailer.
            </li>
            <li>Graduate Gemologist - certified by the GIA.</li>
            <li>
              Member of a reputable appraisal association - ASA, ISA, or NGJA.
            </li>
            <li>
              Conforms to USPSP - Uniform Standard of Professional Appraisal
              Practice.
            </li>
          </ul>
          <li>
            Lumera reserves the right to have two additional appraisals
            completed (by independent, graduate gemologist appraisers chosen by
            the purchaser) to verify the initial appraisal. If either appraiser
            assigns a retail value less than the original purchase price, the
            claim will be accepted as valid.
          </li>
          <li>All claims must be made within 60 days of purchase.</li>
        </ol>
      </div>
      <p>
        By offering our exclusive Quality Guarantee, as well as our{' '}
        <TextButton
          onClick={e => setPopupContent(LifetimeTradeInGuaranteeContent)}
          text='Lifetime Trade-In Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(ReturnPolicyContent)}
          text='30-Day Return Policy'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(LumeraAppraisalContent)}
          text='Free Appraisal'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(FedExDeliveryContent)}
          text='Insured Express Delivery'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(SecurePackagingShippingContent)}
          text='Secure Packaging & Shipping'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(SafeShoppingGuaranteeContent)}
          text='Safe Shopping Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(ReferAFriendContent)}
          text='Refer a Friend program'
        />
        , and{' '}
        <TextButton
          onClick={e => setPopupContent(GiaCertificateContent)}
          text='GIA Certificate'
        />
        , Lumera hopes to instill complete confidence in your purchase.
      </p>
    </div>
  );
}
