// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Center Diamond Illustration';

export function Content({ setPopupContent }: TProps) {
  return (
    <span>
      <p style={{ textAlign: 'left' }}>
        The description and price on this page are for the setting only, and exclude any center diamond. However, the image shows a center diamond for illustrative purposes.
      </p>

      <p style={{ textAlign: 'left' }}>
        To change the center diamond illustrated size, adjust the slider below.
      </p>

      <p style={{ textAlign: 'left' }}>
        To change the center diamond illustrated shape, choose from the “Can also be set with” list.
      </p>

      <p style={{ textAlign: 'left' }}>
        Notice that when you change the center diamond illustration, the setting price often changes as well. Changes to the center diamond may affect the weight of a setting's metal or accent diamonds. These changes affect the setting's price.
      </p>
      
      <p style={{ textAlign: 'left' }}>
        Once you choose a setting, you will proceed to the selection of an actual center diamond.
      </p>
    </span>
  );
}
