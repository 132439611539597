// @flow

import TextButton from '@components/popups/TextButton';
import * as ReturnPolicyContent from './ReturnPolicy';
import * as LumeraAppraisalContent from './LumeraAppraisal';
import * as QualityGuaranteeContent from './QualityGuarantee';
import * as FedExDeliveryContent from './FedExDelivery';
import * as SecurePackagingShippingContent from './SecurePackagingShipping';
import * as SafeShoppingGuaranteeContent from './SafeShoppingGuarantee';
import * as ReferAFriendContent from './ReferAFriend';
import * as GiaCertificateContent from './GiaCertificate';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'The Lifetime Trade-In Guarantee';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        A diamond is forever. But the diamond you purchase today may not forever
        suit your needs. Couples may desire to trade up their engagement
        diamond over time, as their resources grow. During the initial purchase,
        couples may feel pressured to spend more than they are comfortable with,
        concerned that if they stick within their current budget they will be
        unhappy with their diamond in a few years time.
      </p>

      <p>
        The Lumera Lifetime Trade-In Guarantee is designed to address these
        needs.
      </p>

      <p>
        For any GIA diamond purchased, you will receive the full purchase price
        as credit toward the purchase of another GIA diamond of greater value.
        This guarantee is unique in the diamond industry, and reflects our
        complete commitment to your lifetime satisfaction with your diamond
        purchase.
      </p>

      <p>
        With this exclusive guarantee, you can trade up your GIA diamond in the
        years to come, with no penalty or discount. This eliminates any pressure
        to overspend today.
      </p>

      <div>
        <h2 style={{ marginBottom: 15 }}>
          Complete details regarding the Lumera Lifetime Trade-In Guarantee:
        </h2>
        <ol>
          <li>
            Every Lumera GIA diamond purchase comes with the Lifetime Trade-In
            Guarantee.
          </li>
          <li>
            You will receive the full purchase price for the GIA diamond being
            traded in, as a credit toward the new GIA diamond.
          </li>
          <li>
            The purchase price of the new diamond must be at least doublle the purchase price of the original diamond.
          </li>
          <li>
            The trade-in diamond must not be damaged or altered since the
            original purchase.
          </li>
          <li>
            Only one trade-in every five years is permitted. The first trade-in
            can be made five years after the original purchase.
          </li>
          <li>
            You must have the original diamond's undamaged GIA certificate to complete a trade-in.{' '}
          </li>
          <li>
            Only the original purchaser may complete a trade-in. This guarantee
            may not be sold or transferred to any other person or group.
          </li>
        </ol>
      </div>

      <p>
        For questions regarding the Lumera Lifetime Trade-In Guarantee, please
        contact us at{' '}
        <a rel='nofollow' href='mailto:CustomerService@LumeraDiamonds.com'>
          CustomerService@LumeraDiamonds.com
        </a>{' '}
        or call <span className='no-wrap'><a href="tel:+01-888-658-6372">1-888-658-6372</a></span>.
      </p>

      <p>
        By offering our exclusive Lifetime Trade-In Guarantee, as well as our{' '}
        <TextButton
          onClick={e => setPopupContent(ReturnPolicyContent)}
          text='30-Day Return Policy'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(LumeraAppraisalContent)}
          text='Free Appraisal'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(QualityGuaranteeContent)}
          text='Quality Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(FedExDeliveryContent)}
          text='Insured Express Delivery'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(SecurePackagingShippingContent)}
          text='Secure Packaging & Shipping'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(SafeShoppingGuaranteeContent)}
          text='Safe Shopping Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(ReferAFriendContent)}
          text='Refer a Friend program'
        />
        , and{' '}
        <TextButton
          onClick={e => setPopupContent(GiaCertificateContent)}
          text='GIA Certificate'
        />
        , Lumera hopes to instill complete confidence in your purchase.
      </p>
    </div>
  );
}
