// @flow

import * as GiaGradingReportContent from './GiaGradingReport';
import * as GiaDossierContent from './GiaDossier';
import * as GiaContent from './Gia';
import * as DiamondConsultantContent from './DiamondConsultant';

import TextButton from '../TextButton';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Certification';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        The process by which a diamond is sent to a third party laboratory for a
        comprehensive evaluation. Each certificate issued is uniquely numbered,
        and corresponds to one individual diamond. From that point forward, the
        diamond and certificate (laminated to prevent tampering or damage) will
        travel together from seller to buyer.
      </p>
      <p>
        Lab certification provides an impartial judgment of the characteristics
        and quality of each diamond. This certification (called a{' '}
        <TextButton
          onClick={e => setPopupContent(GiaGradingReportContent)}
          text='Grading Report'
        />
        , or{' '}
        <TextButton
          onClick={e => setPopupContent(GiaDossierContent)}
          text='Dossier'
        />{' '}
        by <TextButton onClick={e => setPopupContent(GiaContent)} text='GIA' />)
        gives the purchaser added confidence that the diamond received is as
        described by the seller. The certificate is also valuable for insurance
        purposes, as it provides a professional, independent evaluation of the
        diamond.
      </p>
      <p>
        Lumera sells diamonds which have been certified by the Gemological
        Institute of America (GIA). GIA is the world's oldest, largest, and one
        of the most respected independent laboratories. Unlike some other labs,
        GIA is not owned or partially owned by diamond wholesalers or retailers,
        and is a non-profit organization. GIA actually developed the "four Cs"
        rating system (used almost universally today) to provide truly objective
        standards in the evaluation of a diamond.
      </p>
      <p>
        Every certified Lumera Diamond is accompanied by its GIA Grading Report
        or Dossier (stud earrings, and diamond accents within jewelry, are not
        certified).
      </p>
      <div>
        <div id='caution'>
          <h3>A note of caution:</h3>
          <p>
            All Labs are not created equal, and their judgments are not held to
            any objective standard. Unfortunately, some labs have succumbed to
            pressure from retailers to lower their standards, thereby allowing
            lower quality diamonds to be presented with higher ratings,
            increasing their value in the marketplace. Before relying on any
            certificate, make sure you are comfortable with the reputation and
            expertise of its author.{' '}
          </p>
          <p>
            While Lumera chooses to offer GIA certified diamonds, other labs
            which have a strong reputation in the marketplace include AGS
            (American Gem Society) and HRD (more common in Europe than the U.S).
            Diamonds with certification from labs such as IGI and EGL tend to
            sell at a small discount, since these labs are known for{' '}
            <a href='/diamond-education/GIA-vs-EGL-comparison' target='_blank'>
              relaxed diamond grading standards
            </a>{' '}
            (for example, a diamond graded G in color by EGL may only be graded
            H by GIA).
          </p>
          <p style={{ marginBottom: 0 }}>
            Use caution when comparing the price of diamonds graded by two
            different labs; what at first seems like a bargain may only be the
            result of more relaxed grading.
          </p>
        </div>
      </div>
      <p>
        Questions about certification or other aspects of a diamond? Ask a{' '}
        <TextButton
          onClick={e => setPopupContent(DiamondConsultantContent)}
          text='diamond consultant'
        />{' '}
        for answers. A consultant will answer any questions you have, and if you
        like, search for diamonds on your behalf that match your criteria. Chat
        online, or email{' '}
        <a rel='nofollow' href='mailto:consultant@LumeraDiamonds.com'>
          consultant@lumeradiamonds.com
        </a>
        .
      </p>
      <p>
        Learn more about the content of a{' '}
        <TextButton
          onClick={e => setPopupContent(GiaGradingReportContent)}
          text='GIA Grading Report'
        />{' '}
        or{' '}
        <TextButton
          onClick={e => setPopupContent(GiaDossierContent)}
          text='GIA Dossier'
        />
        .
      </p>
    </div>
  );
}
