// @flow

import styles from '@styles/why_choose_lumera';

type PropsType = {
  setPopupContent: (N: React.Node) => void
};

export function Content({ setPopupContent }: PropsType) {
  return (
    <div>
      <h3 className={styles.h3}>Returns</h3>
      <p>
        Every order is shipped free and fully insured, via FedEx. You may return
        your diamond for a full refund, within 30 days of purchase, for any
        reason. (more on{' '}
        <a href='/customer-service/shipping' className={styles.link}>
          shipping
        </a>{' '}
        and{' '}
        <a href='/customer-service/returns' className={styles.link}>
          returns
        </a>
        )
      </p>
    </div>
  );
}
