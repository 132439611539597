// @flow

import * as PrivacyPolicyContent from './PrivacyPolicy';

import TextButton from '../TextButton';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Saving Your Diamonds';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Creating a username allows you to access your saved
        diamonds for future visits. All that is required is
        a username (such as your email). There are no passwords or other steps.
      </p>

      <p>
        Using your email address makes your username unique and easy to
        remember. Lumera Diamonds will not use or share your information for any
        reason. See our{' '}
        <TextButton
          onClick={e => setPopupContent(PrivacyPolicyContent)}
          text='privacy policy'
        />
        .
      </p>
    </div>
  );
}
