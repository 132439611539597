// @flow

import TextButton from '@components/popups/TextButton';
import * as CuletContent from './Culet';
import * as DiamondAnatomyContent from './DiamondAnatomy';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Facet';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        A facet is a flat polished surface on any side of a diamond. A brilliant
        cut diamond will have 58 facets (including the{' '}
        <TextButton onClick={e => setPopupContent(CuletContent)} text='culet' />
        ). A facet is created during the cutting of a rough diamond, and is
        polished to a glassy smoothness.
      </p>
      <p>
        Learn more about the{' '}
        <TextButton
          onClick={e => setPopupContent(DiamondAnatomyContent)}
          text='anatomy of a diamond'
        />
        .
      </p>
    </div>
  );
}
