// @flow

import TextButton from '@components/popups/TextButton';
import * as LifetimeTradeInGuaranteeContent from './LifetimeTradeInGuarantee';
import * as LumeraAppraisalContent from './LumeraAppraisal';
import * as QualityGuaranteeContent from './QualityGuarantee';
import * as FedExDeliveryContent from './FedExDelivery';
import * as SecurePackagingShippingContent from './SecurePackagingShipping';
import * as SafeShoppingGuaranteeContent from './SafeShoppingGuarantee';
import * as ReferAFriendContent from './ReferAFriend';
import * as GiaCertificateContent from './GiaCertificate';
import * as PresentationSolitaireContent from './PresentationSolitaire';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Return Policy';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        If you are not satisfied for any reason, you may return or
        exchange your item within 30 days of shipment and receive the full
        value of your purchase (our{' '}
        <TextButton
          onClick={e => setPopupContent(PresentationSolitaireContent)}
          text='presentation solitaire'
        />{' '}
        has a special <strong>One Year Exchange</strong>). 
      </p>
      <p>
        Each returned or exchanged item must be in its original condition (no
        engraving, resizing, or resetting since original purchase). Items which
        have been altered or damaged may not be returned or exchanged.{' '}
      </p>
      <p>
        Your item must be received by Lumera within 30 days of the date your order shipped from Lumera; please take shipping transit time into account when calculating the return window.{' '}
      </p>

      <p>
        Please allow up to 3 days from the date your returned item is received
        for the inspection to be completed and a refund issued.
      </p>

      <p>
        See complete details of the Lumera{' '}
        <a href='/customer-service/returns' target='_blank'>
          return policy
        </a>
        .{' '}
      </p>
      <p>
        By offering our 30-Day Return Policy, as well as our{' '}
        <TextButton
          onClick={e => setPopupContent(LifetimeTradeInGuaranteeContent)}
          text='Lifetime Trade-In Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(LumeraAppraisalContent)}
          text='Free Appraisal'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(QualityGuaranteeContent)}
          text='Quality Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(FedExDeliveryContent)}
          text='Insured Express Delivery'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(SecurePackagingShippingContent)}
          text='Secure Packaging & Shipping'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(SafeShoppingGuaranteeContent)}
          text='Safe Shopping Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(ReferAFriendContent)}
          text='Refer a Friend program'
        />
        , and{' '}
        <TextButton
          onClick={e => setPopupContent(GiaCertificateContent)}
          text='GIA Certificate'
        />
        , Lumera hopes to instill complete confidence in your purchase.
      </p>
    </div>
  );
}
