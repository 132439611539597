// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Diamond Consultant';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        A Lumera Diamond Consultant will help you find the best diamond. In as
        little as a few minutes, working with a consultant will provide the
        following benefits:
      </p>

      <ul className='no-item-bottom-margins'>
        <li>
          Your consultant will walk through your selection criteria, answering
          any questions you have while helping you to prioritize your needs in
          the areas of cut, color, clarity, carat weight, and other factors.
        </li>
        <li>
          Once your criteria are established, your consultant will find the very
          best diamonds that meet your specific needs in terms of both quality
          and price.
        </li>
        <li>
          The selected diamonds will be saved online, so that you can view them
          any time you like, comparing them side by side. You can even add or
          remove diamonds from the assortment that is created for you.
        </li>
        <li>
          Your consultant will also search offline for diamonds that meet your
          criteria. Many diamonds, including those of rarer quality and better
          value, are not yet offered online. Your consultant has access to
          virtually every diamond offered by the world's most reputable diamond
          cutters. This is especially helpful when searching for diamonds that
          are less plentiful (e.g. high quality round diamonds over 1 carat, any
          round diamond over 2 carats, and fancy shapes over 1 carat).
        </li>
        <li>
          Your consultant will inspect any diamond you select, giving you
          specific feedback beyond what may be shown on the GIA certificate,
          such as color, brightness, and the visibility of inclusions.
        </li>
        <li>
          A consultant is a complimentary service to you, and there is no
          obligation to purchase.
        </li>
      </ul>

      <p>
        <b>Get started now</b>: Chat online, or send an email to{' '}
        <a rel='nofollow' href='mailto:consultant@LumeraDiamonds.com'>
          Consultant@LumeraDiamonds.com
        </a>
        .
      </p>
    </div>
  );
}
