// @flow
module.exports = {
  // Text styles
  regularGrayText: '#696969',
  lightGrayText: '#8c8c8c',
  regularYellowText: '#e1a913',
  headerBlueText: '#2d4066',
  linkBlueText: '#1c487e',
  paragraphFontFamilies: '"acumin-pro", Arial, Helvetica, sans-serif',

  // Button styles
  buttonBlueBackground: '#1c487e',
  buttonBlueBackgroundHover: '#194979',

  //  Border styles
  borderGrayOutline: '#e1e1e1',
  borderGrayOutlineLight: '#ededed'
};
