// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const bowTieDiamond = '/images/glossary/bow_tie_diamond.jpg';

export const Header = 'Bow Tie';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Elongated fancy shapes, such as marquise, oval, pear, and radiant cut
        diamonds often display an effect commonly known as a "bow-tie". A
        bow-tie is a dark area that runs right to left across the center of the
        diamond in the approximate shape of a man's bow tie.
      </p>
      <img
        src={bowTieDiamond}
        style={{ float: 'right', margin: '20px 10px 10px 10px' }}
      />
      <p>
        The bow-tie itself is caused by light leakage resulting from the
        misalignment of facets. In this dark area, light entering the diamond is
        traveling out the pavilion instead of reflecting back to your eye. This
        creates the dark area you see when looking at the diamond. The diamond
        itself is not dark, and the bow-tie is not an inclusion.
      </p>
      <p>
        While most diamonds of these shapes possess some degree of bow tie, it
        varies in severity from light (near invisible or only visible at certain
        angles) to severe (large, easily observed from every angle). If the bow
        tie effect needs to be pointed out to you (or if you are wondering if
        your diamond has a bow tie) then it is probably quite light. Some degree
        of bow tie can be a positive as it provides contrast in the diamond;
        only when it begins to become the focal point (the first thing you
        notice) should you absolutely rule out the diamond.
      </p>
    </div>
  );
}
