// @flow

import Carousel from '../../carousels/popups/SolitaireExamples';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Solitaire';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        The most popular engagement ring style. The center diamond assumes the
        spotlight, while the simple silhouette compliments any style the wearer
        may adopt.
      </p>

      <Carousel />

      <p>
        Any solitaire can be "dressed up" with a diamond accented wedding band.
        Or, the solitaire can act as a "presentation setting"; allowing the
        question to be "popped" in surprise.
      </p>

      <p>
        Find out why we consider a solitaire{' '}
        <a
          href='/buying-diamonds/engagement-guide'
          title='Why choose a solitaire?'
          target='_blank'
        >
          the best choice for a surprise proposal
        </a>
        .
      </p>
    </div>
  );
}
