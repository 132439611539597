// @flow

import TextButton from '@components/popups/TextButton';
import * as DiamondPlotContent from './DiamondPlot';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const wisp = '/images/glossary/wisp.gif';

export const Header = 'Twinning Wisp';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p className="style1">
        A flat ribbon of pinpoints or clouds, caused by natural distortions
        created during the growth of the crystal.
      </p>
      <img src={wisp} />
      <p>
        <TextButton
          onClick={(e) => setPopupContent(DiamondPlotContent)}
          text="Learn more about inclusion and blemish types, and how they are represented"
        />
        .
      </p>
    </div>
  );
}
