// @flow

import TextButton from '@components/popups/TextButton';
import * as TableContent from './Table';
import * as PavilionContent from './Pavilion';
import * as DiamondAnatomyContent from './DiamondAnatomy';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const pavilionAngle = '/images/glossary/pavilion_angle.gif';

export const Header = 'Pavilion Angle';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <img src={pavilionAngle} style={{ marginBottom: 10 }} />
      <p>
        Refers to the number of degrees between the plane of the{' '}
        <TextButton
          onClick={(e) => setPopupContent(TableContent)}
          text="table"
        />{' '}
        and the{' '}
        <TextButton
          onClick={(e) => setPopupContent(PavilionContent)}
          text="pavilion"
        />{' '}
        facets. The angle determines how much of the light which has traveled
        into the lower part of the diamond will be successfully reflected back
        through the crown (where the observer can perceive it).
      </p>
      <p>
        Learn more about the{' '}
        <TextButton
          onClick={(e) => setPopupContent(DiamondAnatomyContent)}
          text="anatomy of a diamond"
        />
        .
      </p>
    </div>
  );
}
