// @flow

import TextButton from '@components/popups/TextButton';
import * as TheBestWayContent from './TheBestWay';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Ring Size';

export function Content({ setPopupContent }: TProps) {
  return (
    <div style={{ whiteSpace: 'normal' }}>
      <p>
        If you do not know the correct size, you have the following options:
      </p>

      <ul>
        <li>
          A physical ring sizer can be delivered in a day or two (available{' '}
          <a
            href='https://www.amazon.com/MUDDER-Stainless-Ring-Gauges-Measuring/dp/B01CCG6IQY'
            target='_blank'
            title='Metal Ring Sizer from Amazon'
          >
            here
          </a>{' '}
          and{' '}
          <a
            href='https://www.amazon.com/NIUPIKA-Finger-Measure-Jewelry-Sizing/dp/B01MQO6NDU'
            target='_blank'
            title='Plastic Ring Sizer from Amazon'
          >
            here
          </a>
          )
        </li>
        <li>
          A less precise method is to use our{' '}
          <a
            href='/buying-diamonds/ring-sizer'
            target='_blank'
            title='Printable Ring Sizer'
          >
            printable ring sizer
          </a>
        </li>
        <li>
          When in doubt about size, consider a{' '}
          <a
            href='/engagement-rings/search?style%5B%5D=Solitaire&shape=round&sort=asc'
            title='See All Solitaire Engagement Rings'
          >
            solitaire
          </a>
          , as many other styles may be difficult to resize. (Learn more about{' '}
          <TextButton
            onClick={e => setPopupContent(TheBestWayContent)}
            text='using a solitaire to maintain the surprise'
          />
          )
        </li>
      </ul>
    </div>
  );
}
