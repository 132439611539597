// @flow
import Carousel from '../ImageOnly';

const shallowImage = '/images/diamond-education/shallow_diamond_cut.jpg';
const deepImage = '/images/diamond-education/deep_diamond_cut.jpg';
const correctImage = '/images/diamond-education/correct_diamond_cut.jpg';

const items = [
  {
    headerText: 'Shallow Cut',
    imageUrl: shallowImage,
    imageLocation: 'center',
    imageAlt: 'Shallow Diamond Cut',
    imageStyles: {
      height: 88,
      width: 100,
      marginTop: 15
    }
  },
  {
    headerText: 'Deep Cut',
    imageUrl: deepImage,
    imageLocation: 'center',
    imageAlt: 'Deep Diamond Cut',
    imageStyles: {
      height: 84,
      width: 112,
      marginTop: 17,
      marginLeft: 10
    }
  },
  {
    headerText: 'Well Cut',
    imageUrl: correctImage,
    imageLocation: 'center',
    imageAlt: 'Correct Diamond Cut',
    imageStyles: {
      height: 84,
      width: 102,
      marginTop: 6
    }
  }
];

export default (props: Object) => {
  return (
    <Carousel {...props} items={items} contentStyles={{ minHeight: 124 }} />
  );
};
