// @flow

import TextButton from '@components/popups/TextButton';
import * as GirdleContent from './Girdle';
import * as DiamondPlotContent from './DiamondPlot';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const natural = '/images/glossary/natural.gif';

export const Header = 'Natural';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p className="style1">
        Small remains of the original rough stone's surface, left unpolished on
        the surface of the finished diamond. Usually found on the{' '}
        <TextButton
          onClick={(e) => setPopupContent(GirdleContent)}
          text="girdle"
        />
        .
      </p>
      <img src={natural} />
      <p>
        <TextButton
          onClick={(e) => setPopupContent(DiamondPlotContent)}
          text="Learn more about inclusion and blemish types, and how they are represented"
        />
        .
      </p>
    </div>
  );
}
