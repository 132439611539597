// @flow

import TextButton from '@components/popups/TextButton';
import * as CertificationContent from './Certification';
import * as GiaGradingReportContent from './GiaGradingReport';
import * as GiaDossierContent from './GiaDossier';
import * as GiaCertificateContent from './GiaCertificate';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const giaCampus = '/images/glossary/gia-campus.jpg';

export const Header = 'GIA';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <div className="multimedia-box">
        <img
          style={{ width: '100%' }}
          alt="GIA Campus in Carlsbad, CA"
          src={giaCampus}
        />
        <div
          className="caption"
          style={{
            marginTop: 0,
            padding: '9px 5px 12px 5px',
            color: '#9b9b9b'
          }}>
          GIA Campus in Carlsbad, CA
        </div>
      </div>
      <p>
        GIA is the oldest, largest, and one of the most respected independent
        laboratories in the world, issuing{' '}
        <TextButton
          onClick={(e) => setPopupContent(CertificationContent)}
          text="certifications"
        />{' '}
        for diamonds in the form of a{' '}
        <TextButton
          onClick={(e) => setPopupContent(GiaGradingReportContent)}
          text="Grading Report"
        />{' '}
        or{' '}
        <TextButton
          onClick={(e) => setPopupContent(GiaDossierContent)}
          text="Dossier"
        />
        . Unlike most other labs, GIA is not owned or partially owned by diamond
        wholesalers or retailers, and is a non-profit organization. In fact, GIA
        actually developed the "four Cs" rating system used almost universally
        today.
      </p>
      <p>
        The only type of certification Lumera offers is GIA; each certified
        diamond is accompanied by its{' '}
        <TextButton
          onClick={(e) => setPopupContent(GiaCertificateContent)}
          text="GIA certificate"
        />
        .
      </p>
    </div>
  );
}
