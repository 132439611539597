// @flow

import ImagesCarousel from '@components/carousels/diamond_search_popups/CutImages';
import TextCarousel from '@components/carousels/diamond_search_popups/CutText';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Cut';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Cut refers not to a diamond's shape (e.g. round, oval, pear, etc.) but
        to a diamond's proportions, symmetry and polish. The beauty of a diamond
        depends more on cut than any other factor.
      </p>

      <p>
        When light strikes a diamond, a portion will escape through the bottom
        of the diamond (where the observer cannot appreciate it). A well
        proportioned diamond will have each facet properly placed and angled so
        as to maximize the amount of light that reflects back out of the crown
        (top) of the diamond, to the eye of the observer.
      </p>

      <strong>How cut affects performance:</strong>

      <ImagesCarousel />

      <p>
        The cut grade incorporates not only facet angles and shapes, but girdle
        width, culet size, polish and symmetry as well. Because Cut grade
        provides a single rating which weighs the combined impact of many
        factors, it is a simple yet vital tool in evaluating a diamond.
      </p>

      <p>
        Unlike the other "Cs" (carat weight, color, and clarity), the various
        Cut grades in existence today were not originated by GIA, and are not
        uniformly applied. In fact, GIA has only been assigning cut grades since
        2005, and only to round diamonds.
      </p>

      <p>
        Even though retailers use common terms to describe Cut (such as
        Excellent, Very Good, Fair, Poor) the terms are not uniformly defined or
        applied. In fact, a diamond seller may assign any cut grade they choose,
        based on any set of factors they wish. One retailer will use terms such
        as "Signature Ideal", "Ideal", and "Excellent"; while another uses
        "Ideal" to describe all three, and another uses "Excellent" for all. Be
        cautious when comparing cut grades from different sources, as they are
        most likely inconsistent.
      </p>

      <p>
        To avoid confusion or misrepresentation of any diamond, Lumera uses the
        same cut grades as employed by GIA, and does not engage in "cut
        inflation" by creating other grades ("signature", "super ideal", etc.).
      </p>

      <p>
        At Lumera, the GIA cut grade is always shown. For fancy shapes (which
        lack a GIA cut grade), Lumera applies its own cut grade according to a
        proprietary formula. Like GIA, the Lumera cut grade is based on a
        variety of factors and is conservatively applied.
      </p>

      <p className='no-bottom-margin'>
        Cut grade is assigned by the GIA (or Lumera itself when there is no GIA
        grade), using the following scale:
      </p>

      <TextCarousel />

      <p>
        <a href='/diamond-education/diamond-cut' target='_blank'>
          Learn more about diamond cut
        </a>
        , as well as other related factors such as{' '}
        <a href='/diamond-education/diamond-girdle' target='_blank'>
          girdle
        </a>{' '}
        and{' '}
        <a href='/diamond-education/diamond-culet' target='_blank'>
          culet
        </a>
        .
      </p>
    </div>
  );
}
