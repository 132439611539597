// @flow

import Carousel from '../../carousels/popups/CathedralExamples';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Cathedral';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        As traditional as the name implies, cathedral refers to the way in which
        the center diamond is lifted up by arching lines that rise up from the
        setting. This focuses the eye on the center diamond, while also
        enhancing its perceived size.
      </p>

      <Carousel />
    </div>
  );
}
