// @flow

import TextButton from '@components/popups/TextButton';
import * as LifetimeTradeInGuaranteeContent from './LifetimeTradeInGuarantee';
import * as QualityGuaranteeContent from './QualityGuarantee';
import * as SafeShoppingGuaranteeContent from './SafeShoppingGuarantee';
import * as LumeraAppraisalContent from './LumeraAppraisal';
import * as GiaCertificateContent from './GiaCertificate';
import * as SecurePackagingShippingContent from './SecurePackagingShipping';
import * as FedExDeliveryContent from './FedExDelivery';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const looseDiamondImage = '/images/glossary/loose_diamond.jpg';

export const Header = 'Loose Diamond Secure Packaging';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Every diamond purchased loose (meaning, not set in a ring) arrives in a
        specially designed presentation box, which allows you to view your
        diamond while keeping it secure.
      </p>

      <img
        src={looseDiamondImage}
        style={{ display: 'block', margin: '0 auto' }}
      />

      <p>
        The diamond may be kept in the presentation ready box until you deliver
        it to a local jeweler to have it set in a ring.
      </p>

      <p>
        Every GIA diamond purchased comes with a{' '}
        <TextButton
          onClick={(e) => setPopupContent(LifetimeTradeInGuaranteeContent)}
          text="Lifetime Trade-In Guarantee"
        />
        ,{' '}
        <TextButton
          onClick={(e) => setPopupContent(QualityGuaranteeContent)}
          text="Quality Guarantee"
        />
        ,{' '}
        <TextButton
          onClick={(e) => setPopupContent(SafeShoppingGuaranteeContent)}
          text="Safe Shopping Guarantee"
        />
        ,{' '}
        <TextButton
          onClick={(e) => setPopupContent(LumeraAppraisalContent)}
          text="Free Appraisal"
        />
        ,{' '}
        <TextButton
          onClick={(e) => setPopupContent(GiaCertificateContent)}
          text="GIA Certificate"
        />
        , and{' '}
        <TextButton
          onClick={(e) => setPopupContent(SecurePackagingShippingContent)}
          text="secure shipping"
        />{' '}
        via{' '}
        <TextButton
          onClick={(e) => setPopupContent(FedExDeliveryContent)}
          text="insured FedEx Delivery"
        />
        .
      </p>
    </div>
  );
}
