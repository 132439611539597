// @flow

import TextButton from '@components/popups/TextButton';
import * as GiaGradingReportContent from './GiaGradingReport';
import * as GiaDossierContent from './GiaDossier';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const proportionDiagram = '/images/diamond-education/proportion_diagram.jpg';

export const Header = 'Proportion Diagram';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <img src={proportionDiagram} style={{ marginLeft: -10 }} />
      <p>
        Every Lumera Diamond comes with a{' '}
        <TextButton
          onClick={(e) => setPopupContent(GiaGradingReportContent)}
          text="GIA Grading Report"
        />{' '}
        or{' '}
        <TextButton
          onClick={(e) => setPopupContent(GiaDossierContent)}
          text="Dossier"
        />{' '}
        certificate, most of which include a proportion diagram. The proportion
        diagram is a graphic representation of the diamond's actual proportions.
      </p>
      <p>
        Each angle and dimension is measured electronically using a light
        scanner. The proportion diagram is a "fingerprint" of your diamond.
      </p>
    </div>
  );
}
