import { stylesheet } from 'typestyle';
import {
  regularYellowText,
  linkBlueText,
  lightGrayText
} from '@styles/general';

const caretImage = '/images/caret-down.svg';

const fullWidthPdActions = {
  marginRight: 0,
  marginBottom: 15,
  maxWidth: '100%'
};

export default stylesheet({
  container: {
    fontSize: 14,
    width: '100%',
    maxWidth: 147,
    marginRight: 12,
    marginBottom: 'auto',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    userSelect: 'none',

    '@media only screen and (min-width: 100px) and (max-width: 470px)': fullWidthPdActions,
    '@media only screen and (min-width: 680px) and (max-width: 830px)': fullWidthPdActions,
    '@media only screen and (min-width: 1010px) and (max-width: 1024px)': fullWidthPdActions,

    $nest: {
      '&:hover': {
        '& .rw-list-option.rw-state-focus': {
          borderColor: 'transparent'
        }
      },

      '& button.rw-btn-select': {
        background: 'none',
        backgroundImage: `url("${caretImage}"), linear-gradient(to bottom, #ffffff 0%, #ffffff 100%)`,
        backgroundRepeat: 'no-repeat, repeat',
        backgroundPosition: 'right 10px top 50%, 0 0',
        backgroundSize: '0.55em auto, 100%',

        $nest: {
          '& .rw-i': {
            display: 'none !important'
          }
        }
      },

      '& .rw-widget-container': {
        border: '1px solid #e5e5e5',
        borderRadius: 5,
        height: 40
      },

      '& .rw-dropdown-list-input': {
        paddingLeft: 12,
        textAlign: 'left'
      },

      '& .rw-list-option': {
        margin: 0,
        textAlign: 'left'
      },

      '& .rw-list-option.rw-state-selected': {
        $nest: {
          '&, &:active': {
            backgroundColor: linkBlueText,
            borderColor: linkBlueText
          }
        }
      }
    }
  },
  itemPrice: {
    color: lightGrayText,
    $nest: {
      '.rw-list-option.rw-state-selected &, .rw-list-option.rw-state-selected:hover &': {
        color: regularYellowText
      }
    }
  }
});
