// @flow
import Popup from './ExternalElement';
import popups from './all';

import ErrorBoundary from '../ErrorBoundary';

type TProps = {
  name: string,
  selector: string
};

export default function ExternalElementPopup (props: TProps) {
  const { name, selector, ...otherProps } = props;

  return (
    <ErrorBoundary fallback={false}>
      <Popup
        initialContent={popups[name]}
        selector={selector}
        {...otherProps}
      />
    </ErrorBoundary>
  );
}
