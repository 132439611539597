// @flow

import * as InclusionContent from './Inclusion';
import * as ClarityContent from './Clarity';

import TextButton from '../TextButton';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Blemish';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        A flaw present on the surface of the diamond, as opposed to an
        inclusion, which is internal. The degree to which blemishes and{' '}
        <TextButton
          onClick={e => setPopupContent(InclusionContent)}
          text='inclusions'
        />{' '}
        are present in a diamond is indicated by the{' '}
        <TextButton
          onClick={e => setPopupContent(ClarityContent)}
          text='clarity'
        />{' '}
        grade.
      </p>
    </div>
  );
}
