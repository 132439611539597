// @flow

import TextButton from '@components/popups/TextButton';
import * as ReturnPolicyContent from './ReturnPolicy';
import * as FedExInternationalDeliveryContent from './FedExInternationalDelivery';
import * as DiamondConsultantContent from './DiamondConsultant';
import * as LifetimeTradeInGuaranteeContent from './LifetimeTradeInGuarantee';
import * as LumeraAppraisalContent from './LumeraAppraisal';
import * as QualityGuaranteeContent from './QualityGuarantee';
import * as FedExDeliveryContent from './FedExDelivery';
import * as SecurePackagingShippingContent from './SecurePackagingShipping';
import * as SafeShoppingGuaranteeContent from './SafeShoppingGuarantee';
import * as ReferAFriendContent from './ReferAFriend';
import * as GiaCertificateContent from './GiaCertificate';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Pay Later: Purchase with Confidence';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        When you purchase using a credit card, you will not be charged until 3
        days after your order is delivered. This allows you to examine your
        diamond before you buy. (If you are looking for financing options,
        consider{' '}
        <a
          href='https://www.securecheckout.billmelater.com/paycapture-content/fetch?hash=AU826TU8&content=/bmlweb/ppwpsiw.html'
          target='_blank'
        >
          PayPal Credit
        </a>{' '}
        when purchasing via PayPal.)
      </p>
      <p className='style1' />
      <h2>How Pay Later Works</h2>
      <p />
      <ul className='style1'>
        <li>
          When you place your order (online or by phone), your credit card will
          be authorized for the amount of purchase. This confirms that the card
          is valid, and that a sufficient line of credit is available for the
          purchase.
        </li>
        <li>
          Once your order is delivered, you will have 3 days to review and
          inspect your diamond. During that time, you may elect to send the item
          back under terms of the{' '}
          <TextButton
            onClick={e => setPopupContent(ReturnPolicyContent)}
            text='30 day return guarantee'
          />
          , or to keep it.
        </li>
        <li>
          Your card will only be charged for the original purchase amount once
          we receive your approval, or three days after delivery if we do not
          hear from you otherwise.
        </li>
        <li>
          Please note that delayed payment is not available when using PayPal
          (PayPal does not allow it), or when shipping{' '}
          <TextButton
            onClick={e => setPopupContent(FedExInternationalDeliveryContent)}
            text='internationally'
          />
          . In both cases, you will not be charged until your order is shipped.
        </li>
      </ul>
      <p>
        Remember, after the 3 day evaluation period, you still have the
        remainder of the{' '}
        <TextButton
          onClick={e => setPopupContent(ReturnPolicyContent)}
          text='30 day return guarantee'
        />{' '}
        in case you later change your mind for any reason.
      </p>
      <p>
        Questions about payment or other aspects of a buying a diamond? Ask a{' '}
        <TextButton
          onClick={e => setPopupContent(DiamondConsultantContent)}
          text='diamond consultant'
        />{' '}
        for answers. A consultant will answer any questions you have, and if you
        like, search for diamonds on your behalf that match your criteria. Chat
        online, or email{' '}
        <a rel='nofollow' href='mailto:consultant@LumeraDiamonds.com'>
          consultant@lumeradiamonds.com
        </a>
        .
      </p>
      <p>
        By offering our exclusive Pay Later Option, as well as our{' '}
        <TextButton
          onClick={e => setPopupContent(LifetimeTradeInGuaranteeContent)}
          text='Lifetime Trade-In Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(ReturnPolicyContent)}
          text='30-day Return Policy'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(LumeraAppraisalContent)}
          text='Free Appraisal'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(QualityGuaranteeContent)}
          text='Quality Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(FedExDeliveryContent)}
          text='Insured Express Delivery'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(SecurePackagingShippingContent)}
          text='Secure Packaging & Shipping'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(SafeShoppingGuaranteeContent)}
          text='Safe Shopping Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(ReferAFriendContent)}
          text='Refer a Friend program'
        />
        , and{' '}
        <TextButton
          onClick={e => setPopupContent(GiaCertificateContent)}
          text='GIA Certificate'
        />
        , Lumera hopes to instill complete confidence in your purchase.
      </p>
    </div>
  );
}
