// @flow

import * as RoundContent from './Round';
import * as PrincessContent from './PrincessCut';
import * as OvalContent from './OvalCut';
import * as MarquiseContent from './MarquiseCut';
import * as PearContent from './PearShape';
import * as CushionContent from './CushionCut';
import * as EmeraldContent from './EmeraldCut';
import * as AsscherContent from './AsscherCut';
import * as RadiantContent from './RadiantCut';
import * as HeartContent from './HeartShape';

import TextButton from '../TextButton';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Shape';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        There are ten common diamond shapes (Round, Princess, Oval, Marquise,
        Pear, Cushion, Emerald, Asscher, Radiant, Heart). Round is by far the
        most popular, representing about 75% of all diamonds sold. Princess has
        gained in popularity in recent years and is the most popular fancy
        (non-round) shape.
      </p>
      <p>
        Learn about:{' '}
        <TextButton onClick={e => setPopupContent(RoundContent)} text='Round' />,{' '}
        <TextButton onClick={e => setPopupContent(PrincessContent)} text='Princess' />,{' '}
        <TextButton onClick={e => setPopupContent(OvalContent)} text='Oval' />,{' '}
        <TextButton onClick={e => setPopupContent(MarquiseContent)} text='Marquise' />,{' '}
        <TextButton onClick={e => setPopupContent(PearContent)} text='Pear' />,{' '}
        <TextButton onClick={e => setPopupContent(CushionContent)} text='Cushion' />,{' '}
        <TextButton onClick={e => setPopupContent(EmeraldContent)} text='Emerald' />,{' '}
        <TextButton onClick={e => setPopupContent(AsscherContent)} text='Asscher' />,{' '}
        <TextButton onClick={e => setPopupContent(RadiantContent)} text='Radiant' />,{' '}
        <TextButton onClick={e => setPopupContent(HeartContent)} text='Heart' />.
      </p>
    </div>
  );
}
