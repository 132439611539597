// @flow

import TextButton from '@components/popups/TextButton';
import * as GiaGradingReportContent from './GiaGradingReport';
import * as DiamondPlotContent from './DiamondPlot';
import * as ClarityContent from './Clarity';
import * as LaserInscriptionContent from './LaserInscription';
import * as ShapeContent from './Shape';
import * as DiamondDimensionsContent from './DiamondDimensions';
import * as CaratWeightContent from './CaratWeight';
import * as DepthContent from './Depth';
import * as TableContent from './Table';
import * as GirdleContent from './Girdle';
import * as CuletContent from './Culet';
import * as PolishContent from './Polish';
import * as SymmetryContent from './Symmetry';
import * as ColorContent from './Color';
import * as CutContent from './Cut';
import * as FluorescenceContent from './Fluorescence';
import * as ProportionDiagramContent from './ProportionDiagram';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const gradingReportIcon =
  '/images/diamond-education/GIA_diamond_grading_report.gif';

export const Header = 'GIA Dossier';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        An abbreviated form of the{' '}
        <TextButton
          onClick={(e) => setPopupContent(GiaGradingReportContent)}
          text="GIA Grading Report"
        />
        , often used for smaller diamonds. This condensed certificate offers the
        same information as the GIA Grading Report with the exception of a{' '}
        <TextButton
          onClick={(e) => setPopupContent(DiamondPlotContent)}
          text="diamond plot"
        />
        , since the individual flaws are less important in smaller stones (as is{' '}
        <TextButton
          onClick={(e) => setPopupContent(ClarityContent)}
          text="clarity"
        />{' '}
        in general).
      </p>
      <p>
        Every diamond that has been issued a GIA Dossier will have the report
        number{' '}
        <TextButton
          onClick={(e) => setPopupContent(LaserInscriptionContent)}
          text="laser inscribed"
        />{' '}
        on the diamond's girdle. This added security allows the purchaser to
        confirm the identity of the diamond at all times.
      </p>
      <p className="style1">
        The GIA Dossier includes the following information:
      </p>
      <ul className="style1">
        <li style={{ marginBottom: 0 }}>
          Date: The date the diamond was examined by GIA
        </li>
        <li style={{ marginBottom: 0 }}>
          Report Number: The unique tracking number assigned to each diamond and
          report.
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(ShapeContent)}
            text="Shape"
          />{' '}
          (e.g. brilliant round, square modified brilliant)
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(DiamondDimensionsContent)}
            text="Measurements:"
          />{' '}
          to the nearest one-hundredth of a millimeter
        </li>{' '}
        to the nearest one-hundredth of a millimeter
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(CaratWeightContent)}
            text="Carat Weight:"
          />{' '}
          to the nearest one-hundredth of a carat
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(DepthContent)}
            text="Depth %"
          />{' '}
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(TableContent)}
            text="Table %"
          />{' '}
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(GirdleContent)}
            text="Girdle"
          />{' '}
          Thickness{' '}
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(CuletContent)}
            text="Culet"
          />{' '}
          Size{' '}
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(PolishContent)}
            text="Polish"
          />{' '}
          Grade
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(SymmetryContent)}
            text="Symmetry"
          />{' '}
          Grade
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(ClarityContent)}
            text="Clarity"
          />{' '}
          Grade
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(ColorContent)}
            text="Color"
          />{' '}
          Grade{' '}
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(CutContent)}
            text="Cut"
          />{' '}
          Grade: for round diamonds only
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(FluorescenceContent)}
            text="Fluorescence"
          />{' '}
          Grade
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(LaserInscriptionContent)}
            text="Laser Inscription:"
          />{' '}
          Notes the content of any inscription present on the girdle of the
          diamond.
        </li>
        <li style={{ marginBottom: 0 }}>
          General Comments: Additional identifying characteristics or features
          not otherwise represented in the report.
        </li>
        <li style={{ marginBottom: 0 }}>
          <TextButton
            onClick={(e) => setPopupContent(ProportionDiagramContent)}
            text="Proportion Diagram:"
          />{' '}
          A graphic representation of the diamond's actual proportions.
        </li>
        <li style={{ marginBottom: 0 }}>
          Security Features: A variety of document security features (such as
          hologram, security screen, micro-print lines) which confirm the
          authenticity of the report.
        </li>
      </ul>
      <p>
        The certificate for any Lumera Diamond may be viewed by clicking the
        name "GIA" next to any diamond.
      </p>
      <div id="customerBox4">
        <div id="certGIA02">
          <a href="/files/GIA_Dossier.pdf" target="_blank">
            <img
              src={gradingReportIcon}
              style={{ width: 60, marginRight: 10 }}
            />
          </a>
          <a href="/files/GIA_Dossier.pdf" target="_blank">
            View a GIA Dossier
          </a>
        </div>
      </div>
    </div>
  );
}
