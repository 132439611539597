// @flow

import TextButton from '@components/popups/TextButton';
import * as GiaContent from './Gia';
import * as DiamondConsultantContent from './DiamondConsultant';
import * as GiaGradingReportContent from './GiaGradingReport';
import * as GiaDossierContent from './GiaDossier';

import DiamondClarityCarousel from '@components/carousels/popups/DiamondClarity';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const diamondPlot = '/images/diamond-education/diamond_plot.gif';

export const Header = 'Diamond Plot';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Because a photograph cannot capture the subtleties of clarity,{' '}
        <TextButton
          onClick={(e) => setPopupContent(GiaContent)}
          text="GIA"
        />{' '}
        uses a diamond plot to map a diamond's interior and exterior flaws. A
        diamond plot is a graphic representation of every flaw that affects the
        overall clarity grade. The flaws are found under 10x magnification by a
        skilled grader.
      </p>
      <div className="multimedia-box">
        <img
          style={{ width: '100%' }}
          alt="GIA Diamond Plot"
          src={diamondPlot}
        />
        <div
          className="caption"
          style={{
            marginTop: 0,
            padding: '9px 5px 12px 5px',
            color: '#9b9b9b'
          }}>
          Example GIA Diamond Plot: The Key To Symbols section shows the flaws
          in order of severity
        </div>
      </div>
      <p>
        While the plot shows the type and position of each flaw, the actual
        visibility of the flaw is communicated in the diamond Clarity Grade
        itself (i.e. two diamonds may have very similar plots, but very
        different Clarity grades, reflecting the actual severity and visibility
        of the recorded flaws).
      </p>
      <p>
        Besides the plot, there is also a comment section on the certificate
        where additional clarity characteristics are often noted. These are
        usually too minor to be reflected in the plot itself. Between the plot
        and the comment section, all inclusions visible under 10x magnification
        are accounted for.
      </p>

      <p>
        <strong>Clarity Definitions:</strong>
        <br />
        Common inclusions and blemishes, as they are represented on the GIA
        diamond plot, are illustrated below. GIA uses the color green for
        surface blemishes, and red for internal inclusions. Sometimes the nature
        of the inclusion (if it reaches the surface, for example) calls for the
        use of both green and red. The color black is used to convey extra
        facets.
      </p>

      <DiamondClarityCarousel setPopupContent={setPopupContent} />

      <div id="caution" style={{ paddingTop: '5px' }}>
        <h3>A note of caution:</h3>
        <p>
          A diamond plot does not reproduce the actual appearance of a diamond.
          For SI1 or lower grades, do not assume that a relatively clean plot
          indicates that there are no flaws visible to the naked eye. Often, a
          plot may carry only one or two markings, but these are so severe that
          they warrant a lower overall clarity grade.
        </p>
        <p style={{ marginBottom: 0 }}>
          By the same token, a cluttered plot may not mean the diamond is
          visually flawed. The diamond may in fact be flawless to the naked eye,
          since no single inclusion is severe enough to be seen (even though the
          cumulative effect of the flaws might warrant a lower clarity grade).
          When in doubt, speak to a{' '}
          <TextButton
            onClick={(e) => setPopupContent(DiamondConsultantContent)}
            text="diamond consultant"
          />
          , who will gladly review a diamond on your behalf.
        </p>
      </div>
      <p>
        The standard{' '}
        <TextButton
          onClick={(e) => setPopupContent(GiaGradingReportContent)}
          text="GIA Grading Report"
        />{' '}
        includes a diamond plot, the abbreviated{' '}
        <TextButton
          onClick={(e) => setPopupContent(GiaDossierContent)}
          text="GIA Dossier"
        />{' '}
        (often used for diamonds under 1 carat) does not.
      </p>
    </div>
  );
}
