// @flow

import TextButton from '@components/popups/TextButton';
import * as FacetContent from './Facet';
import * as ScintillationContent from './Scintillation';
import * as BrillianceContent from './Brilliance';
import * as FireContent from './Fire';
import * as CutContent from './Cut';

import Carousel from '../../carousels/diamond_education/TopImageDiamondCutProportions';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Diamond Proportion';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Proportion refers to the relationship between the size, shape, and angle
        of each{' '}
        <TextButton onClick={e => setPopupContent(FacetContent)} text='facet' />{' '}
        of a diamond. A wide range of combinations are possible, ultimately
        determining the diamond's interaction with light.
      </p>
      <p>
        When light strikes a diamond, approximately 20% immediately reflects off
        the surface (as glare). Of the 80% that enters, a portion will escape
        through the bottom of the diamond (where the observer cannot appreciate
        it). A well proportioned diamond will have each facet properly placed
        and angled so as to maximize the amount of light that reflects back out
        of the crown (top) of the diamond, to the eye of the observer. This
        reflected light is perceived as{' '}
        <TextButton
          onClick={e => setPopupContent(ScintillationContent)}
          text='scintillation'
        />
        , <TextButton onClick={e => setPopupContent(FireContent)} text='fire' />{' '}
        and{' '}
        <TextButton
          onClick={e => setPopupContent(BrillianceContent)}
          text='brilliance'
        />
        .
      </p>
      <p>
        In the diagram below, three common light patterns are shown. When light
        meets any facet of a diamond, it will either reflect (bounce back) or
        refract (bend while passing through the facet). The angle that the light
        hits the facet determines whether the majority of light reflects or
        refracts, which is why cut is so important.
      </p>
      <Carousel setPopupContent={setPopupContent} />
      <p>
        Learn more about{' '}
        <TextButton
          onClick={e => setPopupContent(CutContent)}
          text='proportion and cut'
        />
        .{' '}
      </p>
    </div>
  );
}
