// @flow

import TextButton from '@components/popups/TextButton';
import * as ClarityContent from './Clarity';
import * as GiaContent from './Gia';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Inclusion';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        An internal flaw in the diamond. The position, size, number, color, and
        reflectivity of a diamond's inclusions has a significant impact on its
        appearance and value.{' '}
        <TextButton onClick={e => setPopupContent(ClarityContent)} text='Clarity' />{' '}
        refers to the degree to which these natural flaws are present. Diamonds
        graded by the{' '}
        <TextButton onClick={e => setPopupContent(GiaContent)} text='GIA' />{' '}
        and other labs have their clarity rated on a scale of F (flawless) to i3
        (significant inclusions).
      </p>
      <p>
        Learn more about{' '}
        <TextButton onClick={e => setPopupContent(ClarityContent)} text='clarity' />
        .
      </p>
    </div>
  );
}
