// @flow

import styles from '@styles/why_choose_lumera';

type PropsType = {
  setPopupContent: (N: React.Node) => void
};

export function Content({ setPopupContent }: PropsType) {
  return (
    <div>
      <h3 className={styles.h3}>Selection</h3>
      <p>
        Over 40,000 GIA certified diamonds, 100 x the selection of the largest
        jewelry stores. (
        <a href='/diamonds/search' className={styles.link}>
          search now
        </a>
        )
      </p>
    </div>
  );
}
