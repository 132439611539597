// @flow

import Carousel from '../../carousels/popups/InfinityExamples';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Infinity';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        A combination of whimsical, soft curves, twists, and loops - a
        wonderful way to add flair to a round diamond, or to compliment a more
        exotic shape.
      </p>

      <Carousel />
    </div>
  );
}
