// @flow

import TextButton from '@components/popups/TextButton';
import * as FacetContent from './Facet';
import * as DiamondPlotContent from './DiamondPlot';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const facet = '/images/glossary/facet.gif';

export const Header = 'Extra Facet';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p className="style1">
        Any{' '}
        <TextButton
          onClick={(e) => setPopupContent(FacetContent)}
          text="facet"
        />{' '}
        that is not a part of the shape's standard cut. Usually created by the
        cutter due to a limitation or flaw in the original rough stone.
      </p>
      <img src={facet} />
      <p>
        <TextButton
          onClick={(e) => setPopupContent(DiamondPlotContent)}
          text="Learn more about inclusion and blemish types, and how they are represented"
        />
        .
      </p>
    </div>
  );
}
