// @flow
import Carousel from '../ImageAndText';

const dfImage = '/images/diamond-education/colorless.gif';
const gjImage = '/images/diamond-education/near_colorless_narrow.gif';
const kmImage = '/images/diamond-education/faint_color.gif';
const nrImage = '/images/diamond-education/vlight_color.gif';
const szImage = '/images/diamond-education/light_color.gif';

const items = [
  {
    headerText: 'Colorless',
    text: `
      <p>
        Color differences between D, E, and F diamonds are difficult to see by
        the untrained eye, but can be reliably detected by a gemologist in side
        by side comparison.
      </p>
    `,
    imageUrl: dfImage,
    imageLocation: 'top',
    imageAlt: 'D-F Diamond Color Scale',
    imageStyles: {
      margin: 15,
      marginTop: 23,
      marginBottom: 0,
      width: 213,
      maxWidth: '100%'
    }
  },
  {
    headerText: 'Near Colorless',
    text: `
      <p>
        G-H appear colorless to most in everyday environments and are suitable
        for white gold and platinum, though they do contain visible color. I-J
        diamonds are more common, and tend to be a good value, often selling for
        half the price of D color.
      </p>
    `,
    imageUrl: gjImage,
    imageLocation: 'top',
    imageAlt: 'G-J Diamond Color Scale',
    imageStyles: {
      margin: 15,
      marginTop: 23,
      marginBottom: 0,
      width: 282,
      maxWidth: '100%'
    }
  },
  {
    headerText: 'Faint Color',
    text: `
      <p>
        Color is more easily detected by the unaided, untrained eye. Set in
        yellow gold, these warm colored diamonds appeal to some, and are an
        exceptional value.
      </p>
    `,
    imageUrl: kmImage,
    imageLocation: 'top',
    imageAlt: 'K-M Diamond Color Scale',
    imageStyles: {
      margin: 15,
      marginTop: 23,
      marginBottom: 0,
      width: 213,
      maxWidth: '100%'
    }
  },
  {
    headerText: 'Very Light Color',
    text: `
      <p>
        Easily seen yellow or brown tint, much less expensive than higher
        grades. Lumera does not carry diamonds in this color range due to a lack
        of demand. 
      </p>
    `,
    imageUrl: nrImage,
    imageLocation: 'top',
    imageAlt: 'N-R Diamond Color Scale',
    imageStyles: {
      margin: 15,
      marginTop: 23,
      marginBottom: 0,
      width: 52,
      maxWidth: '100%'
    }
  },
  {
    headerText: 'Light Color',
    text: `
      <p>
        For almost all customers, S-Z diamonds have too much color for a white
        diamond.
      </p>
    `,
    imageUrl: szImage,
    imageLocation: 'top',
    imageAlt: 'S-Z Diamond Color Scale',
    imageStyles: {
      margin: 15,
      marginTop: 23,
      marginBottom: 0,
      width: 52,
      maxWidth: '100%'
    }
  }
];

export default (props: Object) => {
  return (
    <Carousel
      {...props}
      items={items}
      contentStyles={{ minHeight: 285, margin: 15 }}
    />
  );
};
