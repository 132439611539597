// @flow
import { useEffect, useState } from 'react';
import Carousel from '../ImageOnly';
import ErrorBoundary from '../../ErrorBoundary';

import useAxios from '../../../hooks/useAxios';
import * as routes from '../../../app_routes/settings';

import skuData from '@lumera_configs/style_guide_examples/diamond-accent.txt';

const skus = skuData.split(/,\s*/).map(s => s.trim());

function dataToItem(data) {
  return {
    headerText: 'Diamond Accent Styles',
    imageUrl: data.image,
    imageLocation: 'center',
    imageAlt: data.alt_tag
  };
}

function DiamondAccentExamples(props) {
  const [items, setItems] = useState([]);
  const axios = useAxios();

  useEffect(() => {
    axios
      .get(routes.apiExampleSettings, {
        params: { skus }
      })
      .then(res => setItems(res.data.map(dataToItem)));
  }, []);

  if (items.length > 0) {
    return <Carousel {...props} items={items} />;
  } else {
    return null;
  }
}

export default (props: Object) => {
  return (
    <ErrorBoundary fallback={false}>
      <DiamondAccentExamples {...props} />
    </ErrorBoundary>
  );
};
