// @flow

import * as ActualDiamondImageContent from './ActualDiamondImage';
import * as LengthWidthContent from './LengthToWidthRatio';
import * as ShapeContent from './Shape';

import TextButton from '../TextButton';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const sizeChartImage = '/images/buying-diamonds/size_chart.svg';

export const Header = 'Sample Image';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        The sample image is not the actual diamond. However, the sample image
        does match the{' '}
        <TextButton
          onClick={(e) => setPopupContent(ShapeContent)}
          text="shape"
        />{' '}
        and{' '}
        <TextButton
          onClick={(e) => setPopupContent(LengthWidthContent)}
          text="length to width ratio"
        />{' '}
        of the actual diamond. This means you can use the sample image to see
        the true shape of the diamond; whether elongated, near-square, wide,
        etc.
      </p>

      <p>
        Photo and video (images) of the actual diamond cannot accurately convey
        cut, color, or clarity.
      </p>

      <p>
        For this reason, Lumera provides (and strongly encourages) a free
        gemological review of any diamond prior to purchase. You will receive a
        response to your email in as little as one hour, and virtually eliminate
        the risk of an unpleasant surprise. Our return rate is the lowest in the
        industry due to this approach.
      </p>

      <p>
        To receive gemological review of a diamond, or to ask for help of any
        kind, email{' '}
        <a rel='nofollow' href='mailto:consultant@lumeradiamonds.com'>
          consultant@lumeradiamonds.com
        </a>. Feel free to include any
        evaluation criteria you are using, or to share your saved diamonds. This
        will allow the gemologist to provide meaningful feedback and assist you
        in refining your approach.
      </p>

      <p>
        If you would like to see the true size of this or other diamonds, print
        the Actual Diamond Size Chart below:
      </p>

      <section>
        <a href="/files/size_chart.pdf" target="_blank">
          <img
            src={sizeChartImage}
            style={{
              float: 'left',
              margin: '0 20px 10px 0',
              border: 0,
              width: 43
            }}
          />
        </a>

        <h2 style={{ paddingTop: 3 }}>PRINTABLE SIZE CHART</h2>

        <p style={{ marginBottom: 35 }}>
          Print a copy of our diamond size chart to see the actual size of a
          diamond.
        </p>

        <section style={{ display: 'inline-block' }}>
          <a
            href="/files/size_chart.pdf"
            target="_blank"
            className="blue button"
            style={{ marginBottom: 20 }}>
            PRINT SIZE CHART
          </a>
        </section>

        <p>
          (Requires Adobe Acrobat Reader. Download it{' '}
          <a href="http://get.adobe.com/reader/?promoid=BUIGO" target="_blank">
            here
          </a>
          .){' '}
        </p>
      </section>
    </div>
  );
}
