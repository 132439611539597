// @flow

import styles from '@styles/why_choose_lumera';

type PropsType = {
  setPopupContent: (N: React.Node) => void
};

export function Content({ setPopupContent }: PropsType) {
  return (
    <div>
      <h3 className={styles.h3}>Value</h3>
      <p>
        Buy GIA diamonds at prices 40% less than the lowest traditional jewelry
        store sale prices. (
        <a href='/diamonds/search' className={styles.link}>
          search now
        </a>
        )
      </p>
    </div>
  );
}
