// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const image = '/images/popups/shape/heart.jpg';

export const Header = 'Heart Shaped Diamonds';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <img
        src={image}
        style={{ float: 'left', width: 55, marginTop: 18, marginRight: 13 }}
      />
      <span style={{ fontWeight: 'bold' }}>Heart</span>
      <p>
        The heart shaped diamond is a unique and unmistakable symbol of love;
        popular in solitaire pendants as well as rings.
      </p>

      <p>
        When choosing a heart, symmetry is a very important characteristic,
        since it is critical that the two halves of the heart are identical. The
        cleft (between the two lobes) should be sharp and distinct, and the
        wings (the sides as they curve down to the point) should have a very
        slightly rounded shape.
      </p>

      <p>
        Heart shaped diamonds of less than .50 carats may not be a good choice,
        as the heart shape is more difficult to perceive in smaller diamonds,
        especially after they are set in prongs.
      </p>

      <p>
        <a href="/diamond-education/heart-shaped-diamond" target="_blank">
          Learn more about heart shaped diamonds
        </a>
        , including their history, as well as how to evaluate cut parameter,
        color, and clarity.
      </p>
    </div>
  );
}
