// @flow

type PositionOptions = {
  anchorEl: HTMLElement,
  dimensions: {
    minWidth: void | number | string,
    maxWidth: void | number | string
  },
  scrollsToPopup?: boolean,
  fitVertically?: boolean,
  constrainToAppContainer?: boolean // currently only works for positioning on bottom
};

type MainOptions = {
  ...PositionOptions,
  preferSideAttachment: boolean
};

export const POPUP_BOTTOM_MARGIN = 35;
export const MAX_POPUP_HEIGHT = 400;

const calculatePositionToLeftOfAnchor = (options: PositionOptions) => {
  if (!window || !document.documentElement || !document.body) return {};

  const { anchorEl, dimensions, fitVertically } = options;

  // If no max is specified, we set it to the min in the CSS
  const popupMaxWidth = parseInt(dimensions.maxWidth || dimensions.minWidth);

  const popupLocationAboveAnchorTop = 19;

  const marginBetweenCaretAndAnchor = 20;
  const anchorLeftMargin = parseInt(getCss(anchorEl, 'margin-left'));
  const anchorTopMargin = parseInt(getCss(anchorEl, 'margin-top'));
  const anchorPageOffsetTop = getOffset(anchorEl).top;
  const elPosition = getPosition(anchorEl);

  const viewportToLeftOfAnchor = elPosition.left + anchorLeftMargin;

  // If there isn't enough space, return undefined so we can try another location
  if (viewportToLeftOfAnchor < popupMaxWidth + marginBetweenCaretAndAnchor)
    return undefined;

  const popupLeft =
    viewportToLeftOfAnchor - (popupMaxWidth + marginBetweenCaretAndAnchor);
  const top = elPosition.top + anchorTopMargin - popupLocationAboveAnchorTop;
  const anchorLeft = popupMaxWidth - 2;
  const anchorRotation = 90;
  const anchorTop = popupLocationAboveAnchorTop;

  const scrollLocationAboveAnchor = 30;

  const popupBottomLocation =
    pageScrollTop() + viewportHeight() - POPUP_BOTTOM_MARGIN;
  const popupTopLocation = anchorPageOffsetTop - scrollLocationAboveAnchor;

  const maxHeightInsideViewport = popupBottomLocation - popupTopLocation;

  return {
    popupLeft,
    top,
    maxHeight: Math.min(
      fitVertically ? maxHeightInsideViewport : MAX_POPUP_HEIGHT,
      MAX_POPUP_HEIGHT
    ),
    anchorLeft: anchorLeft,
    anchorTop,
    anchorOffsetTop: anchorPageOffsetTop,
    anchorRotation,
    scrollTo: anchorPageOffsetTop - scrollLocationAboveAnchor
  };
};

const calculatePositionToRightOfAnchor = (options: PositionOptions) => {
  if (!window || !document.documentElement || !document.body) return {};
  options.fitVertically = true;

  const { anchorEl, dimensions, fitVertically } = options;

  // If no max is specified, we set it to the min in the CSS
  const popupMaxWidth = parseInt(dimensions.maxWidth || dimensions.minWidth);

  const popupLocationAboveAnchorTop = 19;

  const marginBetweenCaretAndAnchor = 20;
  const anchorLeftMargin = parseInt(getCss(anchorEl, 'marginLeft'));
  const anchorTopMargin = parseInt(getCss(anchorEl, 'marginTop'));
  const anchorPageOffsetTop = getOffset(anchorEl).top;
  const elPosition = getPosition(anchorEl);
  const anchorDimensions = {
    height: getHeight(anchorEl, 'full'),
    width: getWidth(anchorEl, 'full')
  };
  const viewportWidth = getViewportWidth();
  const anchorWidth = anchorDimensions.width || 0;

  const viewportToLeftOfAnchor = elPosition.left + anchorLeftMargin;
  const viewportToRightOfAnchor =
    viewportWidth - elPosition.left - anchorLeftMargin - anchorWidth;

  // If there isn't enough space, return undefined so we can try another location
  if (viewportToRightOfAnchor < popupMaxWidth + marginBetweenCaretAndAnchor)
    return undefined;

  const popupLeft =
    viewportToLeftOfAnchor +
    anchorDimensions.width +
    marginBetweenCaretAndAnchor;
  const top = elPosition.top + anchorTopMargin - popupLocationAboveAnchorTop;
  const anchorLeft = -8;
  const anchorRotation = -90;
  const anchorTop = popupLocationAboveAnchorTop;

  const scrollLocationAboveAnchor = 30;

  const popupBottomLocation =
    pageScrollTop() + viewportHeight() - POPUP_BOTTOM_MARGIN;
  const popupTopLocation = anchorPageOffsetTop - scrollLocationAboveAnchor;

  const maxHeightInsideViewport = popupBottomLocation - popupTopLocation;

  return {
    popupLeft,
    top,
    anchorLeft: anchorLeft,
    anchorTop,
    anchorOffsetTop: anchorPageOffsetTop,
    anchorRotation,
    maxHeight: Math.min(
      fitVertically ? maxHeightInsideViewport : MAX_POPUP_HEIGHT,
      MAX_POPUP_HEIGHT
    ),
    scrollTo: anchorPageOffsetTop - scrollLocationAboveAnchor
  };
};

const calculatePositionBelowAnchor = (options: PositionOptions) => {
  if (!window || !document.documentElement || !document.body) return {};

  options.fitVertically = true;

  const { anchorEl, dimensions, scrollsToPopup, fitVertically } = options;

  const anchorDimensions = {
    height: getHeight(anchorEl, 'full'),
    width: getWidth(anchorEl, 'full')
  };
  const anchorLeftMargin = parseInt(getCss(anchorEl, 'marginLeft'));
  const elPosition = getPosition(anchorEl);
  const $appContainer = Rails.$('.app-container')[0];
  const caretHeight = 7;
  const topMargin = 3;
  const topOfAnchorToTopOfPopup =
    anchorDimensions.height + caretHeight + topMargin;
  const top = elPosition.top + topOfAnchorToTopOfPopup;
  const anchorPageOffsetTop = getOffset(anchorEl).top;
  const anchorWidth = anchorDimensions.width || 0;
  const anchorHeight = anchorDimensions.height || 0;

  // Subtracting 5.5 puts the tip of the caret aligned with the left side of the
  // anchor. We need to also shift over the same dimension as the left margin,
  // then subtract the left margin from the anchor width in order to figure out
  // the center point of the anchor.
  const anchorLeft =
    elPosition.left +
    anchorLeftMargin -
    5.5 +
    (anchorWidth - anchorLeftMargin) / 2;

  const anchorViewportOffsetTop = anchorPageOffsetTop - pageScrollTop();
  const availableViewportBelowAnchor =
    viewportHeight() - anchorViewportOffsetTop - anchorHeight;

  // We have to add the anchor height because the popup is absolutely positioned
  // at the bottom of the anchor, and we don't take that into account. By
  // adding the anchor height, we do take it into account.
  const maxHeightInsideViewport =
    availableViewportBelowAnchor -
    topOfAnchorToTopOfPopup -
    POPUP_BOTTOM_MARGIN +
    anchorDimensions.height;

  // We are not using this but keep it for now in case we want to switch back
  // from constraining to the document.
  const maxHeightInsideAppContainer =
    (getHeight($appContainer, 'height') || 0) -
    (anchorPageOffsetTop +
      topOfAnchorToTopOfPopup -
      getOffset($appContainer).top) -
    POPUP_BOTTOM_MARGIN;

  const maxHeightInsideDocument =
    pageHeight() -
    (anchorPageOffsetTop + topOfAnchorToTopOfPopup) -
    POPUP_BOTTOM_MARGIN;

  const maxHeight = Math.max(
    scrollsToPopup
      ? 0 // We don't need to make sure that the popup has a minimum height
      : 60, // Give the popup a decent minimum height in case it is close to the bottom of the page and will be extremely short
    Math.min(
      options.constrainToAppContainer
        ? maxHeightInsideAppContainer
        : maxHeightInsideDocument,
      maxHeightInsideViewport
    )
  );

  // If no max is specified, we set it to the min in the CSS
  let popupMaxWidth = parseInt(dimensions.maxWidth || dimensions.minWidth);

  const sideMargin = 5;

  // The 20px gives a bit of margin from the top right corner.
  // The -10px in the Math.max allows is to shift the popup left up to 10 px if
  // the anchor is 10 or less pixels from the left edge of our parent.
  let popupLeft =
    0 + Math.max(anchorLeft - popupMaxWidth + 20, Math.min(0, anchorLeft - 10));

  // Make sure the popup is not off-screen
  if (typeof document !== 'undefined') {
    const browserWidth = document.body.clientWidth;
    const anchorElParentLeft = getOffset(anchorEl).left - elPosition.left;
    const rightEdgeLocation = popupMaxWidth + anchorElParentLeft + popupLeft;

    if (rightEdgeLocation > browserWidth - sideMargin) {
      const leftDiff =
        browserWidth - sideMargin - popupMaxWidth - anchorElParentLeft;

      // Shift left if the popup is off the right of the screen
      popupLeft = popupLeft + leftDiff;

      // Make sure we didn't shift off the left of the screen. If we have to
      // fall off one side, it is better to fall off the right side.
      popupLeft = Math.max(-anchorElParentLeft + 5, popupLeft);
    }
  }

  return {
    popupLeft,
    top,
    anchorLeft: anchorLeft - popupLeft, // we need to shift the anchor by the same amount we shift the popup by
    anchorTop: -14,
    anchorOffsetTop: anchorPageOffsetTop,
    maxHeight: Math.min(
      fitVertically ? maxHeight : MAX_POPUP_HEIGHT,
      MAX_POPUP_HEIGHT
    ),
    anchorRotation: 0,
    scrollTo: anchorPageOffsetTop - 5
  };
};

export default (options: MainOptions) => {
  let scrollsToPopup = options.scrollsToPopup;
  if (typeof scrollsToPopup === 'undefined') {
    scrollsToPopup = true;
  }

  const { preferSideAttachment, ...otherOptions } = options;
  const otherOptionsWithScrollsToPopup = {
    ...otherOptions,
    scrollsToPopup
  };

  if (preferSideAttachment) {
    return (
      calculatePositionToLeftOfAnchor(otherOptionsWithScrollsToPopup) ||
      calculatePositionToRightOfAnchor(otherOptionsWithScrollsToPopup) ||
      calculatePositionBelowAnchor(otherOptionsWithScrollsToPopup)
    );
  } else {
    return calculatePositionBelowAnchor(otherOptionsWithScrollsToPopup);
  }
};

export function pageScrollTop() {
  return (
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    0
  );
}

export function scrollIncrement(gap) {
  if (gap > 1000) {
    return 500;
  } else if (gap > 500) {
    return 100;
  } else if (gap > 100) {
    return 75;
  } else if (gap > 75) {
    return 50;
  } else if (gap > 50) {
    return 30;
  } else if (gap > 30) {
    return 20;
  } else if (gap > 20) {
    return 10;
  } else if (gap > 10) {
    return 9;
  } else if (gap > 9) {
    return 8;
  } else if (gap > 8) {
    return 7;
  } else if (gap > 7) {
    return 6;
  } else if (gap > 6) {
    return 5;
  } else if (gap > 5) {
    return 4;
  } else if (gap > 4) {
    return 3;
  } else if (gap > 3) {
    return 2;
  } else if (gap > 2) {
    return 1;
  } else if (gap >= 1) {
    return 1;
  } else if (gap === 0) {
    return 0;
  } else {
    return -1;
  }
}

export function pageHeight() {
  return document.documentElement.scrollHeight || document.body.scrollHeight;
}

export function viewportHeight() {
  return document.documentElement.clientHeight;
}

function getViewportWidth() {
  return document.documentElement.clientWidth;
}

function getPosition(el: HTMLElement) {
  return { left: el.offsetLeft, top: el.offsetTop };
}

function getOffset(el: HTMLElement) {
  const box = el.getBoundingClientRect();
  const docElem = document.documentElement;
  const clientTop = docElem ? docElem.clientTop : 0;
  const clientLeft = docElem ? docElem.clientLeft : 0;

  return {
    top: box.top + window.pageYOffset - clientTop,
    left: box.left + window.pageXOffset - clientLeft
  };
}

function getCss(elem: HTMLElement, name: string) {
  // NOTE: Known bug, will return 'auto' if style value is 'auto'
  const win = elem.ownerDocument.defaultView;

  // null means not to return pseudo styles
  return win.getComputedStyle(elem, null)[name];
}

function getWidth(el: HTMLElement, type: 'inner' | 'outer' | 'width' | 'full') {
  if (type === 'inner') {
    // .innerWidth()
    return el.clientWidth;
  } else if (type === 'outer') {
    // .outerWidth()
    return el.offsetWidth;
  }

  let s = window.getComputedStyle(el, null);

  if (type === 'width') {
    // .width()
    return (
      el.clientWidth -
      parseInt(s.getPropertyValue('padding-left')) -
      parseInt(s.getPropertyValue('padding-right'))
    );
  } else if (type === 'full') {
    // .outerWidth(includeMargins = true)
    return (
      el.offsetWidth +
      parseInt(s.getPropertyValue('margin-left')) +
      parseInt(s.getPropertyValue('margin-right'))
    );
  }

  return null;
}

function getHeight(
  el: HTMLElement,
  type: 'inner' | 'outer' | 'height' | 'full'
) {
  if (type === 'inner') {
    // .innerHeight()
    return el.clientHeight;
  } else if (type === 'outer') {
    // .outerHeight()
    return el.offsetHeight;
  }

  let s = window.getComputedStyle(el, null);

  if (type === 'height') {
    // .height()
    return (
      el.clientHeight -
      parseInt(s.getPropertyValue('padding-top')) -
      parseInt(s.getPropertyValue('padding-bottom'))
    );
  } else if (type === 'full') {
    // .outerHeight(includeMargins = true)
    return (
      el.offsetHeight +
      parseInt(s.getPropertyValue('margin-top')) +
      parseInt(s.getPropertyValue('margin-bottom'))
    );
  }
  return null;
}
