// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const blueCardImage = '/images/bubble/blue_card.jpg';
const goldCardImage = '/images/bubble/gold_card.jpg';

export const Header = 'CVV';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        CVV (also known as CVC or CID) is an anti-fraud security feature used to
        verify that you are in physical possession of your credit card.
      </p>

      <p>
        For <b>Visa</b>, <b>MasterCard</b>, and <b>Discover</b>, the three-digit
        CVV number is printed on the signature panel on the back of the card,
        immediately after the account number.{' '}
      </p>
      <p style={{ textAlign: 'center' }}>
        <img src={blueCardImage} />
      </p>

      <p>
        For <b>American Express</b>, the four-digit CVV number is printed on the
        front of the card above the account number.
      </p>

      <p style={{ textAlign: 'center' }}>
        <img src={goldCardImage} />
      </p>
    </div>
  );
}
