// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

const image = '/images/popups/shape/asscher.jpg';
export const Header = 'Asscher Cut Diamonds';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <img
        src={image}
        style={{ float: 'left', width: 55, marginTop: 18, marginRight: 13 }}
      />
      <span style={{ fontWeight: 'bold' }}>Asscher</span>
      <p>
        The asscher cut diamond was first produced in 1902 by the Asscher
        Brothers of Holland, and first peaked in popularity in the 1920's.
        Around 2002, one hundred years after the first asscher cut diamond was
        created, the shape began to make a comeback, spurred on by cut
        modifications that gave the shape more brilliance than traditional
        asscher cut diamonds.
      </p>

      <p>
        The modern asscher cut diamond is similar to a square emerald cut,
        usually with larger step facets, a higher crown, and a smaller table. A
        well cut asscher will appear to have concentric squares as you look down
        through the table, the result of proper positioning of the pavilion
        facets underneath.
      </p>

      <p>
        <a href="/diamond-education/asscher-cut-diamond" target="_blank">
          Learn more about Asscher cut diamonds
        </a>
        , including their history, as well as how to evaluate cut parameters,
        color, and clarity.
      </p>
    </div>
  );
}
