// @flow
import { Fragment } from 'react';
import Carousel from '../ImageAndText';

import TextButton from '@components/popups/TextButton';

import * as PavilionContent from '../../popups/content/Pavilion';
import * as FireContent from '../../popups/content/Fire';

const shallowImage = '/images/diamond-education/shallow_diamond_cut.jpg';
const deepImage = '/images/diamond-education/deep_diamond_cut.jpg';
const correctImage = '/images/diamond-education/correct_diamond_cut.jpg';

const ShallowCut = ({ setPopupContent }) => {
  return (
    <Fragment>
      Cut too shallow, entering light strikes the{' '}
      <TextButton
        onClick={(e) => setPopupContent(PavilionContent)}
        text="pavilion"
      />{' '}
      facet at a low angle and passes through the bottom of the diamond.
    </Fragment>
  );
};

const WellCut = ({ setPopupContent }) => {
  return (
    <Fragment>
      In a well cut diamond, the light strikes each facet at an angle which
      allows most of the light to reflect back to the crown (top), perceived as
      a lively{' '}
      <TextButton onClick={(e) => setPopupContent(FireContent)} text="fire" />.
    </Fragment>
  );
};

const items = (setPopupContent) => {
  return [
    {
      headerText: 'Shallow Cut',
      component: <ShallowCut setPopupContent={setPopupContent} />,
      imageUrl: shallowImage,
      imageLocation: 'top',
      imageAlt: 'Shallow Diamond Cut',
      imageStyles: {
        marginTop: 12,
        height: 107,
        width: 122
      }
    },
    {
      headerText: 'Deep Cut',
      text: `Cut is too deep, entering light strikes the second pavilion at too
        low an angle, causing the light to escape through the bottom.`,
      imageUrl: deepImage,
      imageLocation: 'top',
      imageAlt: 'Deep Diamond Cut',
      imageStyles: {
        marginRight: 1,
        marginTop: 15,
        height: 90,
        width: 121
      }
    },
    {
      headerText: 'Well Cut',
      component: <WellCut setPopupContent={setPopupContent} />,
      imageUrl: correctImage,
      imageLocation: 'top',
      imageAlt: 'Correct Diamond Cut',
      imageStyles: {
        marginTop: 2,
        height: 100,
        width: 122
      }
    }
  ];
};

const DiamondCutProportions = (props: Object) => {
  const { setPopupContent, ...otherProps } = props;

  return <Carousel {...otherProps} items={items(setPopupContent)} />;
};

export default DiamondCutProportions;
