// @flow
import typestyle from '@src/utils/typestyle';

import * as Round from './Round';
import * as Princess from './PrincessCut';
import * as Oval from './OvalCut';
import * as Marquise from './MarquiseCut';
import * as Pear from './PearShape';
import * as Cushion from './CushionCut';
import * as Emerald from './EmeraldCut';
import * as Asscher from './AsscherCut';
import * as Radiant from './RadiantCut';
import * as Heart from './HeartShape';

type PropsType = {
  show: boolean,
  onCloseClick: () => void,
  onShapeClick: (name: string) => {},
  anchorElement?: HTMLElement | null
};

const shapeImages = {
  Round: '/images/diamond_search_results/b.svg',
  Princess: '/images/diamond_search_results/pr.svg',
  Oval: '/images/diamond_search_results/o.svg',
  Marquise: '/images/diamond_search_results/m.svg',
  Pear: '/images/diamond_search_results/p.svg',
  Cushion: '/images/diamond_search_results/c.svg',
  Emerald: '/images/diamond_search_results/e.svg',
  Asscher: '/images/diamond_search_results/as.svg',
  Radiant: '/images/diamond_search_results/r.svg',
  Heart: '/images/diamond_search_results/h.svg'
};

const classes = typestyle({
  container: {
    marginBottom: 2,
    marginTop: -7,
    cursor: 'pointer'
  },
  shape: {
    float: 'left',
    width: '25%',
    marginTop: 12
  },
  image: {
    display: 'block',
    margin: '0 auto',
    width: 35
  },
  shapeName: {
    textAlign: 'center',
    fontSize: '13px',
    lineHeight: '19px'
  }
});

const shapeMap = {
  Round: Round,
  Princess: Princess,
  Oval: Oval,
  Marquise: Marquise,
  Pear: Pear,
  Cushion: Cushion,
  Emerald: Emerald,
  Asscher: Asscher,
  Radiant: Radiant,
  Heart: Heart
};

const shapeElement = (
  name: string,
  setPopupContent: (N: React.Node) => void
) => {
  return (
    <div
      key={name}
      onClick={() => setPopupContent(shapeMap[name])}
      className={classes.shape}>
      <div>
        <img src={shapeImages[name]} className={classes.image} />
      </div>
      <div className={classes.shapeName}>{name}</div>
    </div>
  );
};

export const Header = 'Choose a Shape to Learn More';

export function Content ({ setPopupContent }) {
  return (
    <div className={classes.container}>
      {[
        'Round',
        'Princess',
        'Oval',
        'Marquise',
        'Pear',
        'Cushion',
        'Emerald',
        'Asscher',
        'Radiant',
        'Heart'
      ].map((name) => shapeElement(name, setPopupContent))}
    </div>
  );
}
