// @flow

import TextButton from '@components/popups/TextButton';
import * as LooseDiamondPackagingContent from './LooseDiamondPackaging';
import * as LifetimeTradeInGuaranteeContent from './LifetimeTradeInGuarantee';
import * as ReturnPolicyContent from './ReturnPolicy';
import * as LumeraAppraisalContent from './LumeraAppraisal';
import * as QualityGuaranteeContent from './QualityGuarantee';
import * as FedExDeliveryContent from './FedExDelivery';
import * as SafeShoppingGuaranteeContent from './SafeShoppingGuarantee';
import * as ReferAFriendContent from './ReferAFriend';
import * as GiaCertificateContent from './GiaCertificate';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Secure Packaging & Shipping';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        For your privacy and security, your purchase will be shipped as follows:
      </p>
      <ul className='no-item-bottom-margins medium-bottom-margin'>
        <li>
          In a standard FedEx shipping box. Lumera Diamonds will not appear
          anywhere on the outside of the box.
        </li>
        <li>Fully insured against loss or theft.</li>
        <li>
          An adult signature will be required at delivery. For your security,
          FedEx will not leave your package unattended, or leave it at another
          delivery address (such as a neighbor).
        </li>
        <li>
          For loose diamonds,{' '}
          <TextButton
            onClick={e => setPopupContent(LooseDiamondPackagingContent)}
            text='a specially designed inner package'
          />{' '}
          will keep your diamond safe and secure until you have it set.
        </li>
        <li>
          Engagement Rings or other jewelry will be shipped in a presentation
          ready gift box.
        </li>
      </ul>
      <p>
        We don't want to spoil any surprises, so at checkout you will have the
        opportunity to specify exactly how you would like to be contacted
        regarding your order. Lumera will contact you only at the email or phone
        number you designate.
      </p>

      <p>
        By offering our exclusive Secure Packaging & Shipping, as well as our{' '}
        <TextButton
          onClick={e => setPopupContent(LifetimeTradeInGuaranteeContent)}
          text='Lifetime Trade-In Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(ReturnPolicyContent)}
          text='30-Day Return Policy'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(LumeraAppraisalContent)}
          text='Free Appraisal'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(QualityGuaranteeContent)}
          text='Quality Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(FedExDeliveryContent)}
          text='Insured Express Delivery'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(SafeShoppingGuaranteeContent)}
          text='Safe Shopping Guarantee'
        />
        ,{' '}
        <TextButton
          onClick={e => setPopupContent(ReferAFriendContent)}
          text='Refer a Friend program'
        />
        , and{' '}
        <TextButton
          onClick={e => setPopupContent(GiaCertificateContent)}
          text='GIA Certificate'
        />
        , Lumera hopes to instill complete confidence in your purchase.
      </p>
    </div>
  );
}
