// @flow

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Save For Later';

export function Content({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        Save this diamond for easy review and purchase later. You can save
        multiple diamonds, and compare them side by side.
      </p>

      <p>
        After clicking "save for later", use the "saved item" link at the top of
        the page to visit the save and compare page. Once there, create an
        account by entering an email or other username. On future visits, log-in
        using the same username.
      </p>

      <p>
        Your email will not be used for any reason. Its only purpose is to
        provide you with an easy to remember, individual log-in.
      </p>
    </div>
  );
}
