// @flow
import { Fragment } from 'react';
import Carousel from '../ImageAndText';

import TextButton from '@components/popups/TextButton';

import * as CrownContent from '../../popups/content/Crown';
import * as TableContent from '../../popups/content/Table';
import * as GirdleContent from '../../popups/content/Girdle';
import * as PavilionContent from '../../popups/content/Pavilion';
import * as CuletContent from '../../popups/content/Culet';

const bezelFacets = '/images/anatomy_carousel/bezel_facets.gif';
const crown = '/images/anatomy_carousel/crown.gif';
const culet = '/images/anatomy_carousel/culet.gif';
const girdle = '/images/anatomy_carousel/girdle.gif';
const lowerGirdleFacets = '/images/anatomy_carousel/lower_girdle_facets.gif';
const pavilionFacets = '/images/anatomy_carousel/pavilion_facets.gif';
const pavilion = '/images/anatomy_carousel/pavilion.gif';
const starFacets = '/images/anatomy_carousel/star_facets.gif';
const table = '/images/anatomy_carousel/table.gif';
const upperGirdleFacets = '/images/anatomy_carousel/upper_girdle_facets.gif';

const items = (setPopupContent) => {
  return [
    {
      headerText: 'Crown',
      component: (
        <Fragment>
          Entire portion of the diamond that sits above the girdle. (
          <TextButton
            onClick={(e) => setPopupContent(CrownContent)}
            text="learn more"
          />
          )
        </Fragment>
      ),
      imageUrl: crown,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 20
      }
    },
    {
      headerText: 'Table',
      component: (
        <Fragment>
          Single, flat, uppermost surface of a diamond. (
          <TextButton
            onClick={(e) => setPopupContent(TableContent)}
            text="learn more"
          />
          )
        </Fragment>
      ),
      imageUrl: table,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 20
      }
    },
    {
      headerText: 'Star Facets',
      component: (
        <Fragment>
          Facets that sit directly adjacent to the table facet.
        </Fragment>
      ),
      imageUrl: starFacets,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 20
      }
    },
    {
      headerText: 'Bezel Facets',
      component: (
        <Fragment>
          Kite shaped facets that sit between the table and girdle.
        </Fragment>
      ),
      imageUrl: bezelFacets,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 20
      }
    },
    {
      headerText: 'Upper Girdle Facets',
      component: (
        <Fragment>
          Lowest facets on the crown, sitting just above the girdle.
        </Fragment>
      ),
      imageUrl: upperGirdleFacets,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 20
      }
    },
    {
      headerText: 'Girdle',
      component: (
        <Fragment>
          Outer edge of the diamond, where prongs grip. (
          <TextButton
            onClick={(e) => setPopupContent(GirdleContent)}
            text="learn more"
          />
          )
        </Fragment>
      ),
      imageUrl: girdle,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 20
      }
    },
    {
      headerText: 'Pavilion',
      component: (
        <Fragment>
          Entire portion of the diamond that sits below the girdle. (
          <TextButton
            onClick={(e) => setPopupContent(PavilionContent)}
            text="learn more"
          />
          )
        </Fragment>
      ),
      imageUrl: pavilion,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 20
      }
    },
    {
      headerText: 'Lower Girdle Facets',
      component: (
        <Fragment>
          Highest facets on the pavilion, sitting just below the girdle.
        </Fragment>
      ),
      imageUrl: lowerGirdleFacets,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 20
      }
    },
    {
      headerText: 'Pavilion Facets',
      component: (
        <Fragment>
          Lowest facets on the pavilion, sitting adjacent to the culet.
        </Fragment>
      ),
      imageUrl: pavilionFacets,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 20
      }
    },
    {
      headerText: 'Culet',
      component: (
        <Fragment>
          A point, or else a very small facet at bottom of pavilion. (
          <TextButton
            onClick={(e) => setPopupContent(CuletContent)}
            text="learn more"
          />
          )
        </Fragment>
      ),
      imageUrl: culet,
      imageLocation: 'top',
      imageAlt: '',
      imageStyles: {
        marginTop: 20
      }
    }
  ];
};

const Anatomy = (props: Object) => {
  const { setPopupContent, ...otherProps } = props;

  return (
    <Carousel
      {...otherProps}
      items={items(setPopupContent)}
      contentStyles={{ minHeight: 260 }}
    />
  );
};

export default Anatomy;
