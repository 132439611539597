// @flow

import TextButton from '@components/popups/TextButton';
import * as DiamondPlotContent from './DiamondPlot';

const crystal = '/images/glossary/crystal.gif';

type TProps = {
  setPopupContent: (N: React.Node) => void
};

export const Header = 'Crystal';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p className="style1">
        A mineral deposit found inside the diamond, either alone or in groups.
        The crystal may be colored or colorless, but usually appears black in
        overhead lighting.
      </p>
      <img src={crystal} />
      <p>
        <TextButton
          onClick={(e) => setPopupContent(DiamondPlotContent)}
          text="Learn more about inclusion and blemish types, and how they are represented"
        />
        .
      </p>
    </div>
  );
}
