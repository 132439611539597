// @flow

import * as ShapeContent from './Shape';
import * as DiamondDimensionsContent from './DiamondDimensions';
import * as LengthWidthContent from './LengthToWidthRatio';

import TextButton from '../TextButton';

import type { Node } from 'react';

type TProps = {
  setPopupContent: (N: Node) => void
};

const sizeChartIcon = '/images/buying-diamonds/size_chart.svg';

export const Header = 'Approximate Size & Shape';

export function Content ({ setPopupContent }: TProps) {
  return (
    <div>
      <p>
        The diamond displayed is a sample image, not the actual diamond.
        However, the sample image <b>does</b> match the{' '}
        <TextButton
          onClick={(e) => setPopupContent(ShapeContent)}
          text="shape"
        />
        ,{' '}
        <TextButton
          onClick={(e) => setPopupContent(DiamondDimensionsContent)}
          text="measurement"
        />
        , and{' '}
        <TextButton
          onClick={(e) => setPopupContent(LengthWidthContent)}
          text="length to width ratio"
        />{' '}
        of the actual diamond. This means you can use the sample image to see
        the true shape of the diamond; whether elongated, near-square, wide,
        etc.
      </p>
      <p>
        The actual size is accurate when viewed on a monitor set at 1280 x 1024
        screen resolution. You can confirm the size display by comparing an
        actual ruler to the on-screen millimeter rule displayed to the left of
        the diamond image. To check or update your PC monitor's resolution,
        right click anywhere on the desktop, choose Properties, go to the
        Settings tab and move the screen resolution pointer to "1280 x 1024".
      </p>
      <div
        id="customerBox"
        style={{ borderBottom: 'none', marginBottom: '10px' }}
      />
      <p>
        If you would like to see the true size of this or other diamonds, print
        the Actual Diamond Size Chart below:
      </p>
      <div id="customerBox4">
        {' '}
        <a href="/files/size_chart.pdf" target="_blank">
          <img
            src={sizeChartIcon}
            style={{
              float: 'left',
              margin: '20px 20px 10px 0',
              border: 0,
              width: 60
            }}
          />
        </a>
        <h2 style={{ paddingTop: '10px' }}>PRINTABLE SIZE CHART</h2>
        <p style={{ marginBottom: '25px' }}>
          Print a copy of our complimentary diamond size chart to see the actual
          size of a diamond.
        </p>
        <div style={{ display: 'inline-block' }}>
          <a
            href="/files/size_chart.pdf"
            target="_blank"
            className="blue button"
            style={{ marginBottom: '20px' }}>
            PRINT SIZE CHART
          </a>
        </div>
        <p>
          (Requires Adobe Acrobat Reader. Download it{' '}
          <a href="http://get.adobe.com/reader/?promoid=BUIGO" target="_blank">
            here
          </a>{' '}
          for free.){' '}
        </p>
      </div>
    </div>
  );
}
